<template>
  <div
    :class="$style['mainer']"
    id="_dashboard_layout_mainer"
  >
    <Suspense>
      <div
        v-if="!isValid && userStore.token"
        :class="$style['warning']"
      >
        <AlertOctagonIcon :size="80" />
        <Divider />
        <Typography.Title :heading="2"> 未授权页面 </Typography.Title>
      </div>
      <slot v-else />
      <template #fallback>
        <div class="flex justify-center items-center h-full">
          <Spin
            loading
            :size="64"
          />
        </div>
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts" setup>
import { useGlobalStore } from "@/store/globalStore";
import { computed } from "vue";
import { PageCard } from "@/components/PageCard";
import { Typography, Divider, Spin } from "@arco-design/web-vue";
import { AlertOctagonIcon } from "vue-tabler-icons";
import { useUserStore } from "@/store/userStore";

interface Props {
  routeName?: string;
}

const props = defineProps<Props>();
const globalStore = useGlobalStore();
const userStore = useUserStore();
const isValid = computed(() => {
  return true;
  // return props.routeName && globalStore.routeMap.has(props.routeName);
});
</script>

<style lang="scss" module>
.mainer {
  overflow: auto;
  // display: flex;
  flex-direction: column;
  // padding: 8px 4px;
  /* padding: 20px 18px; */
  padding: 32px 46px;
  scrollbar-gutter: stable both-edges;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 3;
  grid-row-end: -1;
}

.warning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
