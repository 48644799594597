<template>
  <ConfigProvider
    :TableParamsTransform="TableParamsTransform"
    :TableApiTransform="TableApiTransform"
  >
    <RouterView v-slot="{ Component }">
      <FullLoader v-if="isLoading" />
      <component
        v-else
        :is="Component"
      />
    </RouterView>
  </ConfigProvider>
</template>

<script lang="ts" setup>
import { ref, watchEffect } from "vue";
import { RouterView } from "vue-router";
import { useGlobalStore } from "@/store/globalStore";
import { useUserStore } from "@/store/userStore";
import { FullLoader } from "./components/FullLoader";
import { ConfigProvider } from "@peacebird/arco-design-vue-plus";
import { AxiosHeaders } from "axios";

const isLoading = ref(false);
const userStore = useUserStore();
const globalStore = useGlobalStore();

const TableParamsTransform = (e) => {
  const { current, pageSize, ...rest } = e;

  return {
    pageNum: current,
    pageSize: pageSize,
    ...rest,
  };
};

const TableApiTransform: any = (e) => {
  if (e instanceof Array) {
    return e;
  } else {
    if (e.headers && e.headers instanceof AxiosHeaders) {
      const resData = e.data;

      if (resData instanceof Array) {
        return resData;
      } else {
        const { rows, total, data } = resData;

        if (data instanceof Array) {
          return data;
        } else {
          return {
            records: rows,
            total,
          };
        }
      }
    } else {
      return {
        records: e.records ?? e.rows,
        total: e.total,
      };
    }
  }
};

watchEffect(async (onCleanup) => {
  let outdate = false;
  const token = userStore.token;

  if (token) {
    isLoading.value = true;

    onCleanup(() => {
      outdate = true;
    });

    try {
      await Promise.all([
        userStore.FETCH_PROFILE(),
        // userStore.FETCH_PERMISSIONS(),
        // globalStore.FETCH_MENUS(),
      ]);

      if (!outdate) {
        isLoading.value = false;
      }
    } catch (error) {
      if (!outdate) {
        isLoading.value = false;
      }
    }

    isLoading.value = false;
  }
});
</script>
