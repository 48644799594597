import MainerWrap from "@/layouts/dashboard/MainerWrap";
import {
  AsyncComponentLoader,
  Component,
  defineAsyncComponent,
  defineComponent,
  h,
} from "vue";
import { FullLoader } from "@/components/FullLoader";
import { RouteMeta } from "vue-router";

interface Route {
  path: string;
  name: string;
  meta?: RouteMeta;
  component: AsyncComponentLoader | Component;
  children?: Route[];
}

const AsyncPageWrap = (component: AsyncComponentLoader) => {
  const AsyncPage = defineAsyncComponent({
    loader: component,
    loadingComponent: FullLoader,
  });

  return defineComponent({
    render() {
      return h(AsyncPage);
    },
  });
};

// 宣传模块
const publicizeModule: Route[] = [
  {
    path: "publicizeModule/carousel",
    name: "publicizeCarousel",
    meta: {
      title: "轮播图管理",
      icon: "CategoryIcon",
    },
    component: () => import("@/views/publicizeModule/carousel/index.vue"),
  },
  {
    path: "publicizeModule/newsCate",
    name: "publicizeNewsCate",
    meta: {
      title: "新闻栏目",
      icon: "CategoryIcon",
    },
    component: () => import("@/views/publicizeModule/newsCate/index.vue"),
  },
  {
    path: "publicizeModule/news",
    name: "publicizeNews",
    meta: {
      title: "新闻管理",
      icon: "NewsIcon",
    },
    component: () => import("@/views/publicizeModule/newsList/index.vue"),
  },
  {
    path: "publicizeModule/newsLibrary",
    name: "publicizeNewsLibrary",
    meta: {
      title: "新闻库",
      icon: "BooksIcon",
    },
    component: () => import("@/views/publicizeModule/newsLibrary/index.vue"),
  },
];

// 活动模块
const ActivityModule: Route[] = [
  {
    path: "activityModule/activityList",
    name: "activityList",
    meta: {
      title: "活动列表",
      icon: "ActivityIcon",
    },
    component: () => import("@/views/activityModule/activityList/index.vue"),
  },
];

// 组织模块
const OrganizationModule: Route[] = [
  {
    path: "organizationModule/organization",
    name: "organization",
    meta: {
      title: "组织管理",
    },
    component: () =>
      import("@/views/organizationModule/organization/index.vue"),
  },
  {
    path: "organizationModule/memberList",
    name: "memberList",
    meta: {
      title: "成员管理",
    },
    component: () => import("@/views/organizationModule/memberList/index.vue"),
  },
];

// 系统管理
const SystemModule: Route[] = [
  // {
  //   path: "systemModule/menuManagement",
  //   name: "MenuManagement",
  //   meta: {
  //     title: "菜单管理",
  //   },
  //   component: () => import("@/views/systemModule/menuManagement"),
  // },
  // {
  //   path: "systemModule/departmentManagement",
  //   name: "DepartmentManagement",
  //   meta: {
  //     title: "部门管理",
  //   },
  //   component: () => import("@/views/systemModule/departmentManagement"),
  // },
  {
    path: "systemModule/accountManagement",
    name: "AccountManagement",
    meta: {
      title: "用户管理",
    },
    component: () => import("@/views/systemModule/accountManagement"),
  },
  {
    path: "systemModule/roleManagement",
    name: "roleManagement",
    meta: {
      title: "角色管理",
    },
    component: () => import("@/views/systemModule/roleManagement"),
  },
  // {
  //   path: "systemModule/postManagement",
  //   name: "PostManagement",
  //   meta: {
  //     title: "岗位管理",
  //   },
  //   component: () => import("@/views/systemModule/postManagement"),
  // },
  {
    path: "systemModule/dictionaryManagement",
    name: "DictionaryManagement",
    meta: {
      title: "字典管理",
    },
    component: () => import("@/views/systemModule/dictionaryManagement"),
  },
  {
    path: "systemModule/dictionaryDataManagement",
    name: "DictionaryDataManagement",
    meta: {
      title: "字典数据管理",
    },
    component: () => import("@/views/systemModule/dictionaryDataManagement"),
  },
];

const DashboardRoutes: Route[] = [
  {
    path: "",
    name: "Dashboard",
    meta: {
      title: "首页", // 标签页缺省标题
      isPin: true, // 是否固定
      isCache: false, // 是否启用 keep-alive 缓存
    },
    component: () => import("@/views/dashboard"),
  },
  ...SystemModule,
  ...publicizeModule,
  ...OrganizationModule,
  ...ActivityModule,
];

const Routes: Route[] = [
  {
    path: "/dashboard",
    name: "_Dashboard",
    component: AsyncPageWrap(() => import("@/layouts/dashboard/Layout.vue")),
    children: DashboardRoutes.map((x) => {
      return {
        ...x,
        component: MainerWrap(x.component as AsyncComponentLoader, x.name),
      };
    }),
  },
  {
    path: "/login",
    name: "Login",
    component: AsyncPageWrap(() => import("@/views/loginLegacy/View.vue")),
  },
  {
    path: "/auth",
    name: "Auth",
    component: AsyncPageWrap(() => import("@/views/auth")),
  },
  {
    path: "/",
    name: "Home",
    component: AsyncPageWrap(() => import("@/views/home")),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: AsyncPageWrap(() => import("@/views/notFound")),
  },
];

export { Routes, DashboardRoutes };
