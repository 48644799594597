import { defineStore } from "pinia";
import { useGlobalStore } from "./globalStore";
// import { login, getInfo, getPermCode } from "@/api/user";
import { Notification } from "@arco-design/web-vue";
import { Message } from "@arco-design/web-vue";
// import { postFsLogin } from "@/api/system";
import { reactive, toRefs, watchEffect } from "vue";
import { postLogin, getGetInfo } from "@/api/_orval/console";

interface UserStoreState {
  roleNames: string[]; // 当前用户角色名称
  roles: string[]; // 当前用户角色
  token: string; // 当前用户令牌
  id: string; // 当前用户 ID
  username: string; // 当前用户账户名
  nickname: string; // 当前用户昵称
  permissions: string[]; // 当前用户权限
  phone: string; // 当前用户手机号
  remember: boolean; // 是否记住密码
}

export interface SigninForm {
  code: string;
  username: string;
  password: string;
  remember?: boolean;
  uuid: string;
}

export interface LarkSigninForm {
  code: string;
  redirect_uri: string;
}

const getInitState = (): UserStoreState => ({
  roleNames: [],
  roles: [],
  token: "",
  id: "",
  username: "",
  nickname: "",
  permissions: [],
  phone: "",
  remember: false,
});

export const useUserStore = defineStore("user", () => {
  const sessionState = JSON.parse(sessionStorage.getItem("UserStore") || "{}");
  const localState = JSON.parse(localStorage.getItem("UserStore") || "{}");

  const globalStore = useGlobalStore();

  const state = reactive<UserStoreState>(
    Object.assign(getInitState(), localState, sessionState),
  );

  const RESET = () => {
    Object.assign(state, getInitState());
  };

  const SIGNIN = async (form: SigninForm) => {
    const { code, username, password, uuid, remember } = form;

    // const { username, password, remember } = form;
    // const data = await login({
    //   username,
    //   password,
    // });
    // const { access_token: token } = data;
    // state.token = token;
    // state.remember = remember ?? false;

    const res = await postLogin({
      code,
      username,
      password,
      uuid,
    });
    const token = res.data.data.token;

    state.token = token;
    state.remember = remember ?? false;
  };

  const LARK_SIGNIN = async (params: LarkSigninForm) => {
    // const { code, redirect_uri } = params;
    // const data = await postFsLogin({
    //   code,
    //   redirectUri: redirect_uri,
    // });
    // const { access_token: token } = data;
    // state.token = token;
  };

  const SIGNOUT = () => {
    globalStore.RESET();
    RESET();
    Notification.clear();
    Message.clear();
  };

  const FETCH_PROFILE = async () => {
    const data = await getGetInfo();
    const {
      user: { userId: id, userName, nickName, phone, roles },
    } = data.data.data;
    state.id = id;
    state.username = userName;
    state.nickname = nickName;
    state.roles = roles.map((x) => x.roleKey);
    state.phone = phone;
    state.roleNames = roles.map((item) => item.roleName);
  };

  const FETCH_PERMISSIONS = async () => {
    // const data = await getPermCode();
    // state.permissions = data;
  };

  watchEffect(() => {
    const token = state.token;
    const remember = state.remember;
    const _state = JSON.stringify({
      token,
      remember,
    });

    sessionStorage.setItem("UserStore", _state);
    if (remember) {
      localStorage.setItem("UserStore", _state);
    } else {
      localStorage.removeItem("UserStore");
    }
  });

  return {
    ...toRefs(state),
    SIGNIN,
    LARK_SIGNIN,
    SIGNOUT,
    FETCH_PROFILE,
    FETCH_PERMISSIONS,
  };
});
