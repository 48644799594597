import { defineStore } from "pinia";
import { router } from "@/router";
import { DashboardRoutes } from "@/router/routes";
import { getRouters } from "@/api/system";
import {
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  RouteRecordName,
} from "vue-router";
import { reactive, toRefs, watchEffect } from "vue";
import { useUserStore } from "./userStore";

export interface Menu {
  key: string;
  label: string;
  icon: string;
  routeName?: string;
  children?: Menu[];
}

type TabMapValue = {
  name: RouteLocationNormalized["name"];
  meta?: RouteLocationNormalized["meta"];
};

type TabMap = Map<RouteRecordName, TabMapValue>;

interface GLobalState {
  menus: Menu[];
  routeMap: Map<string, [string, string]>;
  pinTabMap: TabMap;
  tabMap: TabMap;
  activedRoute?: RouteLocationNormalizedLoaded;
  menuCollapsed: boolean;
}

const getInitState = (): GLobalState => ({
  menus: [] as Menu[],
  routeMap: new Map([["Dashboard", ["首页", "Home2Icon"]]]),
  pinTabMap: new Map(
    DashboardRoutes.filter((x) => x.meta?.isPin).map((x) => {
      return [
        x.name,
        {
          name: x.name,
          meta: x.meta,
        },
      ];
    }),
  ),
  tabMap: new Map(),
  activedRoute: undefined,
  menuCollapsed: false,
});

export const useGlobalStore = defineStore("global", () => {
  const sessionState = JSON.parse(
    sessionStorage.getItem("GlobalStore") || "{}",
  );
  const localState = JSON.parse(localStorage.getItem("GlobalStore") || "{}");

  const userStore = useUserStore();

  const state = reactive<GLobalState>(
    Object.assign(getInitState(), localState, sessionState),
  );

  const RESET = () => {
    Object.assign(state, getInitState());
  };

  const FETCH_MENUS = async () => {
    // const data = await getRouters();
    // const routeMap = new Map(state.routeMap);
    // const recursor = (e) => {
    //   e.forEach((element) => {
    //     const { pathName, meta } = element;
    //     if (pathName && meta) {
    //       const { title, icon } = meta;
    //       routeMap.set(pathName, [title, icon]);
    //     } else {
    //       routeMap.set(pathName, ["", ""]);
    //     }
    //   });
    //   return e
    //     .filter((x) => !x.hideMenu)
    //     .map((x) => {
    //       return {
    //         key: x.name,
    //         label: x.meta?.title,
    //         icon: x.meta?.icon,
    //         routeName: x.pathName,
    //         children: x.children ? recursor(x.children) : undefined,
    //       };
    //     });
    // };
    // state.menus = recursor(data);
    // state.routeMap = routeMap;
  };

  const ADD_TAB = (route: RouteLocationNormalized) => {
    const map = new Map(state.tabMap);
    if (route.name) {
      map.set(route.name, route);
    }
    state.tabMap = map;
  };

  const REMOVE_TAB = (key: string) => {
    const map = new Map(state.tabMap);
    map.delete(key);
    state.tabMap = map;
  };

  const ACTIVE_ROUTE = (route: RouteLocationNormalizedLoaded) => {
    state.activedRoute = route;
  };

  const CLEAN_TAB = () => {
    const map = new Map();
    state.tabMap = map;
  };

  const CLEAN_TAB_WITHOUT = (route: TabMapValue) => {
    if (route.name) {
      const map = new Map([[route.name, route]]);
      state.tabMap = map;
    }
  };

  const CLOSE_TAB = (tabKey: string) => {
    REMOVE_TAB(tabKey);
    const isActive = state.activedRoute?.name === tabKey;
    if (isActive) {
      const tabSize = state.tabMap.size;
      if (tabSize >= 1) {
        const lastRoute = [...state.tabMap.values()][tabSize - 1];
        if (lastRoute.name) {
          router.replace({
            name: lastRoute.name,
          });
        }
      } else {
        const firstPinRoute = [...state.pinTabMap.values()][0];
        if (firstPinRoute.name) {
          router.replace({
            name: firstPinRoute.name,
          });
        }
      }
    }
  };

  const MENU_COLLAPSE = () => {
    state.menuCollapsed = !state.menuCollapsed;
  };

  watchEffect(() => {
    const menuCollapsed = state.menuCollapsed;
    const _state = JSON.stringify({
      menuCollapsed,
    });

    sessionStorage.setItem("GlobalStore", _state);
    if (userStore.remember) {
      localStorage.setItem("GlobalStore", _state);
    } else {
      localStorage.removeItem("GlobalStore");
    }
  });

  return {
    ...toRefs(state),
    RESET,
    FETCH_MENUS,
    ADD_TAB,
    REMOVE_TAB,
    ACTIVE_ROUTE,
    CLEAN_TAB,
    CLEAN_TAB_WITHOUT,
    CLOSE_TAB,
    MENU_COLLAPSE,
  };
});
