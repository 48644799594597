/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * 标题：peacebird-labor-union后台管理系统_接口文档
 * 描述：用于管理集团旗下公司的人员信息,具体包括XXX,XXX模块...
 * OpenAPI spec version: 版本号: 4.0.1
 */
import { useQuery, useMutation } from "@tanstack/vue-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  QueryKey,
  UseQueryReturnType,
} from "@tanstack/vue-query";
import { unref } from "vue";
import type { MaybeRef } from "@tanstack/vue-query/build/lib/types";
import { customInstance } from "../../utils/http";
export type GetMonitorOnlineListParams = {
  /**
   * ipaddr
   */
  ipaddr?: string;
  /**
   * userName
   */
  userName?: string;
};

export type GetSystemUserListDeptChildrenItem = { [key: string]: any };

export type GetSystemUserListParams = {
  admin?: boolean;
  avatar?: string;
  birthday?: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  delFlag?: string;
  /**
   * 详细地址
   */
  "dept.address"?: string;
  /**
   * 祖级列表
   */
  "dept.ancestors"?: string;
  /**
   * 子组织
   */
  "dept.children"?: GetSystemUserListDeptChildrenItem[];
  /**
   * 创建者
   */
  "dept.createBy"?: string;
  /**
   * 创建时间
   */
  "dept.createTime"?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  "dept.delFlag"?: string;
  /**
   * 单位情况
   */
  "dept.deptDesc"?: string;
  /**
   * 组织id
   */
  "dept.deptId"?: number;
  /**
   * 组织隶属
   */
  "dept.deptLevel"?: string;
  /**
   * 组织名称
   */
  "dept.deptName": string;
  /**
   * 组织类别
   */
  "dept.deptType": string;
  /**
   * 邮箱
   */
  "dept.email"?: string;
  /**
   * 组织图片
   */
  "dept.imageUrl"?: string;
  /**
   * 是否异地组织
   */
  "dept.isOut": string;
  /**
   * 党组织书记
   */
  "dept.leader"?: string;
  /**
   * 显示排序
   */
  "dept.orderNum": number;
  /**
   * 请求参数
   */
  "dept.params"?: { [key: string]: any };
  /**
   * 父组织id
   */
  "dept.parentId": number;
  /**
   * 父菜单名称
   */
  "dept.parentName"?: string;
  /**
   * 联系电话
   */
  "dept.phone"?: string;
  /**
   * 成立时间
   */
  "dept.regDate"?: string;
  /**
   * 相关资料
   */
  "dept.relDoc"?: string;
  /**
   * 组织简介
   */
  "dept.remark"?: string;
  /**
   * 搜索值
   */
  "dept.searchValue"?: string;
  /**
   * 组织简称
   */
  "dept.shortName": string;
  /**
   * :0正常,1停用
   */
  "dept.status": string;
  /**
   * 更新者
   */
  "dept.updateBy"?: string;
  /**
   * 更新时间
   */
  "dept.updateTime"?: string;
  deptId?: number;
  devStage?: string;
  education?: string;
  email?: string;
  entryPartyDate?: string;
  homeAddress?: string;
  /**
   * 户籍类型
   */
  householdType?: string;
  idCardNo?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  isJob?: string;
  isRecord?: string;
  loginDate?: string;
  loginIp?: string;
  nation?: string;
  notLocal?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  partyMemberType?: string;
  password?: string;
  phone?: string;
  /**
   * 政治面貌
   */
  politicStatus?: string;
  /**
   * 党内职务
   */
  position?: string;
  postIds?: number[];
  removeReason?: string;
  removeTime?: string;
  roleIds?: number[];
  /**
   * 是否管理员
   */
  "roles[0].admin"?: boolean;
  /**
   * 创建者
   */
  "roles[0].createBy"?: string;
  /**
   * 创建时间
   */
  "roles[0].createTime"?: string;
  /**
   * 数据范围（1：所有数据权限；2：自定义数据权限；3：本组织数据权限；4：本组织及以下数据权限；5：仅本人数据权限）
   */
  "roles[0].dataScope"?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  "roles[0].delFlag"?: string;
  /**
   * 组织树选择项是否关联显示（0：父子不互相关联显示 1：父子互相关联显示 ）
   */
  "roles[0].deptCheckStrictly"?: boolean;
  /**
   * 组织组（数据权限）
   */
  "roles[0].deptIds"?: number[];
  /**
   * 用户是否存在此角色标识 默认不存在
   */
  "roles[0].flag"?: boolean;
  /**
   * 菜单树选择项是否关联显示（ 0：父子不互相关联显示 1：父子互相关联显示）
   */
  "roles[0].menuCheckStrictly"?: boolean;
  /**
   * 菜单组
   */
  "roles[0].menuIds"?: number[];
  /**
   * 请求参数
   */
  "roles[0].params"?: { [key: string]: any };
  /**
   * 备注
   */
  "roles[0].remark"?: string;
  /**
   * 角色ID
   */
  "roles[0].roleId"?: number;
  /**
   * 角色权限
   */
  "roles[0].roleKey": string;
  /**
   * 角色名称
   */
  "roles[0].roleName": string;
  /**
   * 角色排序
   */
  "roles[0].roleSort": string;
  /**
   * 搜索值
   */
  "roles[0].searchValue"?: string;
  /**
   * 角色状态（0正常 1停用）
   */
  "roles[0].status"?: string;
  /**
   * 更新者
   */
  "roles[0].updateBy"?: string;
  /**
   * 更新时间
   */
  "roles[0].updateTime"?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  sex?: string;
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  userId?: number;
  userName?: string;
  userType?: string;
};

export type PostSystemUserImportDataBody = {
  /** file */
  file: Blob;
};

export type PostSystemUserImportDataParams = {
  /**
   * updateSupport
   */
  updateSupport?: boolean;
};

export type PostSystemUserExportDeptChildrenItem = { [key: string]: any };

export type PostSystemUserExportParams = {
  admin?: boolean;
  avatar?: string;
  birthday?: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  delFlag?: string;
  /**
   * 详细地址
   */
  "dept.address"?: string;
  /**
   * 祖级列表
   */
  "dept.ancestors"?: string;
  /**
   * 子组织
   */
  "dept.children"?: PostSystemUserExportDeptChildrenItem[];
  /**
   * 创建者
   */
  "dept.createBy"?: string;
  /**
   * 创建时间
   */
  "dept.createTime"?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  "dept.delFlag"?: string;
  /**
   * 单位情况
   */
  "dept.deptDesc"?: string;
  /**
   * 组织id
   */
  "dept.deptId"?: number;
  /**
   * 组织隶属
   */
  "dept.deptLevel"?: string;
  /**
   * 组织名称
   */
  "dept.deptName": string;
  /**
   * 组织类别
   */
  "dept.deptType": string;
  /**
   * 邮箱
   */
  "dept.email"?: string;
  /**
   * 组织图片
   */
  "dept.imageUrl"?: string;
  /**
   * 是否异地组织
   */
  "dept.isOut": string;
  /**
   * 党组织书记
   */
  "dept.leader"?: string;
  /**
   * 显示排序
   */
  "dept.orderNum": number;
  /**
   * 请求参数
   */
  "dept.params"?: { [key: string]: any };
  /**
   * 父组织id
   */
  "dept.parentId": number;
  /**
   * 父菜单名称
   */
  "dept.parentName"?: string;
  /**
   * 联系电话
   */
  "dept.phone"?: string;
  /**
   * 成立时间
   */
  "dept.regDate"?: string;
  /**
   * 相关资料
   */
  "dept.relDoc"?: string;
  /**
   * 组织简介
   */
  "dept.remark"?: string;
  /**
   * 搜索值
   */
  "dept.searchValue"?: string;
  /**
   * 组织简称
   */
  "dept.shortName": string;
  /**
   * :0正常,1停用
   */
  "dept.status": string;
  /**
   * 更新者
   */
  "dept.updateBy"?: string;
  /**
   * 更新时间
   */
  "dept.updateTime"?: string;
  deptId?: number;
  devStage?: string;
  education?: string;
  email?: string;
  entryPartyDate?: string;
  homeAddress?: string;
  /**
   * 户籍类型
   */
  householdType?: string;
  idCardNo?: string;
  isJob?: string;
  isRecord?: string;
  loginDate?: string;
  loginIp?: string;
  nation?: string;
  notLocal?: string;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  partyMemberType?: string;
  password?: string;
  phone?: string;
  /**
   * 政治面貌
   */
  politicStatus?: string;
  /**
   * 党内职务
   */
  position?: string;
  postIds?: number[];
  removeReason?: string;
  removeTime?: string;
  roleIds?: number[];
  /**
   * 是否管理员
   */
  "roles[0].admin"?: boolean;
  /**
   * 创建者
   */
  "roles[0].createBy"?: string;
  /**
   * 创建时间
   */
  "roles[0].createTime"?: string;
  /**
   * 数据范围（1：所有数据权限；2：自定义数据权限；3：本组织数据权限；4：本组织及以下数据权限；5：仅本人数据权限）
   */
  "roles[0].dataScope"?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  "roles[0].delFlag"?: string;
  /**
   * 组织树选择项是否关联显示（0：父子不互相关联显示 1：父子互相关联显示 ）
   */
  "roles[0].deptCheckStrictly"?: boolean;
  /**
   * 组织组（数据权限）
   */
  "roles[0].deptIds"?: number[];
  /**
   * 用户是否存在此角色标识 默认不存在
   */
  "roles[0].flag"?: boolean;
  /**
   * 菜单树选择项是否关联显示（ 0：父子不互相关联显示 1：父子互相关联显示）
   */
  "roles[0].menuCheckStrictly"?: boolean;
  /**
   * 菜单组
   */
  "roles[0].menuIds"?: number[];
  /**
   * 请求参数
   */
  "roles[0].params"?: { [key: string]: any };
  /**
   * 备注
   */
  "roles[0].remark"?: string;
  /**
   * 角色ID
   */
  "roles[0].roleId"?: number;
  /**
   * 角色权限
   */
  "roles[0].roleKey": string;
  /**
   * 角色名称
   */
  "roles[0].roleName": string;
  /**
   * 角色排序
   */
  "roles[0].roleSort": string;
  /**
   * 搜索值
   */
  "roles[0].searchValue"?: string;
  /**
   * 角色状态（0正常 1停用）
   */
  "roles[0].status"?: string;
  /**
   * 更新者
   */
  "roles[0].updateBy"?: string;
  /**
   * 更新时间
   */
  "roles[0].updateTime"?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  sex?: string;
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  userId?: number;
  userName?: string;
  userType?: string;
};

export type GetSystemUserCheckPhoneUniqueParams = {
  /**
   * 手机号
   */
  phone: string;
  /**
   * 用户Id
   */
  userId?: number;
};

export type GetSystemUserCheckIdCardNoUniqueParams = {
  /**
   * 手机号
   */
  idCardNo: string;
  /**
   * 用户Id
   */
  userId?: number;
};

export type PutSystemUserAuthRoleParams = {
  /**
   * 角色ID串
   */
  roleIds?: string;
  /**
   * 用户Id
   */
  userId?: string;
};

export type GetSystemRoleListParams = {
  /**
   * 是否管理员
   */
  admin?: boolean;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 数据范围（1：所有数据权限；2：自定义数据权限；3：本组织数据权限；4：本组织及以下数据权限；5：仅本人数据权限）
   */
  dataScope?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  delFlag?: string;
  /**
   * 组织树选择项是否关联显示（0：父子不互相关联显示 1：父子互相关联显示 ）
   */
  deptCheckStrictly?: boolean;
  /**
   * 组织组（数据权限）
   */
  deptIds?: number[];
  /**
   * 用户是否存在此角色标识 默认不存在
   */
  flag?: boolean;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 菜单树选择项是否关联显示（ 0：父子不互相关联显示 1：父子互相关联显示）
   */
  menuCheckStrictly?: boolean;
  /**
   * 菜单组
   */
  menuIds?: number[];
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 备注
   */
  remark?: string;
  /**
   * 角色ID
   */
  roleId?: number;
  /**
   * 角色权限
   */
  roleKey: string;
  /**
   * 角色名称
   */
  roleName: string;
  /**
   * 角色排序
   */
  roleSort: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 角色状态（0正常 1停用）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type PostSystemRoleExportParams = {
  /**
   * 是否管理员
   */
  admin?: boolean;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 数据范围（1：所有数据权限；2：自定义数据权限；3：本组织数据权限；4：本组织及以下数据权限；5：仅本人数据权限）
   */
  dataScope?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  delFlag?: string;
  /**
   * 组织树选择项是否关联显示（0：父子不互相关联显示 1：父子互相关联显示 ）
   */
  deptCheckStrictly?: boolean;
  /**
   * 组织组（数据权限）
   */
  deptIds?: number[];
  /**
   * 用户是否存在此角色标识 默认不存在
   */
  flag?: boolean;
  /**
   * 菜单树选择项是否关联显示（ 0：父子不互相关联显示 1：父子互相关联显示）
   */
  menuCheckStrictly?: boolean;
  /**
   * 菜单组
   */
  menuIds?: number[];
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 备注
   */
  remark?: string;
  /**
   * 角色ID
   */
  roleId?: number;
  /**
   * 角色权限
   */
  roleKey: string;
  /**
   * 角色名称
   */
  roleName: string;
  /**
   * 角色排序
   */
  roleSort: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 角色状态（0正常 1停用）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetSystemRoleAuthUserUnallocatedListDeptChildrenItem = {
  [key: string]: any;
};

export type GetSystemRoleAuthUserUnallocatedListParams = {
  admin?: boolean;
  avatar?: string;
  birthday?: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  delFlag?: string;
  /**
   * 详细地址
   */
  "dept.address"?: string;
  /**
   * 祖级列表
   */
  "dept.ancestors"?: string;
  /**
   * 子组织
   */
  "dept.children"?: GetSystemRoleAuthUserUnallocatedListDeptChildrenItem[];
  /**
   * 创建者
   */
  "dept.createBy"?: string;
  /**
   * 创建时间
   */
  "dept.createTime"?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  "dept.delFlag"?: string;
  /**
   * 单位情况
   */
  "dept.deptDesc"?: string;
  /**
   * 组织id
   */
  "dept.deptId"?: number;
  /**
   * 组织隶属
   */
  "dept.deptLevel"?: string;
  /**
   * 组织名称
   */
  "dept.deptName": string;
  /**
   * 组织类别
   */
  "dept.deptType": string;
  /**
   * 邮箱
   */
  "dept.email"?: string;
  /**
   * 组织图片
   */
  "dept.imageUrl"?: string;
  /**
   * 是否异地组织
   */
  "dept.isOut": string;
  /**
   * 党组织书记
   */
  "dept.leader"?: string;
  /**
   * 显示排序
   */
  "dept.orderNum": number;
  /**
   * 请求参数
   */
  "dept.params"?: { [key: string]: any };
  /**
   * 父组织id
   */
  "dept.parentId": number;
  /**
   * 父菜单名称
   */
  "dept.parentName"?: string;
  /**
   * 联系电话
   */
  "dept.phone"?: string;
  /**
   * 成立时间
   */
  "dept.regDate"?: string;
  /**
   * 相关资料
   */
  "dept.relDoc"?: string;
  /**
   * 组织简介
   */
  "dept.remark"?: string;
  /**
   * 搜索值
   */
  "dept.searchValue"?: string;
  /**
   * 组织简称
   */
  "dept.shortName": string;
  /**
   * :0正常,1停用
   */
  "dept.status": string;
  /**
   * 更新者
   */
  "dept.updateBy"?: string;
  /**
   * 更新时间
   */
  "dept.updateTime"?: string;
  deptId?: number;
  devStage?: string;
  education?: string;
  email?: string;
  entryPartyDate?: string;
  homeAddress?: string;
  /**
   * 户籍类型
   */
  householdType?: string;
  idCardNo?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  isJob?: string;
  isRecord?: string;
  loginDate?: string;
  loginIp?: string;
  nation?: string;
  notLocal?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  partyMemberType?: string;
  password?: string;
  phone?: string;
  /**
   * 政治面貌
   */
  politicStatus?: string;
  /**
   * 党内职务
   */
  position?: string;
  postIds?: number[];
  removeReason?: string;
  removeTime?: string;
  roleIds?: number[];
  /**
   * 是否管理员
   */
  "roles[0].admin"?: boolean;
  /**
   * 创建者
   */
  "roles[0].createBy"?: string;
  /**
   * 创建时间
   */
  "roles[0].createTime"?: string;
  /**
   * 数据范围（1：所有数据权限；2：自定义数据权限；3：本组织数据权限；4：本组织及以下数据权限；5：仅本人数据权限）
   */
  "roles[0].dataScope"?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  "roles[0].delFlag"?: string;
  /**
   * 组织树选择项是否关联显示（0：父子不互相关联显示 1：父子互相关联显示 ）
   */
  "roles[0].deptCheckStrictly"?: boolean;
  /**
   * 组织组（数据权限）
   */
  "roles[0].deptIds"?: number[];
  /**
   * 用户是否存在此角色标识 默认不存在
   */
  "roles[0].flag"?: boolean;
  /**
   * 菜单树选择项是否关联显示（ 0：父子不互相关联显示 1：父子互相关联显示）
   */
  "roles[0].menuCheckStrictly"?: boolean;
  /**
   * 菜单组
   */
  "roles[0].menuIds"?: number[];
  /**
   * 请求参数
   */
  "roles[0].params"?: { [key: string]: any };
  /**
   * 备注
   */
  "roles[0].remark"?: string;
  /**
   * 角色ID
   */
  "roles[0].roleId"?: number;
  /**
   * 角色权限
   */
  "roles[0].roleKey": string;
  /**
   * 角色名称
   */
  "roles[0].roleName": string;
  /**
   * 角色排序
   */
  "roles[0].roleSort": string;
  /**
   * 搜索值
   */
  "roles[0].searchValue"?: string;
  /**
   * 角色状态（0正常 1停用）
   */
  "roles[0].status"?: string;
  /**
   * 更新者
   */
  "roles[0].updateBy"?: string;
  /**
   * 更新时间
   */
  "roles[0].updateTime"?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  sex?: string;
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  userId?: number;
  userName?: string;
  userType?: string;
};

export type PutSystemRoleAuthUserSelectAllParams = {
  /**
   * 角色ID
   */
  roleId?: string;
  /**
   * 用户ID串
   */
  userIds?: string;
};

export type PutSystemRoleAuthUserCancelAllParams = {
  /**
   * 角色ID
   */
  roleId?: string;
  /**
   * 用户ID串
   */
  userIds?: string;
};

export type GetSystemRoleAuthUserAllocatedListDeptChildrenItem = {
  [key: string]: any;
};

export type GetSystemRoleAuthUserAllocatedListParams = {
  admin?: boolean;
  avatar?: string;
  birthday?: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  delFlag?: string;
  /**
   * 详细地址
   */
  "dept.address"?: string;
  /**
   * 祖级列表
   */
  "dept.ancestors"?: string;
  /**
   * 子组织
   */
  "dept.children"?: GetSystemRoleAuthUserAllocatedListDeptChildrenItem[];
  /**
   * 创建者
   */
  "dept.createBy"?: string;
  /**
   * 创建时间
   */
  "dept.createTime"?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  "dept.delFlag"?: string;
  /**
   * 单位情况
   */
  "dept.deptDesc"?: string;
  /**
   * 组织id
   */
  "dept.deptId"?: number;
  /**
   * 组织隶属
   */
  "dept.deptLevel"?: string;
  /**
   * 组织名称
   */
  "dept.deptName": string;
  /**
   * 组织类别
   */
  "dept.deptType": string;
  /**
   * 邮箱
   */
  "dept.email"?: string;
  /**
   * 组织图片
   */
  "dept.imageUrl"?: string;
  /**
   * 是否异地组织
   */
  "dept.isOut": string;
  /**
   * 党组织书记
   */
  "dept.leader"?: string;
  /**
   * 显示排序
   */
  "dept.orderNum": number;
  /**
   * 请求参数
   */
  "dept.params"?: { [key: string]: any };
  /**
   * 父组织id
   */
  "dept.parentId": number;
  /**
   * 父菜单名称
   */
  "dept.parentName"?: string;
  /**
   * 联系电话
   */
  "dept.phone"?: string;
  /**
   * 成立时间
   */
  "dept.regDate"?: string;
  /**
   * 相关资料
   */
  "dept.relDoc"?: string;
  /**
   * 组织简介
   */
  "dept.remark"?: string;
  /**
   * 搜索值
   */
  "dept.searchValue"?: string;
  /**
   * 组织简称
   */
  "dept.shortName": string;
  /**
   * :0正常,1停用
   */
  "dept.status": string;
  /**
   * 更新者
   */
  "dept.updateBy"?: string;
  /**
   * 更新时间
   */
  "dept.updateTime"?: string;
  deptId?: number;
  devStage?: string;
  education?: string;
  email?: string;
  entryPartyDate?: string;
  homeAddress?: string;
  /**
   * 户籍类型
   */
  householdType?: string;
  idCardNo?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  isJob?: string;
  isRecord?: string;
  loginDate?: string;
  loginIp?: string;
  nation?: string;
  notLocal?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  partyMemberType?: string;
  password?: string;
  phone?: string;
  /**
   * 政治面貌
   */
  politicStatus?: string;
  /**
   * 党内职务
   */
  position?: string;
  postIds?: number[];
  removeReason?: string;
  removeTime?: string;
  roleIds?: number[];
  /**
   * 是否管理员
   */
  "roles[0].admin"?: boolean;
  /**
   * 创建者
   */
  "roles[0].createBy"?: string;
  /**
   * 创建时间
   */
  "roles[0].createTime"?: string;
  /**
   * 数据范围（1：所有数据权限；2：自定义数据权限；3：本组织数据权限；4：本组织及以下数据权限；5：仅本人数据权限）
   */
  "roles[0].dataScope"?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  "roles[0].delFlag"?: string;
  /**
   * 组织树选择项是否关联显示（0：父子不互相关联显示 1：父子互相关联显示 ）
   */
  "roles[0].deptCheckStrictly"?: boolean;
  /**
   * 组织组（数据权限）
   */
  "roles[0].deptIds"?: number[];
  /**
   * 用户是否存在此角色标识 默认不存在
   */
  "roles[0].flag"?: boolean;
  /**
   * 菜单树选择项是否关联显示（ 0：父子不互相关联显示 1：父子互相关联显示）
   */
  "roles[0].menuCheckStrictly"?: boolean;
  /**
   * 菜单组
   */
  "roles[0].menuIds"?: number[];
  /**
   * 请求参数
   */
  "roles[0].params"?: { [key: string]: any };
  /**
   * 备注
   */
  "roles[0].remark"?: string;
  /**
   * 角色ID
   */
  "roles[0].roleId"?: number;
  /**
   * 角色权限
   */
  "roles[0].roleKey": string;
  /**
   * 角色名称
   */
  "roles[0].roleName": string;
  /**
   * 角色排序
   */
  "roles[0].roleSort": string;
  /**
   * 搜索值
   */
  "roles[0].searchValue"?: string;
  /**
   * 角色状态（0正常 1停用）
   */
  "roles[0].status"?: string;
  /**
   * 更新者
   */
  "roles[0].updateBy"?: string;
  /**
   * 更新时间
   */
  "roles[0].updateTime"?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  sex?: string;
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  userId?: number;
  userName?: string;
  userType?: string;
};

export type PostSystemUserProfileAvatarBody = {
  /** avatarfile */
  avatarfile: Blob;
};

export type GetSystemPostListParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 用户是否存在此岗位标识 默认不存在
   */
  flag?: boolean;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 岗位编码
   */
  postCode: string;
  /**
   * 岗位序号
   */
  postId?: number;
  /**
   * 岗位名称
   */
  postName: string;
  /**
   * 岗位排序
   */
  postSort: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 状态（0正常 1停用）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type PostSystemPostExportParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 用户是否存在此岗位标识 默认不存在
   */
  flag?: boolean;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 岗位编码
   */
  postCode: string;
  /**
   * 岗位序号
   */
  postId?: number;
  /**
   * 岗位名称
   */
  postName: string;
  /**
   * 岗位排序
   */
  postSort: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 状态（0正常 1停用）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type PostSystemOssUploadBody = {
  /** file */
  file: Blob;
};

export type GetSystemOssListParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 文件名
   */
  fileName?: string;
  /**
   * 文件后缀名
   */
  fileSuffix?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 原名
   */
  originalName?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 服务商
   */
  service?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * URL地址
   */
  url?: string;
};

export type GetSystemOssConfigListParams = {
  /**
   * accessKey
   */
  accessKey: string;
  /**
   * bucketName
   */
  bucketName: string;
  /**
   * configKey
   */
  configKey: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * endpoint
   */
  endpoint: string;
  /**
   * 扩展字段
   */
  ext1?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 是否https（Y=是,N=否）
   */
  isHttps?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 主建
   */
  ossConfigId: number;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 前缀
   */
  prefix?: string;
  /**
   * region
   */
  region?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * secretKey
   */
  secretKey: string;
  /**
   * 状态（0=正常,1=停用）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetMonitorOperlogListParams = {
  /**
   * 业务类型（0其它 1新增 2修改 3删除）
   */
  businessType?: number;
  /**
   * 业务类型数组
   */
  businessTypes?: number[];
  /**
   * 组织名称
   */
  deptName?: string;
  /**
   * 错误消息
   */
  errorMsg?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 返回参数
   */
  jsonResult?: string;
  /**
   * 请求方法
   */
  method?: string;
  /**
   * 日志主键
   */
  operId?: number;
  /**
   * 操作地址
   */
  operIp?: string;
  /**
   * 操作地点
   */
  operLocation?: string;
  /**
   * 操作人员
   */
  operName?: string;
  /**
   * 请求参数
   */
  operParam?: string;
  /**
   * 操作时间
   */
  operTime?: string;
  /**
   * 请求url
   */
  operUrl?: string;
  /**
   * 操作类别（0其它 1后台用户 2手机端用户）
   */
  operatorType?: number;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 请求方式
   */
  requestMethod?: string;
  /**
   * 操作状态（0正常 1异常）
   */
  status?: number;
  /**
   * 操作模块
   */
  title?: string;
};

export type PostMonitorOperlogExportParams = {
  /**
   * 业务类型（0其它 1新增 2修改 3删除）
   */
  businessType?: number;
  /**
   * 业务类型数组
   */
  businessTypes?: number[];
  /**
   * 组织名称
   */
  deptName?: string;
  /**
   * 错误消息
   */
  errorMsg?: string;
  /**
   * 返回参数
   */
  jsonResult?: string;
  /**
   * 请求方法
   */
  method?: string;
  /**
   * 日志主键
   */
  operId?: number;
  /**
   * 操作地址
   */
  operIp?: string;
  /**
   * 操作地点
   */
  operLocation?: string;
  /**
   * 操作人员
   */
  operName?: string;
  /**
   * 请求参数
   */
  operParam?: string;
  /**
   * 操作时间
   */
  operTime?: string;
  /**
   * 请求url
   */
  operUrl?: string;
  /**
   * 操作类别（0其它 1后台用户 2手机端用户）
   */
  operatorType?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 请求方式
   */
  requestMethod?: string;
  /**
   * 操作状态（0正常 1异常）
   */
  status?: number;
  /**
   * 操作模块
   */
  title?: string;
};

export type GetSystemNoticeNoticeDetailParams = {
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 消息状态;(0未读 1已读)
   */
  msgStatus?: string;
  /**
   * 标题
   */
  msgTitle?: string;
  /**
   * 消息类型
   */
  msgType?: string;
  /**
   * 通知id
   */
  noticeId?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 用户id
   */
  userId?: number;
  /**
   * 党员姓名
   */
  userName?: string;
};

export type GetSystemNoticeListParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  delFlag?: number;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 公告内容
   */
  noticeContent: string;
  /**
   * 公告ID
   */
  noticeId: number;
  /**
   * 通知对象（0组织 1个人）
   */
  noticeObject: string;
  /**
   * 通知党员或组织
   */
  noticePeople: string;
  /**
   * 公告标题
   */
  noticeTitle: string;
  /**
   * 公告类型
   */
  noticeType: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 推送通知次数
   */
  pushCount?: number;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 公告状态（0正常 1关闭）
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetSystemMsgHasReadParams = {
  /**
   * 消息id
   */
  msgId: number;
};

export type GetSystemMenuTreeselectChildrenItem = { [key: string]: any };

export type GetSystemMenuTreeselectParams = {
  /**
   * 子组织
   */
  children?: GetSystemMenuTreeselectChildrenItem[];
  /**
   * 组件路径
   */
  component?: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 菜单图标
   */
  icon?: string;
  /**
   * 是否缓存（0缓存 1不缓存）
   */
  isCache?: string;
  /**
   * 是否为外链（0是 1否）
   */
  isFrame?: string;
  /**
   * 菜单ID
   */
  menuId?: number;
  /**
   * 菜单名称
   */
  menuName: string;
  /**
   * 类型（M目录 C菜单 F按钮）
   */
  menuType: string;
  /**
   * 显示顺序
   */
  orderNum: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 父菜单ID
   */
  parentId?: number;
  /**
   * 父菜单名称
   */
  parentName?: string;
  /**
   * 路由地址
   */
  path?: string;
  /**
   * 权限字符串
   */
  perms?: string;
  /**
   * 路由参数
   */
  query?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 菜单状态（0显示 1隐藏）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 显示状态（0显示 1隐藏）
   */
  visible?: string;
};

export type GetSystemMenuListChildrenItem = { [key: string]: any };

export type GetSystemMenuListParams = {
  /**
   * 子组织
   */
  children?: GetSystemMenuListChildrenItem[];
  /**
   * 组件路径
   */
  component?: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 菜单图标
   */
  icon?: string;
  /**
   * 是否缓存（0缓存 1不缓存）
   */
  isCache?: string;
  /**
   * 是否为外链（0是 1否）
   */
  isFrame?: string;
  /**
   * 菜单ID
   */
  menuId?: number;
  /**
   * 菜单名称
   */
  menuName: string;
  /**
   * 类型（M目录 C菜单 F按钮）
   */
  menuType: string;
  /**
   * 显示顺序
   */
  orderNum: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 父菜单ID
   */
  parentId?: number;
  /**
   * 父菜单名称
   */
  parentName?: string;
  /**
   * 路由地址
   */
  path?: string;
  /**
   * 权限字符串
   */
  perms?: string;
  /**
   * 路由参数
   */
  query?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 菜单状态（0显示 1隐藏）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 显示状态（0显示 1隐藏）
   */
  visible?: string;
};

export type GetMonitorLogininforListParams = {
  /**
   * 浏览器类型
   */
  browser?: string;
  /**
   * 访问ID
   */
  infoId?: number;
  /**
   * 登录IP地址
   */
  ipaddr?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 登录地点
   */
  loginLocation?: string;
  /**
   * 访问时间
   */
  loginTime?: string;
  /**
   * 提示消息
   */
  msg?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 操作系统
   */
  os?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 登录状态 0成功 1失败
   */
  status?: string;
  /**
   * 用户账号
   */
  userName?: string;
};

export type PostMonitorLogininforExportParams = {
  /**
   * 浏览器类型
   */
  browser?: string;
  /**
   * 访问ID
   */
  infoId?: number;
  /**
   * 登录IP地址
   */
  ipaddr?: string;
  /**
   * 登录地点
   */
  loginLocation?: string;
  /**
   * 访问时间
   */
  loginTime?: string;
  /**
   * 提示消息
   */
  msg?: string;
  /**
   * 操作系统
   */
  os?: string;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 登录状态 0成功 1失败
   */
  status?: string;
  /**
   * 用户账号
   */
  userName?: string;
};

export type GetDownloadThumbnailParams = {
  /**
   * 文件路径
   */
  filePath?: string;
  /**
   * 高度
   */
  height: number;
  /**
   * 宽度
   */
  width: number;
};

export type GetSystemDictTypeListParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 字典主键
   */
  dictId?: number;
  /**
   * 字典名称
   */
  dictName: string;
  /**
   * 字典类型
   */
  dictType: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 备注
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 状态（0正常 1停用）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type PostSystemDictTypeExportParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 字典主键
   */
  dictId?: number;
  /**
   * 字典名称
   */
  dictName: string;
  /**
   * 字典类型
   */
  dictType: string;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 备注
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 状态（0正常 1停用）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetSystemDictDataListParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 样式属性（其他样式扩展）
   */
  cssClass?: string;
  default?: boolean;
  /**
   * 字典编码
   */
  dictCode?: number;
  /**
   * 字典标签
   */
  dictLabel: string;
  /**
   * 字典排序
   */
  dictSort?: number;
  /**
   * 字典类型
   */
  dictType: string;
  /**
   * 字典键值
   */
  dictValue: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 是否默认（Y是 N否）
   */
  isDefault?: string;
  /**
   * 表格字典样式
   */
  listClass?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 备注
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 状态（0正常 1停用）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type PostSystemDictDataExportParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 样式属性（其他样式扩展）
   */
  cssClass?: string;
  default?: boolean;
  /**
   * 字典编码
   */
  dictCode?: number;
  /**
   * 字典标签
   */
  dictLabel: string;
  /**
   * 字典排序
   */
  dictSort?: number;
  /**
   * 字典类型
   */
  dictType: string;
  /**
   * 字典键值
   */
  dictValue: string;
  /**
   * 是否默认（Y是 N否）
   */
  isDefault?: string;
  /**
   * 表格字典样式
   */
  listClass?: string;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 备注
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 状态（0正常 1停用）
   */
  status?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetSystemDeptTreeselectChildrenItem = { [key: string]: any };

export type GetSystemDeptTreeselectParams = {
  /**
   * 详细地址
   */
  address?: string;
  /**
   * 祖级列表
   */
  ancestors?: string;
  /**
   * 子组织
   */
  children?: GetSystemDeptTreeselectChildrenItem[];
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  delFlag?: string;
  /**
   * 单位情况
   */
  deptDesc?: string;
  /**
   * 组织id
   */
  deptId?: number;
  /**
   * 组织隶属
   */
  deptLevel?: string;
  /**
   * 组织名称
   */
  deptName: string;
  /**
   * 组织类别
   */
  deptType: string;
  /**
   * 邮箱
   */
  email?: string;
  /**
   * 组织图片
   */
  imageUrl?: string;
  /**
   * 是否异地组织
   */
  isOut: string;
  /**
   * 党组织书记
   */
  leader?: string;
  /**
   * 显示排序
   */
  orderNum: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 父组织id
   */
  parentId: number;
  /**
   * 父菜单名称
   */
  parentName?: string;
  /**
   * 联系电话
   */
  phone?: string;
  /**
   * 成立时间
   */
  regDate?: string;
  /**
   * 相关资料
   */
  relDoc?: string;
  /**
   * 组织简介
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 组织简称
   */
  shortName: string;
  /**
   * :0正常,1停用
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetSystemDeptListChildrenItem = { [key: string]: any };

export type GetSystemDeptListParams = {
  /**
   * 详细地址
   */
  address?: string;
  /**
   * 祖级列表
   */
  ancestors?: string;
  /**
   * 子组织
   */
  children?: GetSystemDeptListChildrenItem[];
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 删除标志（0代表存在 2代表删除）
   */
  delFlag?: string;
  /**
   * 单位情况
   */
  deptDesc?: string;
  /**
   * 组织id
   */
  deptId?: number;
  /**
   * 组织隶属
   */
  deptLevel?: string;
  /**
   * 组织名称
   */
  deptName: string;
  /**
   * 组织类别
   */
  deptType: string;
  /**
   * 邮箱
   */
  email?: string;
  /**
   * 组织图片
   */
  imageUrl?: string;
  /**
   * 是否异地组织
   */
  isOut: string;
  /**
   * 党组织书记
   */
  leader?: string;
  /**
   * 显示排序
   */
  orderNum: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 父组织id
   */
  parentId: number;
  /**
   * 父菜单名称
   */
  parentName?: string;
  /**
   * 联系电话
   */
  phone?: string;
  /**
   * 成立时间
   */
  regDate?: string;
  /**
   * 相关资料
   */
  relDoc?: string;
  /**
   * 组织简介
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 组织简称
   */
  shortName: string;
  /**
   * :0正常,1停用
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type PostSystemDeptImportDataBody = {
  /** file */
  file: Blob;
};

export type GetSystemConfigListParams = {
  /**
   * 参数主键
   */
  configId?: number;
  /**
   * 参数键名
   */
  configKey: string;
  /**
   * 参数名称
   */
  configName: string;
  /**
   * 系统内置（Y是 N否）
   */
  configType?: string;
  /**
   * 参数键值
   */
  configValue: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 备注
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type PostSystemConfigExportParams = {
  /**
   * 参数主键
   */
  configId?: number;
  /**
   * 参数键名
   */
  configKey: string;
  /**
   * 参数名称
   */
  configName: string;
  /**
   * 系统内置（Y是 N否）
   */
  configType?: string;
  /**
   * 参数键值
   */
  configValue: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 备注
   */
  remark?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetPartyUserRecordListParams = {
  /**
   * 头像地址
   */
  avatar?: string;
  /**
   * 生日
   */
  birthday: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 学历;字典-学历
   */
  education: string;
  /**
   * 邮箱
   */
  email: string;
  /**
   * 入党时间
   */
  entryPartyDate: string;
  /**
   * 家庭住址
   */
  homeAddress?: string;
  /**
   * 身份证号
   */
  idCardNo: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 民族;字典-民族
   */
  nation: string;
  /**
   * 流入地党组织或流出地党组织
   */
  notLocal?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党员类别
   */
  partyMemberType?: string;
  /**
   * 手机号码
   */
  phone: string;
  /**
   * 党内职务
   */
  position?: string;
  /**
   * 移除原因
   */
  removeReason?: string;
  /**
   * 移除时间
   */
  removeTime?: string;
  /**
   * 角色id
   */
  roleIds: number[];
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 用户性别;（0男 1女 2未知）
   */
  sex: string;
  /**
   * 党员状态;（0正常 1停用）
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 党员id
   */
  userId: number;
  /**
   * 党员姓名
   */
  userName: string;
};

export type PostPartyUserRecordExportParams = {
  /**
   * 头像地址
   */
  avatar?: string;
  /**
   * 生日
   */
  birthday: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 学历;字典-学历
   */
  education: string;
  /**
   * 邮箱
   */
  email: string;
  /**
   * 入党时间
   */
  entryPartyDate: string;
  /**
   * 家庭住址
   */
  homeAddress?: string;
  /**
   * 身份证号
   */
  idCardNo: string;
  /**
   * 民族;字典-民族
   */
  nation: string;
  /**
   * 流入地党组织或流出地党组织
   */
  notLocal?: string;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党员类别
   */
  partyMemberType?: string;
  /**
   * 手机号码
   */
  phone: string;
  /**
   * 党内职务
   */
  position?: string;
  /**
   * 移除原因
   */
  removeReason?: string;
  /**
   * 移除时间
   */
  removeTime?: string;
  /**
   * 角色id
   */
  roleIds: number[];
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 用户性别;（0男 1女 2未知）
   */
  sex: string;
  /**
   * 党员状态;（0正常 1停用）
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 党员id
   */
  userId: number;
  /**
   * 党员姓名
   */
  userName: string;
};

export type GetPartyBirthdayNoticeDetailParams = {
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 消息状态;(0未读 1已读)
   */
  msgStatus?: string;
  /**
   * 标题
   */
  msgTitle?: string;
  /**
   * 消息类型
   */
  msgType?: string;
  /**
   * 通知id
   */
  noticeId?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 用户id
   */
  userId?: number;
  /**
   * 党员姓名
   */
  userName?: string;
};

export type GetPartyBirthdayListParams = {
  /**
   * 头像地址
   */
  avatar?: string;
  /**
   * 生日
   */
  birthday: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 学历;字典-学历
   */
  education: string;
  /**
   * 邮箱
   */
  email: string;
  /**
   * 入党时间
   */
  entryPartyDate: string;
  /**
   * 家庭住址
   */
  homeAddress?: string;
  /**
   * 身份证号
   */
  idCardNo: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 民族;字典-民族
   */
  nation: string;
  /**
   * 流入地党组织或流出地党组织
   */
  notLocal?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党员类别
   */
  partyMemberType?: string;
  /**
   * 手机号码
   */
  phone: string;
  /**
   * 党内职务
   */
  position?: string;
  /**
   * 移除原因
   */
  removeReason?: string;
  /**
   * 移除时间
   */
  removeTime?: string;
  /**
   * 角色id
   */
  roleIds: number[];
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 用户性别;（0男 1女 2未知）
   */
  sex: string;
  /**
   * 党员状态;（0正常 1停用）
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 党员id
   */
  userId: number;
  /**
   * 党员姓名
   */
  userName: string;
};

export type GetPartyUserTransferListParams = {
  /**
   * 转出组织ID
   */
  fromDeptId: number;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 转移关系类型;1 内部调动  2 转入 3 转出 4 死亡 5 出党 6 停止党籍 7 恢复党籍
   */
  opType: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 党员姓名
   */
  userName?: string;
};

export type GetPartyUserTransferGetStopPartyMembersParams = {
  /**
   * deptId
   */
  deptId: number;
};

export type GetPartyUserIntegrationListParams = {
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * userName
   */
  userName?: string;
};

export type PostPartyUserIntegrationImportDataBody = {
  /** file */
  file: Blob;
};

export type PostPartyUserIntegrationExportParams = {
  /**
   * userName
   */
  userName?: string;
};

export type GetPartyPdOrganizeLifeNoticeInfoByIdParams = {
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
};

export type GetPartyPdOrganizeLifeListParams = {
  /**
   * 审核状态(0未审核 1已审核 2被驳回)
   */
  audit?: string;
  /**
   * 内容类型(1、教育培训（远教）2、重大事项讨论3、民主评议4、党日活动)
   */
  contentType?: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 创建结束时间
   */
  createdEndTime?: string;
  /**
   * 创建开始时间
   */
  createdStartTime?: string;
  /**
   * 删除标志;0 正常 2删除
   */
  delFlag?: string;
  /**
   * 延时时间(分钟)
   */
  delayTime?: string;
  /**
   * 结束时间
   */
  endTime: string;
  /**
   * 主持人
   */
  host?: string;
  /**
   * 主键id
   */
  id?: number;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 会议地址
   */
  meetingAddress?: string;
  /**
   * 会议内容
   */
  meetingContent?: string;
  /**
   * 会议资料地址
   */
  meetingInfo?: string;
  /**
   * 会议名称
   */
  meetingName?: string;
  /**
   * 会议图片地址
   */
  meetingPictures?: string;
  /**
   * 会议状态(未开始 进行中 已结束)
   */
  meetingStatus?: string;
  /**
   * 通知内容
   */
  noticeContent?: string;
  /**
   * 通知标题
   */
  noticeTitle?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 参会对象(0、组织1、个人)
   */
  participants?: string;
  /**
   * 参会党员
   */
  partyMembers?: string;
  /**
   * 参会组织
   */
  partyOrg?: string;
  /**
   * 主讲人
   */
  presenter?: string;
  /**
   * 发布组织
   */
  publishOrg?: number;
  /**
   * 范围类型(1、党（支）委会2、党员大会3、党小组会)
   */
  rangeType?: string;
  /**
   * 记录人
   */
  recorder?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 签到方式
   */
  signIn?: string;
  /**
   * 开始时间
   */
  startingTime: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 当前登录人
   */
  userId?: number;
};

export type GetPartyPdOrganizeLifeGetPartyListParams = {
  /**
   * 缺席原因
   */
  absentReason?: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 删除标志;0 正常 2删除
   */
  delFlag?: string;
  /**
   * id
   */
  id?: number;
  /**
   * idList
   */
  idList?: number[];
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 会议id
   */
  meetingId?: number;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党员id
   */
  partyId: number;
  /**
   * 参会党员名称
   */
  partyName?: string;
  /**
   * 参会状态(1未参会 2参会 3缺席(未请假) 4请假 5签到未到场)
   */
  partyStatus: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 签到状态(1已签到 2未签到)
   */
  signStatus: string;
  /**
   * 签到时间
   */
  signTime?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetPartyOrgTransitionListParams = {
  /**
   * 实到人数
   */
  actualNum: number;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 延期月数
   */
  delayMonth: number;
  /**
   * 换届组织id
   */
  deptId: number;
  /**
   * 主键
   */
  id: number;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 是否现届
   */
  isCurrent: string;
  /**
   * 是否有办理延期
   */
  isDelay: string;
  /**
   * 候选人名单请示内容
   */
  listApplyContent?: string;
  /**
   * 候选人名单请示时间
   */
  listApplyDate: string;
  /**
   * 候选人名单批复内容
   */
  listApprovalContent?: string;
  /**
   * 候选人名单批复时间
   */
  listApprovalDate: string;
  /**
   * 会议类型
   */
  meetingType: string;
  /**
   * 应换届时间
   */
  nextTransitionDate: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 届数
   */
  periodNum?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 换届结果申请内容
   */
  resultApplyContent?: string;
  /**
   * 换届结果申请时间
   */
  resultApplyDate: string;
  /**
   * 换届结果批复内容
   */
  resultApprovalContent?: string;
  /**
   * 换届结果批复时间
   */
  resultApprovalDate: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 应到人数
   */
  shouldNum: number;
  /**
   * 任期年限
   */
  termYear: number;
  /**
   * 换届请示内容
   */
  transitionApplyContent?: string;
  /**
   * 换届请示时间
   */
  transitionApplyDate: string;
  /**
   * 换届请示书面批复
   */
  transitionApprovalContent: string;
  /**
   * 换届请示批复时间
   */
  transitionApprovalDate: string;
  /**
   * 换届时间
   */
  transitionDate: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type PostPartyOrgTransitionExportParams = {
  /**
   * 实到人数
   */
  actualNum: number;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 延期月数
   */
  delayMonth: number;
  /**
   * 换届组织id
   */
  deptId: number;
  /**
   * 主键
   */
  id: number;
  /**
   * 是否现届
   */
  isCurrent: string;
  /**
   * 是否有办理延期
   */
  isDelay: string;
  /**
   * 候选人名单请示内容
   */
  listApplyContent?: string;
  /**
   * 候选人名单请示时间
   */
  listApplyDate: string;
  /**
   * 候选人名单批复内容
   */
  listApprovalContent?: string;
  /**
   * 候选人名单批复时间
   */
  listApprovalDate: string;
  /**
   * 会议类型
   */
  meetingType: string;
  /**
   * 应换届时间
   */
  nextTransitionDate: string;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 届数
   */
  periodNum?: string;
  /**
   * 备注
   */
  remark?: string;
  /**
   * 换届结果申请内容
   */
  resultApplyContent?: string;
  /**
   * 换届结果申请时间
   */
  resultApplyDate: string;
  /**
   * 换届结果批复内容
   */
  resultApprovalContent?: string;
  /**
   * 换届结果批复时间
   */
  resultApprovalDate: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 应到人数
   */
  shouldNum: number;
  /**
   * 任期年限
   */
  termYear: number;
  /**
   * 换届请示内容
   */
  transitionApplyContent?: string;
  /**
   * 换届请示时间
   */
  transitionApplyDate: string;
  /**
   * 换届请示书面批复
   */
  transitionApprovalContent: string;
  /**
   * 换届请示批复时间
   */
  transitionApprovalDate: string;
  /**
   * 换届时间
   */
  transitionDate: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetPartyLeadershipListParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 主键
   */
  id: number;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 是否离任
   */
  isLeave: string;
  /**
   * 离任时间
   */
  leaveDate?: string;
  /**
   * 任职时间
   */
  officeDate: string;
  /**
   * 任职方式
   */
  officeWay: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党内职务
   */
  partyPosts: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 班子职务排序
   */
  sortNo: number;
  /**
   * 换届组织id
   */
  transitionId: number;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 党员id
   */
  userId: number;
  /**
   * 党员姓名
   */
  userName?: string;
};

export type PostPartyLeadershipExportParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 主键
   */
  id: number;
  /**
   * 是否离任
   */
  isLeave: string;
  /**
   * 离任时间
   */
  leaveDate?: string;
  /**
   * 任职时间
   */
  officeDate: string;
  /**
   * 任职方式
   */
  officeWay: string;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党内职务
   */
  partyPosts: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 班子职务排序
   */
  sortNo: number;
  /**
   * 换届组织id
   */
  transitionId: number;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 党员id
   */
  userId: number;
  /**
   * 党员姓名
   */
  userName?: string;
};

export type GetPartyInfoSectionTreeselectParams = {
  /**
   * APP端栏目缩略图
   */
  appImage?: string;
  /**
   * 分类名字
   */
  categoryName: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 主键
   */
  id: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 父节点
   */
  parentId?: number;
  /**
   * PC端栏目缩略图
   */
  pcImage?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 排序
   */
  sortNo: number;
  /**
   * 状态; 0显示 1隐藏
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetPartyInfoSectionCheckCategoryNameUniqueParams = {
  /**
   * 栏目名称
   */
  categoryName: string;
  /**
   * 栏目id
   */
  id?: number;
};

export type GetPartyInfoListListParams = {
  /**
   * 作者
   */
  author?: string;
  /**
   * 新闻详情
   */
  content?: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 发布组织
   */
  deptId: number;
  /**
   * 主键
   */
  id: number;
  /**
   * 新闻简介
   */
  introduction?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 新闻链接
   */
  link?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 发布时间
   */
  publishTime: string;
  /**
   * 阅读次数
   */
  readCount: number;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 栏目id
   */
  sectionId: number;
  /**
   * 排序
   */
  sortNo: number;
  /**
   * 新闻来源
   */
  source: string;
  /**
   * 新闻缩略图
   */
  thumbnail?: string;
  /**
   * 新闻标题
   */
  title: string;
  /**
   * 置顶;（Y是 N否）
   */
  top: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetPartyInfoBaseListParams = {
  /**
   * 作者
   */
  author: string;
  /**
   * 新闻详情
   */
  content: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 主键
   */
  id: number;
  /**
   * 新闻简介
   */
  introduction: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 新闻链接
   */
  link: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 发布时间
   */
  publishTime: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 所属栏目
   */
  sectionId: number;
  /**
   * 新闻来源
   */
  source: string;
  /**
   * 新闻缩略图
   */
  thumbnail: string;
  /**
   * 新闻标题
   */
  title: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetPartyDemocraticDeliberationResultListParams = {
  /**
   * 支部认定等级(0优秀1合格2基本合格3不合格4弃权)
   */
  branchCertificationLevel: string;
  /**
   * 评议奖惩情况(0无奖惩1有奖惩)
   */
  commentRewards: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 主键
   */
  id: number;
  /**
   * id集合
   */
  idList: number[];
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 会议id
   */
  meetingId: number;
  /**
   * 互评基本合格
   */
  mutualEvaluationBasicQualified: number;
  /**
   * 互评优秀
   */
  mutualEvaluationExcellent: number;
  /**
   * 互评不合格
   */
  mutualEvaluationFailed: number;
  /**
   * 互评合格
   */
  mutualEvaluationQualified: number;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 参会党员id
   */
  partyId: number;
  /**
   * 奖惩说明
   */
  rewardsDescription: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetPartyDemocraticDeliberationMutualListParams = {
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 主键
   */
  id: number;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 会议id
   */
  meetingId: number;
  /**
   * 互评状态(0未完成1已完成)
   */
  mutualEvaluationStatus: string;
  /**
   * 互评时间
   */
  mutualEvaluationTime: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 参会党员id
   */
  partyId: number;
  /**
   * 参会党员名称
   */
  partyName: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetPartyDemocraticDeliberationListParams = {
  /**
   * 审核状态(0未审核 1已审核2被驳回)
   */
  audit: string;
  /**
   * 审核人
   */
  auditBy: string;
  /**
   * 审核时间
   */
  auditTime: string;
  /**
   * 评议地址
   */
  commentAddress: string;
  /**
   * 评议内容
   */
  commentContent: string;
  /**
   * 评议党员id
   */
  commentParty: string;
  /**
   * 评议主题
   */
  commentTopic: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 结束时间
   */
  endTime: string;
  /**
   * 优秀人数上限
   */
  excellentNumberLimit: number;
  /**
   * 主键
   */
  id: number;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 会议资料地址
   */
  meetingInfo: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 发布组织id
   */
  publishOrg: number;
  /**
   * 查询条件结束时间
   */
  searchEndTime?: string;
  /**
   * 查询条件开始时间
   */
  searchStartTime?: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 开始时间
   */
  startingTime: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type PostPartyDemocraticDeliberationExportParams = {
  /**
   * 支部认定等级(0优秀1合格2基本合格3不合格4弃权)
   */
  branchCertificationLevel: string;
  /**
   * 评议奖惩情况(0无奖惩1有奖惩)
   */
  commentRewards: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 主键
   */
  id: number;
  /**
   * id集合
   */
  idList: number[];
  /**
   * 会议id
   */
  meetingId: number;
  /**
   * 互评基本合格
   */
  mutualEvaluationBasicQualified: number;
  /**
   * 互评优秀
   */
  mutualEvaluationExcellent: number;
  /**
   * 互评不合格
   */
  mutualEvaluationFailed: number;
  /**
   * 互评合格
   */
  mutualEvaluationQualified: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 参会党员id
   */
  partyId: number;
  /**
   * 奖惩说明
   */
  rewardsDescription: string;
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetActivityListParams = {
  /**
   * 活动名称
   */
  activityName: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 活动描述
   */
  description?: string;
  /**
   * 活动结束时间
   */
  endTime: string;
  /**
   * 表格链接
   */
  formLink?: string;
  /**
   * 主键
   */
  id: number;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 活动开始时间
   */
  startTime: string;
  /**
   * 活动缩略图
   */
  thumbnail?: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
};

export type GetPartyUserDevelopListParams = {
  deptId?: number;
  devStage?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  userName?: string;
};

export type GetPartyUserApplicationListParams = {
  deptId?: number;
  devStage?: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  userName?: string;
};

export type GetPartyUserJobListParams = {
  /**
   * 头像地址
   */
  avatar?: string;
  /**
   * 生日
   */
  birthday: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 学历;字典-学历
   */
  education: string;
  /**
   * 邮箱
   */
  email: string;
  /**
   * 入党时间
   */
  entryPartyDate: string;
  /**
   * 家庭住址
   */
  homeAddress?: string;
  /**
   * 身份证号
   */
  idCardNo: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 民族;字典-民族
   */
  nation: string;
  /**
   * 流入地党组织或流出地党组织
   */
  notLocal?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党员类别
   */
  partyMemberType?: string;
  /**
   * 手机号码
   */
  phone: string;
  /**
   * 党内职务
   */
  position?: string;
  /**
   * 移除原因
   */
  removeReason?: string;
  /**
   * 移除时间
   */
  removeTime?: string;
  /**
   * 角色id
   */
  roleIds: number[];
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 用户性别;（0男 1女 2未知）
   */
  sex: string;
  /**
   * 党员状态;（0正常 1停用）
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 党员id
   */
  userId: number;
  /**
   * 党员姓名
   */
  userName: string;
};

export type PostPartyUserJobImportDataBody = {
  /** file */
  file: Blob;
};

export type GetPartyUserJobGetUserByUserIdsParams = {
  /**
   * 用户id
   */
  userIds: string;
};

export type GetPartyUserJobGetJobPartyMembersParams = {
  /**
   * 组织id
   */
  deptId: number;
};

export type PostPartyUserJobExportParams = {
  /**
   * 头像地址
   */
  avatar?: string;
  /**
   * 生日
   */
  birthday: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 学历;字典-学历
   */
  education: string;
  /**
   * 邮箱
   */
  email: string;
  /**
   * 入党时间
   */
  entryPartyDate: string;
  /**
   * 家庭住址
   */
  homeAddress?: string;
  /**
   * 身份证号
   */
  idCardNo: string;
  /**
   * 民族;字典-民族
   */
  nation: string;
  /**
   * 流入地党组织或流出地党组织
   */
  notLocal?: string;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党员类别
   */
  partyMemberType?: string;
  /**
   * 手机号码
   */
  phone: string;
  /**
   * 党内职务
   */
  position?: string;
  /**
   * 移除原因
   */
  removeReason?: string;
  /**
   * 移除时间
   */
  removeTime?: string;
  /**
   * 角色id
   */
  roleIds: number[];
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 用户性别;（0男 1女 2未知）
   */
  sex: string;
  /**
   * 党员状态;（0正常 1停用）
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 党员id
   */
  userId: number;
  /**
   * 党员姓名
   */
  userName: string;
};

export type GetPartyUserHistoryListParams = {
  /**
   * 头像地址
   */
  avatar?: string;
  /**
   * 生日
   */
  birthday: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 学历;字典-学历
   */
  education: string;
  /**
   * 邮箱
   */
  email: string;
  /**
   * 入党时间
   */
  entryPartyDate: string;
  /**
   * 家庭住址
   */
  homeAddress?: string;
  /**
   * 身份证号
   */
  idCardNo: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 民族;字典-民族
   */
  nation: string;
  /**
   * 流入地党组织或流出地党组织
   */
  notLocal?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党员类别
   */
  partyMemberType?: string;
  /**
   * 手机号码
   */
  phone: string;
  /**
   * 党内职务
   */
  position?: string;
  /**
   * 移除原因
   */
  removeReason?: string;
  /**
   * 移除时间
   */
  removeTime?: string;
  /**
   * 角色id
   */
  roleIds: number[];
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 用户性别;（0男 1女 2未知）
   */
  sex: string;
  /**
   * 党员状态;（0正常 1停用）
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 党员id
   */
  userId: number;
  /**
   * 党员姓名
   */
  userName: string;
};

export type GetPartyUserFlowListParams = {
  /**
   * 头像地址
   */
  avatar?: string;
  /**
   * 生日
   */
  birthday: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 学历;字典-学历
   */
  education: string;
  /**
   * 邮箱
   */
  email: string;
  /**
   * 入党时间
   */
  entryPartyDate: string;
  /**
   * 家庭住址
   */
  homeAddress?: string;
  /**
   * 身份证号
   */
  idCardNo: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 民族;字典-民族
   */
  nation: string;
  /**
   * 流入地党组织或流出地党组织
   */
  notLocal?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党员类别
   */
  partyMemberType?: string;
  /**
   * 手机号码
   */
  phone: string;
  /**
   * 党内职务
   */
  position?: string;
  /**
   * 移除原因
   */
  removeReason?: string;
  /**
   * 移除时间
   */
  removeTime?: string;
  /**
   * 角色id
   */
  roleIds: number[];
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 用户性别;（0男 1女 2未知）
   */
  sex: string;
  /**
   * 党员状态;（0正常 1停用）
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 党员id
   */
  userId: number;
  /**
   * 党员姓名
   */
  userName: string;
};

export type GetPartyAddressBookListParams = {
  /**
   * 头像地址
   */
  avatar?: string;
  /**
   * 生日
   */
  birthday: string;
  /**
   * 创建者
   */
  createBy?: string;
  /**
   * 创建时间
   */
  createTime?: string;
  /**
   * 组织id
   */
  deptId: number;
  /**
   * 学历;字典-学历
   */
  education: string;
  /**
   * 邮箱
   */
  email: string;
  /**
   * 入党时间
   */
  entryPartyDate: string;
  /**
   * 家庭住址
   */
  homeAddress?: string;
  /**
   * 身份证号
   */
  idCardNo: string;
  /**
   * 排序的方向
   */
  isAsc?: string;
  /**
   * 民族;字典-民族
   */
  nation: string;
  /**
   * 流入地党组织或流出地党组织
   */
  notLocal?: string;
  /**
   * 排序列
   */
  orderByColumn?: string;
  /**
   * 当前页数
   */
  pageNum?: number;
  /**
   * 分页大小
   */
  pageSize?: number;
  /**
   * 请求参数
   */
  params?: { [key: string]: any };
  /**
   * 党员类别
   */
  partyMemberType?: string;
  /**
   * 手机号码
   */
  phone: string;
  /**
   * 党内职务
   */
  position?: string;
  /**
   * 移除原因
   */
  removeReason?: string;
  /**
   * 移除时间
   */
  removeTime?: string;
  /**
   * 角色id
   */
  roleIds: number[];
  /**
   * 搜索值
   */
  searchValue?: string;
  /**
   * 用户性别;（0男 1女 2未知）
   */
  sex: string;
  /**
   * 党员状态;（0正常 1停用）
   */
  status: string;
  /**
   * 更新者
   */
  updateBy?: string;
  /**
   * 更新时间
   */
  updateTime?: string;
  /**
   * 党员id
   */
  userId: number;
  /**
   * 党员姓名
   */
  userName: string;
};

export interface 领导班子视图对象 {
  /** 头像 */
  avatar?: string;
  /** 组织id */
  deptId?: number;
  /** 任职所在党组织 */
  deptName?: string;
  /** 主键 */
  id?: number;
  /** 是否离任 */
  isLeave?: string;
  /** 离任时间 */
  leaveDate?: string;
  /** 任职时间 */
  officeDate?: string;
  /** 任职方式 */
  officeWay?: string;
  /** 党内职务 */
  partyPosts?: string;
  /** 班子职务排序 */
  sortNo?: number;
  /** 换届组织id */
  transitionId?: number;
  /** 党员id */
  userId?: number;
  /** 姓名 */
  userName?: string;
}

/**
 * 请求参数
 */
export type 领导班子业务对象Params = { [key: string]: any };

export interface 领导班子业务对象 {
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 组织id */
  deptId: number;
  /** 主键 */
  id: number;
  /** 是否离任 */
  isLeave: string;
  /** 离任时间 */
  leaveDate?: string;
  /** 任职时间 */
  officeDate: string;
  /** 任职方式 */
  officeWay: string;
  /** 请求参数 */
  params?: 领导班子业务对象Params;
  /** 党内职务 */
  partyPosts: string;
  /** 搜索值 */
  searchValue?: string;
  /** 班子职务排序 */
  sortNo: number;
  /** 换届组织id */
  transitionId: number;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
  /** 党员id */
  userId: number;
  /** 党员姓名 */
  userName?: string;
}

export interface 预备党员记录视图对象 {
  /** 入党志愿书填写时间 */
  applicationFormTime?: string;
  /** 基层党委审批时间 */
  basicOrgApprovalTime?: string;
  /** 第一入党介绍人意见时间 */
  firstIntroducerOpinionTime?: string;
  /** 第一谈话人id */
  firstTalker?: number;
  /** 第一谈话人 */
  firstTalkerName?: string;
  /** 大会通过结果 */
  generalAssemblyResult?: string;
  /** 大会通过日期 */
  generalAssemblyTime?: string;
  /** 总支部（审批）时间 */
  generalBranchApprovalTime?: string;
  /** id */
  id?: number;
  /** 培养、教育和考察情况;上存“培养、教育和考察情况”，每半年一次，至少两次（必填），上不封顶，上存（《预备党员考察表》第1-5页）、思想汇报等PDF或JPG等图片. */
  investigationDesc?: string;
  /** 组织谈话人id */
  orgInterviewers?: number;
  /** 组织谈话人 */
  orgInterviewersName?: string;
  /** 组织谈话日期 */
  orgTalkDate?: string;
  /** 预审结果 */
  preTrialResult?: string;
  /** 预审时间 */
  preTrialTime?: string;
  /** 接收预备党员的情况 */
  receiveCondition?: string;
  /** 党支部通过接收申请人为预备党员的决议时间 */
  receivingTime?: string;
  /** 相关资料 */
  relDoc?: string;
  /** 第二入党介绍人意见时间 */
  secondIntroducerOpinionTime?: string;
  /** 第二谈话人id */
  secondTalker?: number;
  /** 第二谈话人 */
  secondTalkerName?: string;
  /** 上级党组织指派专人进行谈话时间 */
  speciallyTalkerDate?: string;
  /** 用户id */
  userId?: number;
}

export interface 预备党员记录业务对象 {
  /** 入党志愿书填写时间 */
  applicationFormTime: string;
  /** 基层党委审批时间 */
  basicOrgApprovalTime: string;
  /** 第一入党介绍人意见时间 */
  firstIntroducerOpinionTime: string;
  /** 第一谈话人 */
  firstTalker: number;
  /** 大会通过结果 */
  generalAssemblyResult: string;
  /** 大会通过日期 */
  generalAssemblyTime: string;
  /** 总支部（审批）时间 */
  generalBranchApprovalTime?: string;
  /** 培养、教育和考察情况;上存“培养、教育和考察情况”，每半年一次，至少两次（必填），上不封顶，上存（《预备党员考察表》第1-5页）、思想汇报等PDF或JPG等图片. */
  investigationDesc: string;
  /** 组织谈话人 */
  orgInterviewers: number;
  /** 组织谈话日期 */
  orgTalkDate: string;
  /** 预审结果 */
  preTrialResult: string;
  /** 预审时间 */
  preTrialTime: string;
  /** 接收预备党员的情况 */
  receiveCondition: string;
  /** 党支部通过接收申请人为预备党员的决议时间 */
  receivingTime?: string;
  /** 相关资料 */
  relDoc: string;
  /** 第二入党介绍人意见时间 */
  secondIntroducerOpinionTime: string;
  /** 第二谈话人 */
  secondTalker: number;
  /** 上级党组织指派专人进行谈话时间 */
  speciallyTalkerDate: string;
  /** 用户id */
  userId: number;
}

export interface 重置密码请求 {
  password: string;
  userId: number;
}

export interface 转流动党员请求 {
  notLocal: string;
  userId: number;
}

/**
 * 请求参数
 */
export type 转入党员请求Params = { [key: string]: any };

export interface 转入党员请求 {
  /** 头像地址 */
  avatar?: string;
  /** 生日 */
  birthday: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 组织id */
  deptId: number;
  /** 学历;字典-学历 */
  education: string;
  /** 邮箱 */
  email: string;
  /** 入党时间 */
  entryPartyDate: string;
  /** 家庭住址 */
  homeAddress?: string;
  /** 身份证号 */
  idCardNo: string;
  /** 民族;字典-民族 */
  nation: string;
  /** 流入地党组织或流出地党组织 */
  notLocal?: string;
  opReason?: string;
  /** 请求参数 */
  params?: 转入党员请求Params;
  /** 党员类别 */
  partyMemberType?: string;
  /** 手机号码 */
  phone: string;
  /** 党内职务 */
  position?: string;
  /** 移除原因 */
  removeReason?: string;
  /** 移除时间 */
  removeTime?: string;
  /** 角色id */
  roleIds: number[];
  /** 搜索值 */
  searchValue?: string;
  /** 用户性别;（0男 1女 2未知） */
  sex: string;
  /** 党员状态;（0正常 1停用） */
  status: string;
  transferDate: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
  /** 党员id */
  userId: number;
  /** 党员姓名 */
  userName: string;
}

export interface 路由显示信息 {
  /** 设置该路由的图标，对应路径src/assets/icons/svg */
  icon?: string;
  /** 内链地址（http(s)://开头） */
  link?: string;
  /** 设置为true，则不会被 <keep-alive>缓存 */
  noCache?: boolean;
  /** 设置该路由在侧边栏和面包屑中展示的名字 */
  title?: string;
}

export interface 路由配置信息 {
  /** 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面 */
  alwaysShow?: boolean;
  /** 子路由 */
  children?: 路由配置信息[];
  /** 组件地址 */
  component?: string;
  /** 是否隐藏路由，当设置 true 的时候该路由不会再侧边栏出现 */
  hidden?: boolean;
  /** 其他元素 */
  meta?: 路由显示信息;
  /** 路由名字 */
  name?: string;
  /** 路由地址 */
  path?: string;
  /** 路由参数：如 {"id": 1, "name": "ry"} */
  query?: string;
  /** 重定向地址，当设置 noRedirect 的时候该路由在面包屑导航中不可被点击 */
  redirect?: string;
}

export interface 请求响应对象领导班子视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 领导班子视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象预备党员记录视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 预备党员记录视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象评议结果视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 评议结果视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象菜单权限业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 菜单权限业务对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象组织结构视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 组织结构视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象组织生活视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 组织生活视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象组织生活党员到场记录对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 组织生活党员到场记录对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象组织换届视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 组织换届视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象积极分子记录视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 积极分子记录视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象用户积分视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 用户积分视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象活动视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 活动视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象民主评议视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 民主评议视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象正式党员档案记录视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 正式党员档案记录视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象新闻浏览量视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 新闻浏览量视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象岗位信息业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 岗位信息业务对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象对象存储配置视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 对象存储配置视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象字典类型业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 字典类型业务对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象字典数据业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 字典数据业务对象Res;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象发展对象记录视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 发展对象记录视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象参数配置业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 参数配置业务对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象入党申请记录视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 入党申请记录视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象党委简介视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 党委简介视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象党员年龄分布视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 党员年龄分布视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象党员学历分布视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 党员学历分布视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象党员党龄分布视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 党员党龄分布视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象党员信息视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 党员信息视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象信息栏目视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 信息栏目视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象信息列视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 信息列视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象人员转移记录视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 人员转移记录视图对象;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象String {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: string;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象Long {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: number;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象Int {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: number;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象Boolean {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: boolean;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象Void {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象SysRole {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: SysRoleRes;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象SysNotice {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: SysNotice;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象SysDeptVo {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: SysDeptVo;
  /** 消息内容 */
  msg?: string;
}

/**
 * 数据对象
 */
export type 请求响应对象MapStringStringData = { [key: string]: string };

export interface 请求响应对象MapStringString {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 请求响应对象MapStringStringData;
  /** 消息内容 */
  msg?: string;
}

/**
 * 数据对象
 */
export type 请求响应对象MapStringObjectData = { [key: string]: any };

export interface 请求响应对象MapStringObject {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 请求响应对象MapStringObjectData;
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象List路由配置信息 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 路由配置信息[];
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象List菜单权限业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 菜单权限业务对象[];
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象List组织对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 组织对象[];
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象List岗位信息业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 岗位信息业务对象[];
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象List字典类型业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 字典类型业务对象[];
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象List字典数据业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 字典数据业务对象Res[];
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象List党员信息视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 党员信息视图对象[];
  /** 消息内容 */
  msg?: string;
}

export type 请求响应对象ListTreeLongDataItem = {
  [key: string]: { [key: string]: any };
};

export interface 请求响应对象ListTreeLong {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: 请求响应对象ListTreeLongDataItem[];
  /** 消息内容 */
  msg?: string;
}

export interface 请求响应对象ListSysRole {
  /** 消息状态码 */
  code?: number;
  /** 数据对象 */
  data?: SysRoleRes[];
  /** 消息内容 */
  msg?: string;
}

export interface 评议结果视图对象 {
  /** 支部认定等级(0优秀1合格2基本合格3不合格4弃权) */
  branchCertificationLevel?: string;
  /** 评议奖惩情况(0无奖惩1有奖惩) */
  commentRewards?: string;
  /** 组织名称 */
  deptName: string;
  /** 主键 */
  id?: number;
  /** 会议id */
  meetingId?: number;
  /** 互评基本合格 */
  mutualEvaluationBasicQualified?: number;
  /** 互评优秀 */
  mutualEvaluationExcellent?: number;
  /** 互评不合格 */
  mutualEvaluationFailed?: number;
  /** 互评合格 */
  mutualEvaluationQualified?: number;
  /** 参会党员id */
  partyId?: number;
  /** 参会党员名称 */
  partyName: string;
  /** 奖惩说明 */
  rewardsDescription?: string;
}

/**
 * 请求参数
 */
export type 评议结果业务对象Params = { [key: string]: any };

export interface 评议结果业务对象 {
  /** 支部认定等级(0优秀1合格2基本合格3不合格4弃权) */
  branchCertificationLevel: string;
  /** 评议奖惩情况(0无奖惩1有奖惩) */
  commentRewards: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 组织id */
  deptId: number;
  /** 主键 */
  id: number;
  /** id集合 */
  idList: number[];
  /** 会议id */
  meetingId: number;
  /** 互评基本合格 */
  mutualEvaluationBasicQualified: number;
  /** 互评优秀 */
  mutualEvaluationExcellent: number;
  /** 互评不合格 */
  mutualEvaluationFailed: number;
  /** 互评合格 */
  mutualEvaluationQualified: number;
  /** 请求参数 */
  params?: 评议结果业务对象Params;
  /** 参会党员id */
  partyId: number;
  /** 奖惩说明 */
  rewardsDescription: string;
  /** 搜索值 */
  searchValue?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type 菜单权限业务对象Params = { [key: string]: any };

export type 菜单权限业务对象ChildrenItem = { [key: string]: any };

export interface 菜单权限业务对象 {
  /** 子组织 */
  children?: 菜单权限业务对象ChildrenItem[];
  /** 组件路径 */
  component?: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 菜单图标 */
  icon?: string;
  /** 是否缓存（0缓存 1不缓存） */
  isCache?: string;
  /** 是否为外链（0是 1否） */
  isFrame?: string;
  /** 菜单ID */
  menuId?: number;
  /** 菜单名称 */
  menuName?: string;
  /** 类型（M目录 C菜单 F按钮） */
  menuType?: string;
  /** 显示顺序 */
  orderNum?: number;
  /** 请求参数 */
  params?: 菜单权限业务对象Params;
  /** 父菜单ID */
  parentId?: number;
  /** 父菜单名称 */
  parentName?: string;
  /** 路由地址 */
  path?: string;
  /** 权限字符串 */
  perms?: string;
  /** 路由参数 */
  query?: string;
  /** 备注 */
  remark?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 菜单状态（0显示 1隐藏） */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
  /** 显示状态（0显示 1隐藏） */
  visible?: string;
}

export interface 组织结构视图对象 {
  /** 党委 */
  data1?: number;
  /** 党支部 */
  data2?: number;
  /** 正式党员 */
  data3?: number;
  /** 预备党员 */
  data4?: number;
}

/**
 * 请求参数
 */
export type 组织生活视图对象0Params = { [key: string]: any };

export interface 组织生活视图对象0 {
  /** 审核状态(0未审核 1已审核 2被驳回) */
  audit?: string;
  /** 内容类型(1、教育培训（远教）2、重大事项讨论3、民主评议4、党日活动) */
  contentType?: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 创建结束时间 */
  createdEndTime?: string;
  /** 创建开始时间 */
  createdStartTime?: string;
  /** 删除标志;0 正常 2删除 */
  delFlag?: string;
  /** 延时时间(分钟) */
  delayTime?: string;
  /** 结束时间 */
  endTime?: string;
  /** 主持人 */
  host?: string;
  /** 主键id */
  id?: number;
  /** 会议地址 */
  meetingAddress?: string;
  /** 会议内容 */
  meetingContent?: string;
  /** 会议资料地址 */
  meetingInfo?: string;
  /** 会议名称 */
  meetingName?: string;
  /** 会议图片地址 */
  meetingPictures?: string;
  /** 会议状态(未开始 进行中 已结束) */
  meetingStatus?: string;
  /** 通知内容 */
  noticeContent?: string;
  /** 通知标题 */
  noticeTitle?: string;
  /** 请求参数 */
  params?: 组织生活视图对象0Params;
  /** 参会对象(0、组织1、个人) */
  participants?: string;
  /** 参会党员 */
  partyMembers?: string;
  /** 参会组织 */
  partyOrg?: string;
  /** 主讲人 */
  presenter?: string;
  /** 发布组织 */
  publishOrg?: number;
  /** 范围类型(1、党（支）委会2、党员大会3、党小组会) */
  rangeType?: string;
  /** 记录人 */
  recorder?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 签到方式 */
  signIn?: string;
  /** 开始时间 */
  startingTime?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
  /** 当前登录人 */
  userId?: number;
}

/**
 * 请求参数
 */
export type 组织生活视图对象Params = { [key: string]: any };

export interface 组织生活视图对象 {
  /** 审核状态(0未审核 1已审核 2被驳回) */
  audit?: string;
  /** 内容类型(1、教育培训（远教）2、重大事项讨论3、民主评议4、党日活动) */
  contentType?: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 删除标志 */
  delFlag?: string;
  /** 延时时间(分钟) */
  delayTime?: string;
  /** 结束时间 */
  endTime?: string;
  /** 主持人 */
  host?: string;
  /** 会议id */
  id?: number;
  /** 会议地址 */
  meetingAddress?: string;
  /** 会议内容 */
  meetingContent?: string;
  /** 会议资料地址 */
  meetingInfo?: string;
  /** 会议名称 */
  meetingName?: string;
  /** 会议图片地址 */
  meetingPictures?: string;
  /** 会议状态(未开始 进行中 已结束) */
  meetingStatus?: string;
  /** 通知内容 */
  noticeContent?: string;
  /** 通知标题 */
  noticeTitle?: string;
  /** 请求参数 */
  params?: 组织生活视图对象Params;
  /** 参会对象(0、组织1、个人) */
  participants?: string;
  /** 参会党员 */
  partyMembers?: string;
  /** app参会党员 */
  partyMembersAppVoList?: App参会党员对象[];
  /** 参会党员名称 */
  partyMembersName?: string;
  /** 参会组织 */
  partyOrg?: string;
  /** 参会组织名称 */
  partyOrgName?: string;
  /** 参会状态(1未参会 2参会 3缺席(未请假) 4请假 5签到未到场) */
  partyStatus?: string;
  /** 主讲人 */
  presenter?: string;
  /** 发布组织 */
  publishOrg?: number;
  /** 发布组织名称 */
  publishOrgName?: string;
  /** 范围类型(1、党（支）委会2、党员大会3、党小组会) */
  rangeType?: string;
  /** 记录人 */
  recorder?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 签到方式 */
  signIn?: string;
  /** 签到人数 */
  signInNum?: number;
  /** 签到状态(1已签到 2未签到) */
  signStatus?: string;
  /** 开始时间 */
  startingTime?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type 组织生活党员到场记录对象0Params = { [key: string]: any };

export interface 组织生活党员到场记录对象0 {
  /** 缺席原因 */
  absentReason?: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 删除标志;0 正常 2删除 */
  delFlag?: string;
  /** id */
  id?: number;
  /** idList */
  idList?: number[];
  /** 会议id */
  meetingId?: number;
  /** 请求参数 */
  params?: 组织生活党员到场记录对象0Params;
  /** 党员id */
  partyId?: number;
  /** 参会党员名称 */
  partyName?: string;
  /** 参会状态(1未参会 2参会 3缺席(未请假) 4请假 5签到未到场) */
  partyStatus?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 签到状态(1已签到 2未签到) */
  signStatus?: string;
  /** 签到时间 */
  signTime?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type 组织生活党员到场记录对象Params = { [key: string]: any };

export interface 组织生活党员到场记录对象 {
  /** 缺席原因 */
  absentReason?: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 删除标志 */
  delFlag?: string;
  /** 到场记录id */
  id?: number;
  /** 会议id */
  meetingId?: number;
  /** 请求参数 */
  params?: 组织生活党员到场记录对象Params;
  /** 党员id */
  partyId?: number;
  /** 党员名称 */
  partyName?: string;
  /** 参会状态(1未参会 2参会 3缺席(未请假) 4请假 5签到未到场) */
  partyStatus?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 签到状态(1已签到 2未签到) */
  signStatus?: string;
  /** 签到时间 */
  signTime?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

export interface 组织换届视图对象 {
  /** 实到人数 */
  actualNum?: number;
  /** 延期月数 */
  delayMonth?: number;
  /** 换届组织id */
  deptId?: number;
  /** 换届组织 */
  deptName?: string;
  /** 主键 */
  id?: number;
  /** 是否现届 */
  isCurrent?: string;
  /** 是否有办理延期 */
  isDelay?: string;
  /** 候选人名单请示内容 */
  listApplyContent?: string;
  /** 候选人名单请示时间 */
  listApplyDate?: string;
  /** 候选人名单批复内容 */
  listApprovalContent?: string;
  /** 候选人名单批复时间 */
  listApprovalDate?: string;
  /** 会议类型 */
  meetingType?: string;
  /** 应换届时间 */
  nextTransitionDate?: string;
  /** 届数 */
  periodNum?: string;
  /** 备注 */
  remark?: string;
  /** 换届结果申请内容 */
  resultApplyContent?: string;
  /** 换届结果申请时间 */
  resultApplyDate?: string;
  /** 换届结果批复内容 */
  resultApprovalContent?: string;
  /** 换届结果批复时间 */
  resultApprovalDate?: string;
  /** 应到人数 */
  shouldNum?: number;
  /** 任期年限 */
  termYear?: number;
  /** 换届请示内容 */
  transitionApplyContent?: string;
  /** 换届请示时间 */
  transitionApplyDate?: string;
  /** 换届请示书面批复 */
  transitionApprovalContent?: string;
  /** 换届请示批复时间 */
  transitionApprovalDate?: string;
  /** 换届时间 */
  transitionDate?: string;
}

/**
 * 请求参数
 */
export type 组织换届业务对象Params = { [key: string]: any };

export interface 组织换届业务对象 {
  /** 实到人数 */
  actualNum: number;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 延期月数 */
  delayMonth: number;
  /** 换届组织id */
  deptId: number;
  /** 主键 */
  id: number;
  /** 是否现届 */
  isCurrent: string;
  /** 是否有办理延期 */
  isDelay: string;
  /** 候选人名单请示内容 */
  listApplyContent?: string;
  /** 候选人名单请示时间 */
  listApplyDate: string;
  /** 候选人名单批复内容 */
  listApprovalContent?: string;
  /** 候选人名单批复时间 */
  listApprovalDate: string;
  /** 会议类型 */
  meetingType: string;
  /** 应换届时间 */
  nextTransitionDate: string;
  /** 请求参数 */
  params?: 组织换届业务对象Params;
  /** 届数 */
  periodNum?: string;
  /** 备注 */
  remark?: string;
  /** 换届结果申请内容 */
  resultApplyContent?: string;
  /** 换届结果申请时间 */
  resultApplyDate: string;
  /** 换届结果批复内容 */
  resultApprovalContent?: string;
  /** 换届结果批复时间 */
  resultApprovalDate: string;
  /** 搜索值 */
  searchValue?: string;
  /** 应到人数 */
  shouldNum: number;
  /** 任期年限 */
  termYear: number;
  /** 换届请示内容 */
  transitionApplyContent?: string;
  /** 换届请示时间 */
  transitionApplyDate: string;
  /** 换届请示书面批复 */
  transitionApprovalContent: string;
  /** 换届请示批复时间 */
  transitionApprovalDate: string;
  /** 换届时间 */
  transitionDate: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type 组织对象Params = { [key: string]: any };

export type 组织对象ChildrenItem = { [key: string]: any };

export interface 组织对象 {
  /** 详细地址 */
  address?: string;
  /** 祖级列表 */
  ancestors?: string;
  /** 子组织 */
  children?: 组织对象ChildrenItem[];
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 删除标志（0代表存在 2代表删除） */
  delFlag?: string;
  /** 单位情况 */
  deptDesc?: string;
  /** 组织id */
  deptId?: number;
  /** 组织隶属 */
  deptLevel?: string;
  /** 组织名称 */
  deptName?: string;
  /** 组织类别 */
  deptType?: string;
  /** 邮箱 */
  email?: string;
  /** 组织图片 */
  imageUrl?: string;
  /** 是否异地组织 */
  isOut?: string;
  /** 党组织书记 */
  leader?: string;
  /** 显示排序 */
  orderNum?: number;
  /** 请求参数 */
  params?: 组织对象Params;
  /** 父组织id */
  parentId?: number;
  /** 父菜单名称 */
  parentName?: string;
  /** 联系电话 */
  phone?: string;
  /** 成立时间 */
  regDate?: string;
  /** 相关资料 */
  relDoc?: string;
  /** 组织简介 */
  remark?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 组织简称 */
  shortName?: string;
  /** :0正常,1停用 */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type 系统访问记录业务对象Params = { [key: string]: any };

export interface 系统访问记录业务对象 {
  /** 浏览器类型 */
  browser?: string;
  /** 访问ID */
  infoId?: number;
  /** 登录IP地址 */
  ipaddr?: string;
  /** 登录地点 */
  loginLocation?: string;
  /** 访问时间 */
  loginTime?: string;
  /** 提示消息 */
  msg?: string;
  /** 操作系统 */
  os?: string;
  /** 请求参数 */
  params?: 系统访问记录业务对象Params;
  /** 登录状态 0成功 1失败 */
  status?: string;
  /** 用户账号 */
  userName?: string;
}

export interface 积极分子记录视图对象 {
  /** 党支部意见时间;(该时间作为积极分子身份确定的时间) */
  branchOpinionTime?: string;
  /** 确定为入党积极分子的情况说明;图片 */
  conDesc?: string;
  /** 指定培养人 */
  designatedTrainer?: string;
  /** 党委（党工委）备案时间 */
  filingTime?: string;
  /** 党总支部审批时间 */
  generalBranchApprovalTime?: string;
  /** 党总支部意见时间 */
  generalBranchOpinionTime?: string;
  /** 党小组审批时间 */
  groupApprovalTime?: string;
  /** 党小组意见时间 */
  groupOpinionTime?: string;
  /** id */
  id?: number;
  /** 思想汇报;图片 */
  ideologicalReport?: string;
  /** 培养、教育和考察情况;每半年一次，至少两次（必填），上不封顶. */
  investigation?: string;
  /** 谈话记录 */
  orgConLog?: string;
  /** 组织谈话时间 */
  orgConTime?: string;
  /** 一榜公示时间 */
  publicityDate?: string;
  /** 一榜公示公示情况登记表 */
  publicityForm?: string;
  /** 党员推荐或群团组织推优时间 */
  recommendTime?: string;
  /** 个人基本情况;图片上传 */
  userBaseInfo?: string;
  /** 用户id */
  userId?: number;
}

export interface 积极分子记录业务对象 {
  /** 党支部意见时间;(该时间作为积极分子身份确定的时间) */
  branchOpinionTime: string;
  /** 确定为入党积极分子的情况说明;图片 */
  conDesc: string;
  /** 指定培养人 */
  designatedTrainer: string;
  /** 党委（党工委）备案时间 */
  filingTime: string;
  /** 党总支部审批时间 */
  generalBranchApprovalTime: string;
  /** 党总支部意见时间 */
  generalBranchOpinionTime?: string;
  /** 党小组审批时间 */
  groupApprovalTime: string;
  /** 党小组意见时间 */
  groupOpinionTime?: string;
  /** id */
  id: number;
  /** 思想汇报;图片 */
  ideologicalReport: string;
  /** 培养、教育和考察情况;每半年一次，至少两次（必填），上不封顶. */
  investigation: string;
  /** 谈话记录 */
  orgConLog?: string;
  /** 组织谈话时间 */
  orgConTime: string;
  /** 一榜公示时间 */
  publicityDate: string;
  /** 一榜公示公示情况登记表 */
  publicityForm: string;
  /** 党员推荐或群团组织推优时间 */
  recommendTime: string;
  /** 个人基本情况;图片上传 */
  userBaseInfo: string;
  /** 用户id */
  userId: number;
}

export interface 用户积分视图对象 {
  /** 组织 */
  deptName?: string;
  /** 履行职责分 */
  dutyScore?: number;
  /** 主键 */
  id?: number;
  /** 指数总分 */
  indexScore?: number;
  /** 党内生活分 */
  lifeScore?: number;
  /** 道德品质分 */
  moralScore?: number;
  /** 手机号 */
  phone?: string;
  /** 性别 */
  sex?: string;
  /** 奉献社会分 */
  socialScore?: number;
  /** 达标分 */
  standardScore?: number;
  /** 用户id */
  userId?: number;
  /** 姓名 */
  userName?: string;
}

/**
 * 请求参数
 */
export type 用户积分业务对象Params = { [key: string]: any };

export interface 用户积分业务对象 {
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 履行职责分 */
  dutyScore: number;
  /** 主键 */
  id?: number;
  /** 指数总分 */
  indexScore: number;
  /** 党内生活分 */
  lifeScore: number;
  /** 道德品质分 */
  moralScore: number;
  /** 请求参数 */
  params?: 用户积分业务对象Params;
  /** 搜索值 */
  searchValue?: string;
  /** 奉献社会分 */
  socialScore: number;
  /** 达标分 */
  standardScore: number;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
  /** 用户 */
  userId: number;
}

export interface 用户登录对象 {
  /** 验证码 */
  code?: string;
  /** 用户密码 */
  password?: string;
  /** 用户名 */
  username?: string;
  /** 唯一标识 */
  uuid?: string;
}

export interface 用户注册对象 {
  /** 验证码 */
  code?: string;
  /** 用户密码 */
  password?: string;
  /** 用户类型 */
  userType?: string;
  /** 用户名 */
  username?: string;
  /** 唯一标识 */
  uuid?: string;
}

export interface 用户和角色关联 {
  /** 角色ID */
  roleId?: number;
  /** 用户ID */
  userId?: number;
}

export interface 消息视图对象 {
  /** 创建时间 */
  createTime?: string;
  /** 内容 */
  msgContent?: string;
  /** 主键 */
  msgId?: number;
  /** 消息状态;(0未读 1已读) */
  msgStatus?: string;
  /** 标题 */
  msgTitle?: string;
  /** 消息类型 */
  msgType?: string;
  /** 通知id */
  noticeId?: string;
  /** 推送次数 */
  pushCount?: number;
  readTime?: string;
  relateId?: number;
  /** 用户id */
  userId?: number;
  /** 党员姓名 */
  userName?: string;
}

export interface 活动视图对象 {
  /** 活动名称 */
  activityName: string;
  /** 活动描述 */
  description?: string;
  /** 活动结束时间 */
  endTime: string;
  /** 表格链接 */
  formLink?: string;
  /** 主键 */
  id: number;
  /** 活动开始时间 */
  startTime: string;
  /** 活动缩略图 */
  thumbnail?: string;
  /** 活动报名开始时间 */
  applyStartTime: string;
  /** 活动报名结束时间 */
  applyEndTime: string;
}

/**
 * 请求参数
 */
export type 活动业务对象Params = { [key: string]: any };

export interface 活动业务对象 {
  /** 活动名称 */
  activityName: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 活动描述 */
  description?: string;
  /** 活动结束时间 */
  endTime: string;
  /** 表格链接 */
  formLink?: string;
  /** 主键 */
  id: number;
  /** 请求参数 */
  params?: 活动业务对象Params;
  /** 搜索值 */
  searchValue?: string;
  /** 活动开始时间 */
  startTime: string;
  /** 活动缩略图 */
  thumbnail?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

export interface 民主评议视图对象 {
  /** 审核状态(0未审核 1已审核) */
  audit?: string;
  /** 审核人 */
  auditBy?: string;
  /** 审核时间 */
  auditTime?: string;
  /** 评议地址 */
  commentAddress?: string;
  /** 评议内容 */
  commentContent?: string;
  /** 评议党员id */
  commentParty?: string;
  /** 评议主题 */
  commentTopic?: string;
  /** 结束时间 */
  endTime?: string;
  /** 优秀人数上限 */
  excellentNumberLimit?: number;
  /** 主键 */
  id?: number;
  /** 会议资料地址 */
  meetingInfo?: string;
  /** app状态（0表示可以互评 1表示互评完成） */
  mutualEvaluationStatus?: string;
  /** 发布组织id */
  publishOrg?: number;
  /** 发布组织名称 */
  publishOrgName?: string;
  /** 开始时间 */
  startingTime?: string;
  /** 状态 */
  status?: string;
}

/**
 * 请求参数
 */
export type 民主评议业务对象Params = { [key: string]: any };

export interface 民主评议业务对象 {
  /** 审核状态(0未审核 1已审核2被驳回) */
  audit: string;
  /** 审核人 */
  auditBy: string;
  /** 审核时间 */
  auditTime: string;
  /** 评议地址 */
  commentAddress: string;
  /** 评议内容 */
  commentContent: string;
  /** 评议党员id */
  commentParty: string;
  /** 评议主题 */
  commentTopic: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 结束时间 */
  endTime: string;
  /** 优秀人数上限 */
  excellentNumberLimit: number;
  /** 主键 */
  id: number;
  /** 会议资料地址 */
  meetingInfo: string;
  /** 请求参数 */
  params?: 民主评议业务对象Params;
  /** 发布组织id */
  publishOrg: number;
  /** 查询条件结束时间 */
  searchEndTime?: string;
  /** 查询条件开始时间 */
  searchStartTime?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 开始时间 */
  startingTime: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

export interface 正式党员档案记录视图对象 {
  /** 申请转正时间 */
  applyConfirmTime?: string;
  /** 审批组织id */
  approvalOrganization?: number;
  /** 审批组织 */
  approvalOrganizationName?: string;
  /** 基层党委审批时间 */
  basicApprovalTime?: string;
  /** 党员转正支部大会意见 */
  branchDiscussionDate?: string;
  /** 委员会审查情况 */
  committeeReview?: string;
  /** 大会讨论结果 */
  conferenceResult?: string;
  /** 转正申请书;图片上传 */
  confirmApplication?: string;
  /** 转正审批日期 */
  confirmApprovalDate?: string;
  /** 转正审批意见 */
  confirmApprovalOpinion?: string;
  /** 转正时间 */
  confirmTime?: string;
  /** 转为正式党员的情况 */
  conversionDesc?: string;
  /** 支部大会通过延长预备期的党员能否转为正式党员的决议时间 */
  extendResolutionTime?: string;
  /** 是否延长预备期 */
  extendThePeriod?: string;
  /** 延长预备期的情况 */
  extendThePeriodCondition?: string;
  /** 党内外意见 */
  generalPublicOpinions?: string;
  /** 转正党小组讨论日期 */
  groupDiscussionDate?: string;
  /** 党员转正党小组意见 */
  groupOpinion?: string;
  /** id */
  id?: number;
  /** 党支部书记id */
  partyBranchSecretary?: number;
  /** 党支部书记 */
  partyBranchSecretaryName?: string;
  /** 相关资料;（相关资料应包含：1.预备党员考核鉴定表 2.转正申请 3.群众座谈会记录 4.公示情况登记表 5.党员基本信息表 6.基本信息采集表） */
  relevantInformation?: string;
  /** 支部大会通过预备党员能否转为正式党员的决议时间 */
  resolutionTime?: string;
  /** 总支部审查（审批）时间 */
  reviewTime?: string;
  /** 用户id */
  userId?: number;
}

export interface 正式党员档案记录业务对象 {
  /** 申请转正时间 */
  applyConfirmTime: string;
  /** 审批组织 */
  approvalOrganization: number;
  /** 基层党委审批时间 */
  basicApprovalTime: string;
  /** 党员转正支部大会意见 */
  branchDiscussionDate: string;
  /** 委员会审查情况 */
  committeeReview: string;
  /** 大会讨论结果 */
  conferenceResult: string;
  /** 转正申请书;图片上传 */
  confirmApplication: string;
  /** 转正审批日期 */
  confirmApprovalDate: string;
  /** 转正审批意见 */
  confirmApprovalOpinion: string;
  /** 转正时间 */
  confirmTime: string;
  /** 转为正式党员的情况 */
  conversionDesc: string;
  /** 支部大会通过延长预备期的党员能否转为正式党员的决议时间 */
  extendResolutionTime?: string;
  /** 是否延长预备期 */
  extendThePeriod: string;
  /** 延长预备期的情况 */
  extendThePeriodCondition?: string;
  /** 党内外意见 */
  generalPublicOpinions: string;
  /** 转正党小组讨论日期 */
  groupDiscussionDate: string;
  /** 党员转正党小组意见 */
  groupOpinion: string;
  /** 党支部书记 */
  partyBranchSecretary: number;
  /** 相关资料;（相关资料应包含：1.预备党员考核鉴定表 2.转正申请 3.群众座谈会记录 4.公示情况登记表 5.党员基本信息表 6.基本信息采集表） */
  relevantInformation: string;
  /** 支部大会通过预备党员能否转为正式党员的决议时间 */
  resolutionTime: string;
  /** 总支部审查（审批）时间 */
  reviewTime: string;
  /** 用户id */
  userId: number;
}

export interface 新闻浏览量视图对象 {
  /** 党建要闻 */
  data1?: number;
  /** 政策解读 */
  data2?: number;
  /** 领导讲话 */
  data3?: number;
  /** 党章党规 */
  data4?: number;
}

/**
 * 请求参数
 */
export type 操作日志记录业务对象Params = { [key: string]: any };

export interface 操作日志记录业务对象 {
  /** 业务类型（0其它 1新增 2修改 3删除） */
  businessType?: number;
  /** 业务类型数组 */
  businessTypes?: number[];
  /** 组织名称 */
  deptName?: string;
  /** 错误消息 */
  errorMsg?: string;
  /** 返回参数 */
  jsonResult?: string;
  /** 请求方法 */
  method?: string;
  /** 日志主键 */
  operId?: number;
  /** 操作地址 */
  operIp?: string;
  /** 操作地点 */
  operLocation?: string;
  /** 操作人员 */
  operName?: string;
  /** 请求参数 */
  operParam?: string;
  /** 操作时间 */
  operTime?: string;
  /** 请求url */
  operUrl?: string;
  /** 操作类别（0其它 1后台用户 2手机端用户） */
  operatorType?: number;
  /** 请求参数 */
  params?: 操作日志记录业务对象Params;
  /** 请求方式 */
  requestMethod?: string;
  /** 操作状态（0正常 1异常） */
  status?: number;
  /** 操作模块 */
  title?: string;
}

export interface 推送本地库业务对象 {
  ids: number[];
  sectionId: number;
}

export interface 恢复党籍请求 {
  fromDeptId: number;
  opReason: string;
  outOrInType: string;
  transferDate: string;
  userId: number;
}

export interface 当前在线会话业务对象 {
  /** 浏览器类型 */
  browser?: string;
  /** 组织名称 */
  deptName?: string;
  /** 登录IP地址 */
  ipaddr?: string;
  /** 登录地址 */
  loginLocation?: string;
  /** 登录时间 */
  loginTime?: number;
  /** 操作系统 */
  os?: string;
  /** 会话编号 */
  tokenId?: string;
  /** 用户名称 */
  userName?: string;
}

/**
 * 请求参数
 */
export type 岗位信息业务对象Params = { [key: string]: any };

export interface 岗位信息业务对象 {
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 用户是否存在此岗位标识 默认不存在 */
  flag?: boolean;
  /** 请求参数 */
  params?: 岗位信息业务对象Params;
  /** 岗位编码 */
  postCode?: string;
  /** 岗位序号 */
  postId?: number;
  /** 岗位名称 */
  postName?: string;
  /** 岗位排序 */
  postSort?: string;
  /** 备注 */
  remark?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 状态（0正常 1停用） */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

export interface 对象存储配置视图对象 {
  /** accessKey */
  accessKey?: string;
  /** 桶名称 */
  bucketName?: string;
  /** 配置key */
  configKey?: string;
  /** 访问站点 */
  endpoint?: string;
  /** 扩展字段 */
  ext1?: string;
  /** 是否https（Y=是,N=否） */
  isHttps?: string;
  /** 主建 */
  ossConfigId?: number;
  /** 前缀 */
  prefix?: string;
  /** 域 */
  region?: string;
  /** 备注 */
  remark?: string;
  /** secretKey */
  secretKey?: string;
  /** 状态（0=正常,1=停用） */
  status?: string;
}

/**
 * 请求参数
 */
export type 对象存储配置业务对象Params = { [key: string]: any };

export interface 对象存储配置业务对象 {
  /** accessKey */
  accessKey: string;
  /** bucketName */
  bucketName: string;
  /** configKey */
  configKey: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** endpoint */
  endpoint: string;
  /** 扩展字段 */
  ext1?: string;
  /** 是否https（Y=是,N=否） */
  isHttps?: string;
  /** 主建 */
  ossConfigId: number;
  /** 请求参数 */
  params?: 对象存储配置业务对象Params;
  /** 前缀 */
  prefix?: string;
  /** region */
  region?: string;
  /** 搜索值 */
  searchValue?: string;
  /** secretKey */
  secretKey: string;
  /** 状态（0=正常,1=停用） */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type 字典类型业务对象Params = { [key: string]: any };

export interface 字典类型业务对象 {
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 字典主键 */
  dictId?: number;
  /** 字典名称 */
  dictName?: string;
  /** 字典类型 */
  dictType?: string;
  /** 请求参数 */
  params?: 字典类型业务对象Params;
  /** 备注 */
  remark?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 状态（0正常 1停用） */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type 字典数据业务对象ResParams = { [key: string]: any };

export interface 字典数据业务对象Res {
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 样式属性（其他样式扩展） */
  cssClass?: string;
  default?: boolean;
  /** 字典编码 */
  dictCode?: number;
  /** 字典标签 */
  dictLabel?: string;
  /** 字典排序 */
  dictSort?: number;
  /** 字典类型 */
  dictType?: string;
  /** 字典键值 */
  dictValue?: string;
  /** 是否默认（Y是 N否） */
  isDefault?: string;
  /** 表格字典样式 */
  listClass?: string;
  /** 请求参数 */
  params?: 字典数据业务对象ResParams;
  /** 备注 */
  remark?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 状态（0正常 1停用） */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type 字典数据业务对象ReqParams = { [key: string]: any };

export interface 字典数据业务对象Req {
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 样式属性（其他样式扩展） */
  cssClass?: string;
  /** 字典编码 */
  dictCode?: number;
  /** 字典标签 */
  dictLabel?: string;
  /** 字典排序 */
  dictSort?: number;
  /** 字典类型 */
  dictType?: string;
  /** 字典键值 */
  dictValue?: string;
  /** 是否默认（Y是 N否） */
  isDefault?: string;
  /** 表格字典样式 */
  listClass?: string;
  /** 请求参数 */
  params?: 字典数据业务对象ReqParams;
  /** 备注 */
  remark?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 状态（0正常 1停用） */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

export interface 发展对象记录视图对象 {
  /** 党支部意见 */
  branchOpinions?: string;
  /** 党支部意见时间 */
  branchOpinionsTime?: string;
  /** 确定发展对象党支部书记id */
  branchSecretary?: number;
  /** 确定发展对象党支部书记 */
  branchSecretaryName?: string;
  /** 集中培训日期 */
  centralizedTrainingDate?: string;
  /** 集中培训结果 */
  centralizedTrainingResult?: string;
  /** 培养联系人意见时间 */
  devContactOpinionsTime?: string;
  /** 第一联系人id */
  firstContact?: number;
  /** 第一联系人 */
  firstContactName?: string;
  /** 党总支部意见时间 */
  generalBranchOpinionsTime?: string;
  /** 党内外群众意见 */
  generalPublicOpinions?: string;
  /** 党小组意见 */
  groupOpinions?: string;
  /** 党小组意见时间 */
  groupOpinionsTime?: string;
  /** id */
  id?: number;
  /** 列为发展对象时间 */
  listedDevTime?: string;
  /** 党委（党工委）备案时间 */
  partyCommFilingTime?: string;
  /** 党委（党工委）预审意见时间 */
  partyCommPreTrialTime?: string;
  /** 发展对象政审结果 */
  politicalScreeningResult?: string;
  /** 发展对象政审时间 */
  politicalScreeningTime?: string;
  /** 党支部预审时间 */
  preTrialTime?: string;
  /** 接收预备党员预审的情况说明;图片 */
  receivingDescription?: string;
  /** 相关资料;图片 */
  relevantInformation?: string;
  /** 党总支部审查时间 */
  reviewTime?: string;
  /** 第二联系人id */
  secondContact?: number;
  /** 第二联系人 */
  secondContactName?: string;
  /** 二榜公示公示情况登记表;图片 */
  secondPubForm?: string;
  /** 二榜公示时间 */
  secondPubTime?: string;
  /** 三榜公示公示情况登记表;图片 */
  thirdPubForm?: string;
  /** 三榜公示时间 */
  thirdPubTime?: string;
  /** 确定为发展对象的情况说明;图片 */
  trainingOverview?: string;
  /** 发展对象培训时间 */
  trainingTime?: string;
  /** 用户id */
  userId?: number;
}

export interface 发展对象记录业务对象 {
  /** 党支部意见 */
  branchOpinions: string;
  /** 党支部意见时间 */
  branchOpinionsTime: string;
  /** 确定发展对象党支部书记 */
  branchSecretary: number;
  /** 集中培训日期 */
  centralizedTrainingDate: string;
  /** 集中培训结果 */
  centralizedTrainingResult: string;
  /** 培养联系人意见时间 */
  devContactOpinionsTime: string;
  /** 第一联系人 */
  firstContact: number;
  /** 党总支部意见时间 */
  generalBranchOpinionsTime?: string;
  /** 党内外群众意见 */
  generalPublicOpinions: string;
  /** 党小组意见 */
  groupOpinions?: string;
  /** 党小组意见时间 */
  groupOpinionsTime?: string;
  /** 列为发展对象时间 */
  listedDevTime: string;
  /** 党委（党工委）备案时间 */
  partyCommFilingTime: string;
  /** 党委（党工委）预审意见时间 */
  partyCommPreTrialTime: string;
  /** 发展对象政审结果 */
  politicalScreeningResult: string;
  /** 发展对象政审时间 */
  politicalScreeningTime: string;
  /** 党支部预审时间 */
  preTrialTime: string;
  /** 接收预备党员预审的情况说明;图片 */
  receivingDescription: string;
  /** 相关资料;图片 */
  relevantInformation: string;
  /** 党总支部审查时间 */
  reviewTime: string;
  /** 第二联系人 */
  secondContact?: number;
  /** 二榜公示公示情况登记表;图片 */
  secondPubForm: string;
  /** 二榜公示时间 */
  secondPubTime: string;
  /** 三榜公示公示情况登记表;图片 */
  thirdPubForm: string;
  /** 三榜公示时间 */
  thirdPubTime: string;
  /** 确定为发展对象的情况说明;图片 */
  trainingOverview: string;
  /** 发展对象培训时间 */
  trainingTime: string;
  /** 用户id */
  userId: number;
}

/**
 * 请求参数
 */
export type 参数配置业务对象Params = { [key: string]: any };

export interface 参数配置业务对象 {
  /** 参数主键 */
  configId?: number;
  /** 参数键名 */
  configKey?: string;
  /** 参数名称 */
  configName?: string;
  /** 系统内置（Y是 N否） */
  configType?: string;
  /** 参数键值 */
  configValue?: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 请求参数 */
  params?: 参数配置业务对象Params;
  /** 备注 */
  remark?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

export interface 分页响应对象领导班子视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 领导班子视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象评议结果视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 评议结果视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象组织生活视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 组织生活视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象组织生活党员到场记录对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 组织生活党员到场记录对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象组织换届视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 组织换届视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象系统访问记录业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 系统访问记录业务对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象用户积分视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 用户积分视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象消息视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 消息视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象活动视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 活动视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象民主评议视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 民主评议视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象操作日志记录业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 操作日志记录业务对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象当前在线会话业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 当前在线会话业务对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象岗位信息业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 岗位信息业务对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象对象存储配置视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 对象存储配置视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象字典类型业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 字典类型业务对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象字典数据业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 字典数据业务对象Res[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象参数配置业务对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 参数配置业务对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象党员发展视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 党员发展视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象党员信息视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 党员信息视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象信息库视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 信息库视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象信息列视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 信息列视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象人员转移记录视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 人员转移记录视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象互评记录视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: 互评记录视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象SysUser {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: SysUserRes[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象SysRole {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: SysRoleRes[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象SysNotice {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: SysNotice[];
  /** 总记录数 */
  total?: number;
}

export interface 分页响应对象Oss对象存储视图对象 {
  /** 消息状态码 */
  code?: number;
  /** 消息内容 */
  msg?: string;
  /** 列表数据 */
  rows?: Oss对象存储视图对象[];
  /** 总记录数 */
  total?: number;
}

export interface 内部调动请求 {
  fromDeptId: number;
  opReason: string;
  toDeptId: number;
  userId: number;
}

export interface 入党申请请求 {
  /** 申请资料 */
  applyData?: string;
  /** 申请日期 */
  applyDate?: string;
  /** 头像地址 */
  avatar?: string;
  /** 生日 */
  birthday: string;
  /** 组织id */
  deptId: number;
  /** 学历;字典-学历 */
  education: string;
  /** 邮箱 */
  email: string;
  /** 家庭住址 */
  homeAddress?: string;
  /** 身份证号 */
  idCardNo: string;
  /** 是否正式 */
  isFormal?: string;
  /** 民族;字典-民族 */
  nation: string;
  /** 手机号码 */
  phone: string;
  /** 用户性别;（0男 1女 2未知） */
  sex: string;
  /** 党员姓名 */
  userName: string;
}

export interface 入党申请记录视图对象 {
  /** 申请资料 */
  applyData?: string;
  /** 申请入党时间 */
  applyDate?: string;
  /** 组织谈话时间 */
  deptConDate?: string;
  /** id */
  id?: number;
  /** 入党申请书;入党申请书图片 */
  partyMemberApplication?: string;
  /** 接收组织 */
  receiveDeptId?: number;
  /** 用户id */
  userId?: number;
}

export interface 入党申请记录业务对象 {
  /** 申请资料 */
  applyData?: string;
  /** 组织谈话时间 */
  deptConDate: string;
  /** 主键 */
  id: number;
  /** 入党申请书;入党申请书图片 */
  partyMemberApplication: string;
  /** 用户id */
  userId: number;
}

export interface 党委简介视图对象 {
  /** 下辖直属党支部数量 */
  data1?: number;
  /** 在册党员数量 */
  data2?: number;
  /** 80后党员数量 */
  data3?: number;
  /** 党员平均年龄 */
  data4?: number;
}

export interface 党员转出请求 {
  fromDeptId: number;
  opReason: string;
  toDeptName: string;
  userId: number;
}

export interface 党员死亡请求 {
  fromDeptId: number;
  transferDate: string;
  userId: number;
}

export interface 党员年龄分布视图对象 {
  /** 30岁以下 */
  data1?: number;
  /** 30-40岁 */
  data2?: number;
  /** 41-50岁 */
  data3?: number;
  /** 51-60岁 */
  data4?: number;
  /** 60岁以上 */
  data5?: number;
}

export interface 党员学历分布视图对象 {
  /** 博士 */
  data1?: number;
  /** 研究生 */
  data2?: number;
  /** 大学 */
  data3?: number;
  /** 大专 */
  data4?: number;
  /** 高中 */
  data5?: number;
  /** 中专 */
  data6?: number;
  /** 初中 */
  data7?: number;
  /** 中技 */
  data8?: number;
}

export interface 党员发展视图对象 {
  /** 接收预备党员时间 */
  acceptProbationaryDate?: string;
  /** 申请时间 */
  applyDate?: string;
  /** 确定积极分子时间 */
  confirmActiveDate?: string;
  /** 确定发展对象时间 */
  confirmDevelopmentDate?: string;
  /** 所属党组织 */
  deptName?: string;
  /** 人员类别 */
  devStage?: string;
  /** 主键 */
  id?: number;
  /** 转正时间 */
  positiveDate?: string;
  /** 性别 */
  sex?: string;
  /** 用户id */
  userId?: number;
  /** 姓名 */
  userName?: string;
}

export interface 党员发展基本信息业务对象 {
  /** 生日 */
  birthday: string;
  /** 组织id */
  deptId: number;
  /** 学历;字典-学历 */
  education: string;
  /** 邮箱 */
  email: string;
  /** 家庭住址 */
  homeAddress: string;
  /** 身份证号 */
  idCardNo: string;
  /** 民族;字典-民族 */
  nation: string;
  /** 手机号码 */
  phone: string;
  /** 用户性别;（0男 1女 2未知） */
  sex: string;
  /** 用户id */
  userId: number;
  /** 党员姓名 */
  userName: string;
}

export interface 党员出党请求 {
  fromDeptId: number;
  opReason: string;
  outOrInType: string;
  remark: string;
  transferDate: string;
  userId: number;
}

export interface 党员党龄分布视图对象 {
  /** 3年以下 */
  data1?: number;
  /** 3-5年 */
  data2?: number;
  /** 6-10年 */
  data3?: number;
  /** 10年以上 */
  data4?: number;
}

export interface 党员信息视图对象 {
  /** 申请资料 */
  applyData?: string;
  /** 头像地址 */
  avatar?: string;
  /** 生日 */
  birthday?: string;
  /** 组织id */
  deptId?: number;
  /** 组织名称 */
  deptName?: string;
  /** 学历;字典-学历 */
  education?: string;
  /** 邮箱 */
  email?: string;
  /** 入党时间 */
  entryPartyDate?: string;
  /** 家庭住址 */
  homeAddress?: string;
  /** 户籍类型 */
  householdType?: string;
  /** 身份证号 */
  idCardNo?: string;
  /** 用户积分 */
  integration?: number;
  /** 是否在职;(0在职 1离职 2停止党籍) */
  isJob?: string;
  /** 是否在册;0组织关系在册  1组织关系离册 */
  isRecord?: string;
  /** 民族;字典-民族 */
  nation?: string;
  /** 流入地党组织 */
  notLocal?: string;
  /** 党员类别;字典-党员类别 */
  partyMemberType?: string;
  /** 手机号码 */
  phone?: string;
  /** 政治面貌 */
  politicStatus?: string;
  /** 党内职务 */
  position?: string;
  /** 移除原因 */
  removeReason?: string;
  /** 移除时间 */
  removeTime?: string;
  /** 角色id */
  roleIds?: number[];
  /** 用户性别;（0男 1女） */
  sex?: string;
  /** 达标分 */
  standardScore?: number;
  /** 党员状态;0正常 1停用 */
  status?: string;
  /** 党员id */
  userId?: number;
  /** 党员姓名 */
  userName?: string;
  /** 用户类型 */
  userType?: string;
}

/**
 * 请求参数
 */
export type 党员信息业务对象Params = { [key: string]: any };

export interface 党员信息业务对象 {
  /** 头像地址 */
  avatar?: string;
  /** 生日 */
  birthday: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 组织id */
  deptId: number;
  /** 学历;字典-学历 */
  education: string;
  /** 邮箱 */
  email: string;
  /** 入党时间 */
  entryPartyDate: string;
  /** 家庭住址 */
  homeAddress?: string;
  /** 身份证号 */
  idCardNo: string;
  /** 民族;字典-民族 */
  nation: string;
  /** 流入地党组织或流出地党组织 */
  notLocal?: string;
  /** 请求参数 */
  params?: 党员信息业务对象Params;
  /** 党员类别 */
  partyMemberType?: string;
  /** 手机号码 */
  phone: string;
  /** 党内职务 */
  position?: string;
  /** 移除原因 */
  removeReason?: string;
  /** 移除时间 */
  removeTime?: string;
  /** 角色id */
  roleIds: number[];
  /** 搜索值 */
  searchValue?: string;
  /** 用户性别;（0男 1女 2未知） */
  sex: string;
  /** 党员状态;（0正常 1停用） */
  status: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
  /** 党员id */
  userId: number;
  /** 党员姓名 */
  userName: string;
}

export interface 停止党籍请求 {
  fromDeptId: number;
  opReason: string;
  outOrInType: string;
  transferDate: string;
  userId: number;
}

export interface 信息栏目视图对象 {
  /** APP端栏目缩略图 */
  appImage?: string;
  /** 分类名字 */
  categoryName?: string;
  /** 主键 */
  id?: number;
  /** 父节点 */
  parentId?: number;
  /** PC端栏目缩略图 */
  pcImage?: string;
  /** 排序 */
  sortNo?: number;
  /** 状态; 0显示 1隐藏 */
  status?: string;
}

/**
 * 请求参数
 */
export type 信息栏目业务对象Params = { [key: string]: any };

export interface 信息栏目业务对象 {
  /** APP端栏目缩略图 */
  appImage?: string;
  /** 分类名字 */
  categoryName: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 主键 */
  id: number;
  /** 请求参数 */
  params?: 信息栏目业务对象Params;
  /** 父节点 */
  parentId?: number;
  /** PC端栏目缩略图 */
  pcImage?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 排序 */
  sortNo: number;
  /** 状态; 0显示 1隐藏 */
  status: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

export interface 信息库视图对象 {
  /** 作者 */
  author?: string;
  /** 所属栏目 */
  categoryName?: string;
  /** 新闻详情 */
  content?: string;
  /** 主键 */
  id?: number;
  /** 新闻简介 */
  introduction?: string;
  /** 新闻链接 */
  link?: string;
  /** 发布时间 */
  publishTime?: string;
  /** 新闻来源 */
  source?: string;
  /** 新闻缩略图 */
  thumbnail?: string;
  /** 新闻标题 */
  title?: string;
}

export interface 信息列视图对象 {
  /** 作者 */
  author?: string;
  /** 栏目名称 */
  categoryName?: string;
  /** 新闻详情 */
  content?: string;
  /** 发布组织 */
  deptId?: number;
  /** 组织名称 */
  deptName?: string;
  /** 主键 */
  id?: number;
  /** 新闻简介 */
  introduction?: string;
  /** 新闻链接 */
  link?: string;
  /** 发布时间 */
  publishTime?: string;
  /** 阅读次数 */
  readCount?: number;
  /** 栏目id */
  sectionId?: number;
  /** 排序 */
  sortNo?: number;
  /** 新闻来源 */
  source?: string;
  /** 新闻缩略图 */
  thumbnail?: string;
  /** 新闻标题 */
  title?: string;
  /** 置顶;（Y是 N否） */
  top?: string;
}

/**
 * 请求参数
 */
export type 信息列业务对象Params = { [key: string]: any };

export interface 信息列业务对象 {
  /** 作者 */
  author?: string;
  /** 新闻详情 */
  content?: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 发布组织 */
  deptId: number;
  /** 主键 */
  id: number;
  /** 新闻简介 */
  introduction?: string;
  /** 新闻链接 */
  link?: string;
  /** 请求参数 */
  params?: 信息列业务对象Params;
  /** 发布时间 */
  publishTime: string;
  /** 阅读次数 */
  readCount: number;
  /** 搜索值 */
  searchValue?: string;
  /** 栏目id */
  sectionId: number;
  /** 排序 */
  sortNo: number;
  /** 新闻来源 */
  source: string;
  /** 新闻缩略图 */
  thumbnail?: string;
  /** 新闻标题 */
  title: string;
  /** 置顶;（Y是 N否） */
  top: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

export interface 人员转移记录视图对象 {
  /** 创建时间 */
  createTime?: string;
  /** 转出组织ID */
  fromDeptId?: number;
  /** 转出组织名称（外部） */
  fromDeptName?: string;
  /** 转出组织名称（内部） */
  fromInnerDeptName?: string;
  /** 主键 */
  id?: number;
  /** 操作原因 */
  opReason?: string;
  /** 转移关系类型;1 内部调动  2 转入 3 转出 4 死亡 5 出党 6 停止党籍 7 恢复党籍 */
  opType?: string;
  /** 停止党籍类型|出党类型|恢复党籍类型 */
  outOrInType?: string;
  /** 备注 */
  remark?: string;
  /** 转入组织ID */
  toDeptId?: number;
  /** 转入组织名称（外部） */
  toDeptName?: string;
  /** 转入组织名称（内部） */
  toInnerDeptName?: string;
  /** 操作时间 */
  transferDate?: string;
  /** 关联党员ID */
  userId?: number;
  /** 党员姓名 */
  userName?: string;
}

export interface 互评记录视图对象 {
  /** 组织名称 */
  deptName: string;
  /** 优秀人数上限 */
  excellentNumberLimit: number;
  /** 主键 */
  id?: number;
  /** 会议id */
  meetingId?: number;
  /** 互评状态(0未完成1已完成) */
  mutualEvaluationStatus?: string;
  /** 互评时间 */
  mutualEvaluationTime?: string;
  /** 参会党员id */
  partyId?: number;
  /** 参会党员名称 */
  partyName: string;
}

export interface App参会党员对象 {
  /** 头像地址 */
  avatar?: string;
  /** 组织id */
  deptId?: number;
  /** 组织名称 */
  deptName?: string;
  /** 参会状态(1未参会 2参会 3缺席(未请假) 4请假 5签到未到场) */
  partyStatus?: string;
  /** 签到状态(1已签到 2未签到) */
  signStatus?: string;
  /** 党员id */
  userId?: number;
  /** 党员姓名 */
  userName?: string;
}

export interface TreeNodeConfig {
  childrenKey?: string;
  deep?: number;
  idKey?: string;
  nameKey?: string;
  parentIdKey?: string;
  weightKey?: string;
}

/**
 * 请求参数
 */
export type SysUserResParams = { [key: string]: any };

export interface SysUserRes {
  admin?: boolean;
  avatar?: string;
  birthday?: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  delFlag?: string;
  dept?: 组织对象;
  deptId?: number;
  devStage?: string;
  education?: string;
  email?: string;
  entryPartyDate?: string;
  homeAddress?: string;
  /** 户籍类型 */
  householdType?: string;
  idCardNo?: string;
  isJob?: string;
  isRecord?: string;
  loginDate?: string;
  loginIp?: string;
  nation?: string;
  notLocal?: string;
  /** 请求参数 */
  params?: SysUserResParams;
  partyMemberType?: string;
  phone?: string;
  /** 政治面貌 */
  politicStatus?: string;
  /** 党内职务 */
  position?: string;
  postIds?: number[];
  removeReason?: string;
  removeTime?: string;
  roleIds?: number[];
  roles?: SysRoleRes[];
  /** 搜索值 */
  searchValue?: string;
  sex?: string;
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
  userId?: number;
  userName?: string;
  userType?: string;
}

/**
 * 请求参数
 */
export type SysUserReqParams = { [key: string]: any };

export interface SysUserReq {
  avatar?: string;
  birthday?: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  delFlag?: string;
  dept?: 组织对象;
  deptId?: number;
  devStage?: string;
  education?: string;
  email?: string;
  entryPartyDate?: string;
  homeAddress?: string;
  /** 户籍类型 */
  householdType?: string;
  idCardNo?: string;
  isJob?: string;
  isRecord?: string;
  loginDate?: string;
  loginIp?: string;
  nation?: string;
  notLocal?: string;
  /** 请求参数 */
  params?: SysUserReqParams;
  partyMemberType?: string;
  phone?: string;
  /** 政治面貌 */
  politicStatus?: string;
  /** 党内职务 */
  position?: string;
  postIds?: number[];
  removeReason?: string;
  removeTime?: string;
  roleIds?: number[];
  roles?: SysRoleReq[];
  /** 搜索值 */
  searchValue?: string;
  sex?: string;
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
  userId?: number;
  userName?: string;
  userType?: string;
}

/**
 * 请求参数
 */
export type SysUserParams = { [key: string]: any };

export interface SysUser {
  admin?: boolean;
  avatar?: string;
  birthday?: string;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  delFlag?: string;
  dept?: 组织对象;
  deptId?: number;
  devStage?: string;
  education?: string;
  email?: string;
  entryPartyDate?: string;
  homeAddress?: string;
  /** 户籍类型 */
  householdType?: string;
  idCardNo?: string;
  isJob?: string;
  isRecord?: string;
  loginDate?: string;
  loginIp?: string;
  nation?: string;
  notLocal?: string;
  /** 请求参数 */
  params?: SysUserParams;
  partyMemberType?: string;
  phone?: string;
  /** 政治面貌 */
  politicStatus?: string;
  /** 党内职务 */
  position?: string;
  postIds?: number[];
  removeReason?: string;
  removeTime?: string;
  roleIds?: number[];
  roles?: SysRoleRes[];
  /** 搜索值 */
  searchValue?: string;
  sex?: string;
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
  userId?: number;
  userName?: string;
  userType?: string;
}

/**
 * 请求参数
 */
export type SysRoleResParams = { [key: string]: any };

export interface SysRoleRes {
  /** 是否管理员 */
  admin?: boolean;
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 数据范围（1：所有数据权限；2：自定义数据权限；3：本组织数据权限；4：本组织及以下数据权限；5：仅本人数据权限） */
  dataScope?: string;
  /** 删除标志（0代表存在 2代表删除） */
  delFlag?: string;
  /** 组织树选择项是否关联显示（0：父子不互相关联显示 1：父子互相关联显示 ） */
  deptCheckStrictly?: boolean;
  /** 组织组（数据权限） */
  deptIds?: number[];
  /** 用户是否存在此角色标识 默认不存在 */
  flag?: boolean;
  /** 菜单树选择项是否关联显示（ 0：父子不互相关联显示 1：父子互相关联显示） */
  menuCheckStrictly?: boolean;
  /** 菜单组 */
  menuIds?: number[];
  /** 请求参数 */
  params?: SysRoleResParams;
  /** 备注 */
  remark?: string;
  /** 角色ID */
  roleId?: number;
  /** 角色权限 */
  roleKey?: string;
  /** 角色名称 */
  roleName?: string;
  /** 角色排序 */
  roleSort?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 角色状态（0正常 1停用） */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type SysRoleReqParams = { [key: string]: any };

export interface SysRoleReq {
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 数据范围（1：所有数据权限；2：自定义数据权限；3：本组织数据权限；4：本组织及以下数据权限；5：仅本人数据权限） */
  dataScope?: string;
  /** 删除标志（0代表存在 2代表删除） */
  delFlag?: string;
  /** 组织树选择项是否关联显示（0：父子不互相关联显示 1：父子互相关联显示 ） */
  deptCheckStrictly?: boolean;
  /** 组织组（数据权限） */
  deptIds?: number[];
  /** 用户是否存在此角色标识 默认不存在 */
  flag?: boolean;
  /** 菜单树选择项是否关联显示（ 0：父子不互相关联显示 1：父子互相关联显示） */
  menuCheckStrictly?: boolean;
  /** 菜单组 */
  menuIds?: number[];
  /** 请求参数 */
  params?: SysRoleReqParams;
  /** 备注 */
  remark?: string;
  /** 角色ID */
  roleId?: number;
  /** 角色权限 */
  roleKey?: string;
  /** 角色名称 */
  roleName?: string;
  /** 角色排序 */
  roleSort?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 角色状态（0正常 1停用） */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type SysRoleParams = { [key: string]: any };

export interface SysRole {
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 数据范围（1：所有数据权限；2：自定义数据权限；3：本组织数据权限；4：本组织及以下数据权限；5：仅本人数据权限） */
  dataScope?: string;
  /** 删除标志（0代表存在 2代表删除） */
  delFlag?: string;
  /** 组织树选择项是否关联显示（0：父子不互相关联显示 1：父子互相关联显示 ） */
  deptCheckStrictly?: boolean;
  /** 组织组（数据权限） */
  deptIds?: number[];
  /** 用户是否存在此角色标识 默认不存在 */
  flag?: boolean;
  /** 菜单树选择项是否关联显示（ 0：父子不互相关联显示 1：父子互相关联显示） */
  menuCheckStrictly?: boolean;
  /** 菜单组 */
  menuIds?: number[];
  /** 请求参数 */
  params?: SysRoleParams;
  /** 备注 */
  remark?: string;
  /** 角色ID */
  roleId?: number;
  /** 角色权限 */
  roleKey?: string;
  /** 角色名称 */
  roleName?: string;
  /** 角色排序 */
  roleSort?: string;
  /** 搜索值 */
  searchValue?: string;
  /** 角色状态（0正常 1停用） */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

/**
 * 请求参数
 */
export type SysNoticeParams = { [key: string]: any };

export interface SysNotice {
  /** 创建者 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  delFlag?: number;
  /** 公告内容 */
  noticeContent?: string;
  /** 公告ID */
  noticeId?: number;
  /** 通知对象（0组织 1个人） */
  noticeObject?: string;
  /** 通知党员或组织 */
  noticePeople?: string;
  /** 公告标题 */
  noticeTitle?: string;
  /** 公告类型 */
  noticeType?: string;
  /** 请求参数 */
  params?: SysNoticeParams;
  /** 推送通知次数 */
  pushCount?: number;
  /** 搜索值 */
  searchValue?: string;
  /** 公告状态（0正常 1关闭） */
  status?: string;
  /** 更新者 */
  updateBy?: string;
  /** 更新时间 */
  updateTime?: string;
}

export interface SysDeptVo {
  /** 下级党组织数量 */
  deptCount?: number;
  leadershipVoList?: 领导班子视图对象[];
  /** 组织信息 */
  sysDept?: 组织对象;
  /** 党员数量 */
  userCount?: number;
}

export interface Oss对象存储视图对象 {
  /** 上传人 */
  createBy?: string;
  /** 创建时间 */
  createTime?: string;
  /** 文件名 */
  fileName?: string;
  /** 文件后缀名 */
  fileSuffix?: string;
  /** 原名 */
  originalName?: string;
  /** 对象存储主键 */
  ossId?: number;
  /** 服务商 */
  service?: string;
  /** URL地址 */
  url?: string;
}

export interface ComparableObject {
  [key: string]: any;
}

export interface CharSequence {
  [key: string]: any;
}

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

/**
 * @summary 查询通讯录信息列表
 */
export const getPartyAddressBookList = (
  params: MaybeRef<GetPartyAddressBookListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象党员信息视图对象>(
    { url: `/party/addressBook/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyAddressBookListQueryKey = (
  params: MaybeRef<GetPartyAddressBookListParams>,
) => ["party", "addressBook", "list", ...(params ? [params] : [])] as const;

export const getGetPartyAddressBookListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyAddressBookList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyAddressBookListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyAddressBookList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyAddressBookList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyAddressBookListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyAddressBookList>>
  > = ({ signal }) => getPartyAddressBookList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyAddressBookListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyAddressBookList>>
>;
export type GetPartyAddressBookListQueryError = void;

/**
 * @summary 查询通讯录信息列表
 */
export const useGetPartyAddressBookList = <
  TData = Awaited<ReturnType<typeof getPartyAddressBookList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyAddressBookListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyAddressBookList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyAddressBookListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 获取缓存监控详细信息
 */
export const getMonitorCache = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象MapStringObject>(
    { url: `/monitor/cache`, method: "get", signal },
    options,
  );
};

export const getGetMonitorCacheQueryKey = () => ["monitor", "cache"] as const;

export const getGetMonitorCacheQueryOptions = <
  TData = Awaited<ReturnType<typeof getMonitorCache>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getMonitorCache>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getMonitorCache>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetMonitorCacheQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMonitorCache>>> = ({
    signal,
  }) => getMonitorCache(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetMonitorCacheQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMonitorCache>>
>;
export type GetMonitorCacheQueryError = void;

/**
 * @summary 获取缓存监控详细信息
 */
export const useGetMonitorCache = <
  TData = Awaited<ReturnType<typeof getMonitorCache>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getMonitorCache>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMonitorCacheQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 生成验证码
 */
export const getCaptchaImage = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象MapStringObject>(
    { url: `/captchaImage`, method: "get", signal },
    options,
  );
};

export const getGetCaptchaImageQueryKey = () => ["captchaImage"] as const;

export const getGetCaptchaImageQueryOptions = <
  TData = Awaited<ReturnType<typeof getCaptchaImage>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCaptchaImage>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getCaptchaImage>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetCaptchaImageQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCaptchaImage>>> = ({
    signal,
  }) => getCaptchaImage(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetCaptchaImageQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCaptchaImage>>
>;
export type GetCaptchaImageQueryError = void;

/**
 * @summary 生成验证码
 */
export const useGetCaptchaImage = <
  TData = Awaited<ReturnType<typeof getCaptchaImage>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getCaptchaImage>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetCaptchaImageQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询党员年龄分布
 */
export const getPartyCockpitAgeDistribution = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象党员年龄分布视图对象>(
    { url: `/party/cockpit/ageDistribution`, method: "get", signal },
    options,
  );
};

export const getGetPartyCockpitAgeDistributionQueryKey = () =>
  ["party", "cockpit", "ageDistribution"] as const;

export const getGetPartyCockpitAgeDistributionQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyCockpitAgeDistribution>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitAgeDistribution>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyCockpitAgeDistribution>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyCockpitAgeDistributionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyCockpitAgeDistribution>>
  > = ({ signal }) => getPartyCockpitAgeDistribution(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyCockpitAgeDistributionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyCockpitAgeDistribution>>
>;
export type GetPartyCockpitAgeDistributionQueryError = void;

/**
 * @summary 查询党员年龄分布
 */
export const useGetPartyCockpitAgeDistribution = <
  TData = Awaited<ReturnType<typeof getPartyCockpitAgeDistribution>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitAgeDistribution>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyCockpitAgeDistributionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询党委树
 */
export const getPartyCockpitDeptTree = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象ListTreeLong>(
    { url: `/party/cockpit/deptTree`, method: "get", signal },
    options,
  );
};

export const getGetPartyCockpitDeptTreeQueryKey = () =>
  ["party", "cockpit", "deptTree"] as const;

export const getGetPartyCockpitDeptTreeQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyCockpitDeptTree>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitDeptTree>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyCockpitDeptTree>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyCockpitDeptTreeQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyCockpitDeptTree>>
  > = ({ signal }) => getPartyCockpitDeptTree(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyCockpitDeptTreeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyCockpitDeptTree>>
>;
export type GetPartyCockpitDeptTreeQueryError = void;

/**
 * @summary 查询党委树
 */
export const useGetPartyCockpitDeptTree = <
  TData = Awaited<ReturnType<typeof getPartyCockpitDeptTree>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitDeptTree>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyCockpitDeptTreeQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询党员学历分布
 */
export const getPartyCockpitEducationDistribution = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象党员学历分布视图对象>(
    { url: `/party/cockpit/educationDistribution`, method: "get", signal },
    options,
  );
};

export const getGetPartyCockpitEducationDistributionQueryKey = () =>
  ["party", "cockpit", "educationDistribution"] as const;

export const getGetPartyCockpitEducationDistributionQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyCockpitEducationDistribution>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitEducationDistribution>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyCockpitEducationDistribution>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyCockpitEducationDistributionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyCockpitEducationDistribution>>
  > = ({ signal }) =>
    getPartyCockpitEducationDistribution(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyCockpitEducationDistributionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyCockpitEducationDistribution>>
>;
export type GetPartyCockpitEducationDistributionQueryError = void;

/**
 * @summary 查询党员学历分布
 */
export const useGetPartyCockpitEducationDistribution = <
  TData = Awaited<ReturnType<typeof getPartyCockpitEducationDistribution>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitEducationDistribution>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPartyCockpitEducationDistributionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询优秀党员
 */
export const getPartyCockpitExcellentUsers = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List党员信息视图对象>(
    { url: `/party/cockpit/excellentUsers`, method: "get", signal },
    options,
  );
};

export const getGetPartyCockpitExcellentUsersQueryKey = () =>
  ["party", "cockpit", "excellentUsers"] as const;

export const getGetPartyCockpitExcellentUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyCockpitExcellentUsers>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitExcellentUsers>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyCockpitExcellentUsers>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyCockpitExcellentUsersQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyCockpitExcellentUsers>>
  > = ({ signal }) => getPartyCockpitExcellentUsers(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyCockpitExcellentUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyCockpitExcellentUsers>>
>;
export type GetPartyCockpitExcellentUsersQueryError = void;

/**
 * @summary 查询优秀党员
 */
export const useGetPartyCockpitExcellentUsers = <
  TData = Awaited<ReturnType<typeof getPartyCockpitExcellentUsers>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitExcellentUsers>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyCockpitExcellentUsersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询新闻浏览量
 */
export const getPartyCockpitNewsViews = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象新闻浏览量视图对象>(
    { url: `/party/cockpit/newsViews`, method: "get", signal },
    options,
  );
};

export const getGetPartyCockpitNewsViewsQueryKey = () =>
  ["party", "cockpit", "newsViews"] as const;

export const getGetPartyCockpitNewsViewsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyCockpitNewsViews>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitNewsViews>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyCockpitNewsViews>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyCockpitNewsViewsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyCockpitNewsViews>>
  > = ({ signal }) => getPartyCockpitNewsViews(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyCockpitNewsViewsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyCockpitNewsViews>>
>;
export type GetPartyCockpitNewsViewsQueryError = void;

/**
 * @summary 查询新闻浏览量
 */
export const useGetPartyCockpitNewsViews = <
  TData = Awaited<ReturnType<typeof getPartyCockpitNewsViews>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitNewsViews>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyCockpitNewsViewsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询组织结构
 */
export const getPartyCockpitOrgStructure = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象组织结构视图对象>(
    { url: `/party/cockpit/orgStructure`, method: "get", signal },
    options,
  );
};

export const getGetPartyCockpitOrgStructureQueryKey = () =>
  ["party", "cockpit", "orgStructure"] as const;

export const getGetPartyCockpitOrgStructureQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyCockpitOrgStructure>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitOrgStructure>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyCockpitOrgStructure>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyCockpitOrgStructureQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyCockpitOrgStructure>>
  > = ({ signal }) => getPartyCockpitOrgStructure(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyCockpitOrgStructureQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyCockpitOrgStructure>>
>;
export type GetPartyCockpitOrgStructureQueryError = void;

/**
 * @summary 查询组织结构
 */
export const useGetPartyCockpitOrgStructure = <
  TData = Awaited<ReturnType<typeof getPartyCockpitOrgStructure>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitOrgStructure>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyCockpitOrgStructureQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询党委简介
 */
export const getPartyCockpitPartyCommitteeIntroduction = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象党委简介视图对象>(
    { url: `/party/cockpit/partyCommitteeIntroduction`, method: "get", signal },
    options,
  );
};

export const getGetPartyCockpitPartyCommitteeIntroductionQueryKey = () =>
  ["party", "cockpit", "partyCommitteeIntroduction"] as const;

export const getGetPartyCockpitPartyCommitteeIntroductionQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyCockpitPartyCommitteeIntroduction>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitPartyCommitteeIntroduction>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyCockpitPartyCommitteeIntroduction>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyCockpitPartyCommitteeIntroductionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyCockpitPartyCommitteeIntroduction>>
  > = ({ signal }) =>
    getPartyCockpitPartyCommitteeIntroduction(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyCockpitPartyCommitteeIntroductionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyCockpitPartyCommitteeIntroduction>>
>;
export type GetPartyCockpitPartyCommitteeIntroductionQueryError = void;

/**
 * @summary 查询党委简介
 */
export const useGetPartyCockpitPartyCommitteeIntroduction = <
  TData = Awaited<ReturnType<typeof getPartyCockpitPartyCommitteeIntroduction>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitPartyCommitteeIntroduction>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPartyCockpitPartyCommitteeIntroductionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询党员党龄分布
 */
export const getPartyCockpitPartyStandingDistribution = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象党员党龄分布视图对象>(
    { url: `/party/cockpit/partyStandingDistribution`, method: "get", signal },
    options,
  );
};

export const getGetPartyCockpitPartyStandingDistributionQueryKey = () =>
  ["party", "cockpit", "partyStandingDistribution"] as const;

export const getGetPartyCockpitPartyStandingDistributionQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyCockpitPartyStandingDistribution>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitPartyStandingDistribution>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyCockpitPartyStandingDistribution>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyCockpitPartyStandingDistributionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyCockpitPartyStandingDistribution>>
  > = ({ signal }) =>
    getPartyCockpitPartyStandingDistribution(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyCockpitPartyStandingDistributionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyCockpitPartyStandingDistribution>>
>;
export type GetPartyCockpitPartyStandingDistributionQueryError = void;

/**
 * @summary 查询党员党龄分布
 */
export const useGetPartyCockpitPartyStandingDistribution = <
  TData = Awaited<ReturnType<typeof getPartyCockpitPartyStandingDistribution>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyCockpitPartyStandingDistribution>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPartyCockpitPartyStandingDistributionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 修改流动党员信息
 */
export const putPartyUserFlow = (
  党员信息业务对象: MaybeRef<党员信息业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/flow`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 党员信息业务对象,
    },
    options,
  );
};

export const getPutPartyUserFlowMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyUserFlow>>,
    TError,
    { data: 党员信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPartyUserFlow>>,
  TError,
  { data: 党员信息业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPartyUserFlow>>,
    { data: 党员信息业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putPartyUserFlow(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPartyUserFlowMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPartyUserFlow>>
>;
export type PutPartyUserFlowMutationBody = 党员信息业务对象;
export type PutPartyUserFlowMutationError = unknown;

/**
 * @summary 修改流动党员信息
 */
export const usePutPartyUserFlow = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyUserFlow>>,
    TError,
    { data: 党员信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutPartyUserFlowMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增流动党员信息
 */
export const postPartyUserFlow = (
  党员信息业务对象: MaybeRef<党员信息业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/flow`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 党员信息业务对象,
    },
    options,
  );
};

export const getPostPartyUserFlowMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserFlow>>,
    TError,
    { data: 党员信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserFlow>>,
  TError,
  { data: 党员信息业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserFlow>>,
    { data: 党员信息业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserFlow(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserFlowMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserFlow>>
>;
export type PostPartyUserFlowMutationBody = 党员信息业务对象;
export type PostPartyUserFlowMutationError = unknown;

/**
 * @summary 新增流动党员信息
 */
export const usePostPartyUserFlow = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserFlow>>,
    TError,
    { data: 党员信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyUserFlowMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 转为在职党员
 */
export const postPartyUserFlowChangeToJob = (
  postPartyUserFlowChangeToJobBody: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/flow/changeToJob`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: postPartyUserFlowChangeToJobBody,
    },
    options,
  );
};

export const getPostPartyUserFlowChangeToJobMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserFlowChangeToJob>>,
    TError,
    { data: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserFlowChangeToJob>>,
  TError,
  { data: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserFlowChangeToJob>>,
    { data: number }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserFlowChangeToJob(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserFlowChangeToJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserFlowChangeToJob>>
>;
export type PostPartyUserFlowChangeToJobMutationBody = number;
export type PostPartyUserFlowChangeToJobMutationError = unknown;

/**
 * @summary 转为在职党员
 */
export const usePostPartyUserFlowChangeToJob = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserFlowChangeToJob>>,
    TError,
    { data: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserFlowChangeToJobMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询流动党员信息列表
 */
export const getPartyUserFlowList = (
  params: MaybeRef<GetPartyUserFlowListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象党员信息视图对象>(
    { url: `/party/user/flow/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyUserFlowListQueryKey = (
  params: MaybeRef<GetPartyUserFlowListParams>,
) => ["party", "user", "flow", "list", ...(params ? [params] : [])] as const;

export const getGetPartyUserFlowListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserFlowList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserFlowListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserFlowList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserFlowList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserFlowListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserFlowList>>
  > = ({ signal }) => getPartyUserFlowList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserFlowListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserFlowList>>
>;
export type GetPartyUserFlowListQueryError = void;

/**
 * @summary 查询流动党员信息列表
 */
export const useGetPartyUserFlowList = <
  TData = Awaited<ReturnType<typeof getPartyUserFlowList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserFlowListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserFlowList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserFlowListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除流动党员信息
 */
export const deletePartyUserFlowByUserIds = (
  userIds: MaybeRef<string>,
  deletePartyUserFlowByUserIdsBody: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/flow/${unref(userIds)}`,
      method: "delete",
      headers: { "Content-Type": "application/json" },
      data: deletePartyUserFlowByUserIdsBody,
    },
    options,
  );
};

export const getDeletePartyUserFlowByUserIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyUserFlowByUserIds>>,
    TError,
    { userIds: string; data: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyUserFlowByUserIds>>,
  TError,
  { userIds: string; data: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyUserFlowByUserIds>>,
    { userIds: string; data: string }
  > = (props) => {
    const { userIds, data } = props ?? {};

    return deletePartyUserFlowByUserIds(userIds, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyUserFlowByUserIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyUserFlowByUserIds>>
>;
export type DeletePartyUserFlowByUserIdsMutationBody = string;
export type DeletePartyUserFlowByUserIdsMutationError = unknown;

/**
 * @summary 删除流动党员信息
 */
export const useDeletePartyUserFlowByUserIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyUserFlowByUserIds>>,
    TError,
    { userIds: string; data: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyUserFlowByUserIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取流动党员信息详细信息
 */
export const getPartyUserFlowByUserId = (
  userId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象党员信息视图对象>(
    { url: `/party/user/flow/${unref(userId)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyUserFlowByUserIdQueryKey = (userId: MaybeRef<number>) =>
  ["party", "user", "flow", userId] as const;

export const getGetPartyUserFlowByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserFlowByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserFlowByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserFlowByUserId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserFlowByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserFlowByUserId>>
  > = ({ signal }) => getPartyUserFlowByUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPartyUserFlowByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserFlowByUserId>>
>;
export type GetPartyUserFlowByUserIdQueryError = void;

/**
 * @summary 获取流动党员信息详细信息
 */
export const useGetPartyUserFlowByUserId = <
  TData = Awaited<ReturnType<typeof getPartyUserFlowByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserFlowByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserFlowByUserIdQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询历史党员信息列表
 */
export const getPartyUserHistoryList = (
  params: MaybeRef<GetPartyUserHistoryListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象党员信息视图对象>(
    { url: `/party/user/history/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyUserHistoryListQueryKey = (
  params: MaybeRef<GetPartyUserHistoryListParams>,
) => ["party", "user", "history", "list", ...(params ? [params] : [])] as const;

export const getGetPartyUserHistoryListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserHistoryList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserHistoryListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserHistoryList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserHistoryList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserHistoryListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserHistoryList>>
  > = ({ signal }) => getPartyUserHistoryList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserHistoryListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserHistoryList>>
>;
export type GetPartyUserHistoryListQueryError = void;

/**
 * @summary 查询历史党员信息列表
 */
export const useGetPartyUserHistoryList = <
  TData = Awaited<ReturnType<typeof getPartyUserHistoryList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserHistoryListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserHistoryList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserHistoryListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 还原/批量还原
 */
export const postPartyUserHistoryRestore = (
  postPartyUserHistoryRestoreBody: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/history/restore`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: postPartyUserHistoryRestoreBody,
    },
    options,
  );
};

export const getPostPartyUserHistoryRestoreMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserHistoryRestore>>,
    TError,
    { data: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserHistoryRestore>>,
  TError,
  { data: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserHistoryRestore>>,
    { data: number }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserHistoryRestore(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserHistoryRestoreMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserHistoryRestore>>
>;
export type PostPartyUserHistoryRestoreMutationBody = number;
export type PostPartyUserHistoryRestoreMutationError = unknown;

/**
 * @summary 还原/批量还原
 */
export const usePostPartyUserHistoryRestore = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserHistoryRestore>>,
    TError,
    { data: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserHistoryRestoreMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 删除历史党员信息
 */
export const deletePartyUserHistoryByUserIds = (
  userIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/user/history/${unref(userIds)}`, method: "delete" },
    options,
  );
};

export const getDeletePartyUserHistoryByUserIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyUserHistoryByUserIds>>,
    TError,
    { userIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyUserHistoryByUserIds>>,
  TError,
  { userIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyUserHistoryByUserIds>>,
    { userIds: string }
  > = (props) => {
    const { userIds } = props ?? {};

    return deletePartyUserHistoryByUserIds(userIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyUserHistoryByUserIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyUserHistoryByUserIds>>
>;

export type DeletePartyUserHistoryByUserIdsMutationError = unknown;

/**
 * @summary 删除历史党员信息
 */
export const useDeletePartyUserHistoryByUserIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyUserHistoryByUserIds>>,
    TError,
    { userIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyUserHistoryByUserIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询党员年龄分布
 */
export const getPartyHomeAgeDistribution = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象党员年龄分布视图对象>(
    { url: `/party/home/ageDistribution`, method: "get", signal },
    options,
  );
};

export const getGetPartyHomeAgeDistributionQueryKey = () =>
  ["party", "home", "ageDistribution"] as const;

export const getGetPartyHomeAgeDistributionQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyHomeAgeDistribution>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomeAgeDistribution>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyHomeAgeDistribution>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyHomeAgeDistributionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyHomeAgeDistribution>>
  > = ({ signal }) => getPartyHomeAgeDistribution(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyHomeAgeDistributionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyHomeAgeDistribution>>
>;
export type GetPartyHomeAgeDistributionQueryError = void;

/**
 * @summary 查询党员年龄分布
 */
export const useGetPartyHomeAgeDistribution = <
  TData = Awaited<ReturnType<typeof getPartyHomeAgeDistribution>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomeAgeDistribution>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyHomeAgeDistributionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询党组织数量
 */
export const getPartyHomeDeptCount = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象Int>(
    { url: `/party/home/deptCount`, method: "get", signal },
    options,
  );
};

export const getGetPartyHomeDeptCountQueryKey = () =>
  ["party", "home", "deptCount"] as const;

export const getGetPartyHomeDeptCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyHomeDeptCount>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomeDeptCount>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyHomeDeptCount>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyHomeDeptCountQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyHomeDeptCount>>
  > = ({ signal }) => getPartyHomeDeptCount(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyHomeDeptCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyHomeDeptCount>>
>;
export type GetPartyHomeDeptCountQueryError = void;

/**
 * @summary 查询党组织数量
 */
export const useGetPartyHomeDeptCount = <
  TData = Awaited<ReturnType<typeof getPartyHomeDeptCount>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomeDeptCount>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyHomeDeptCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询党员学历分布
 */
export const getPartyHomeEducationDistribution = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象党员学历分布视图对象>(
    { url: `/party/home/educationDistribution`, method: "get", signal },
    options,
  );
};

export const getGetPartyHomeEducationDistributionQueryKey = () =>
  ["party", "home", "educationDistribution"] as const;

export const getGetPartyHomeEducationDistributionQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyHomeEducationDistribution>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomeEducationDistribution>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyHomeEducationDistribution>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyHomeEducationDistributionQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyHomeEducationDistribution>>
  > = ({ signal }) => getPartyHomeEducationDistribution(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyHomeEducationDistributionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyHomeEducationDistribution>>
>;
export type GetPartyHomeEducationDistributionQueryError = void;

/**
 * @summary 查询党员学历分布
 */
export const useGetPartyHomeEducationDistribution = <
  TData = Awaited<ReturnType<typeof getPartyHomeEducationDistribution>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomeEducationDistribution>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPartyHomeEducationDistributionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询新闻浏览量
 */
export const getPartyHomeNewsViews = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象新闻浏览量视图对象>(
    { url: `/party/home/newsViews`, method: "get", signal },
    options,
  );
};

export const getGetPartyHomeNewsViewsQueryKey = () =>
  ["party", "home", "newsViews"] as const;

export const getGetPartyHomeNewsViewsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyHomeNewsViews>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomeNewsViews>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyHomeNewsViews>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyHomeNewsViewsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyHomeNewsViews>>
  > = ({ signal }) => getPartyHomeNewsViews(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyHomeNewsViewsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyHomeNewsViews>>
>;
export type GetPartyHomeNewsViewsQueryError = void;

/**
 * @summary 查询新闻浏览量
 */
export const useGetPartyHomeNewsViews = <
  TData = Awaited<ReturnType<typeof getPartyHomeNewsViews>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomeNewsViews>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyHomeNewsViewsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询党员数量
 */
export const getPartyHomePartyMemberCount = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象Int>(
    { url: `/party/home/partyMemberCount`, method: "get", signal },
    options,
  );
};

export const getGetPartyHomePartyMemberCountQueryKey = () =>
  ["party", "home", "partyMemberCount"] as const;

export const getGetPartyHomePartyMemberCountQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyHomePartyMemberCount>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomePartyMemberCount>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyHomePartyMemberCount>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyHomePartyMemberCountQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyHomePartyMemberCount>>
  > = ({ signal }) => getPartyHomePartyMemberCount(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyHomePartyMemberCountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyHomePartyMemberCount>>
>;
export type GetPartyHomePartyMemberCountQueryError = void;

/**
 * @summary 查询党员数量
 */
export const useGetPartyHomePartyMemberCount = <
  TData = Awaited<ReturnType<typeof getPartyHomePartyMemberCount>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomePartyMemberCount>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyHomePartyMemberCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询个人积分
 */
export const getPartyHomePersonScore = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象Long>(
    { url: `/party/home/personScore`, method: "get", signal },
    options,
  );
};

export const getGetPartyHomePersonScoreQueryKey = () =>
  ["party", "home", "personScore"] as const;

export const getGetPartyHomePersonScoreQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyHomePersonScore>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomePersonScore>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyHomePersonScore>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyHomePersonScoreQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyHomePersonScore>>
  > = ({ signal }) => getPartyHomePersonScore(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyHomePersonScoreQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyHomePersonScore>>
>;
export type GetPartyHomePersonScoreQueryError = void;

/**
 * @summary 查询个人积分
 */
export const useGetPartyHomePersonScore = <
  TData = Awaited<ReturnType<typeof getPartyHomePersonScore>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyHomePersonScore>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyHomePersonScoreQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 修改在职党员信息
 */
export const putPartyUserJob = (
  党员信息业务对象: MaybeRef<党员信息业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/job`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 党员信息业务对象,
    },
    options,
  );
};

export const getPutPartyUserJobMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyUserJob>>,
    TError,
    { data: 党员信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPartyUserJob>>,
  TError,
  { data: 党员信息业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPartyUserJob>>,
    { data: 党员信息业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putPartyUserJob(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPartyUserJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPartyUserJob>>
>;
export type PutPartyUserJobMutationBody = 党员信息业务对象;
export type PutPartyUserJobMutationError = unknown;

/**
 * @summary 修改在职党员信息
 */
export const usePutPartyUserJob = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyUserJob>>,
    TError,
    { data: 党员信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutPartyUserJobMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增在职党员信息
 */
export const postPartyUserJob = (
  党员信息业务对象: MaybeRef<党员信息业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/job`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 党员信息业务对象,
    },
    options,
  );
};

export const getPostPartyUserJobMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserJob>>,
    TError,
    { data: 党员信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserJob>>,
  TError,
  { data: 党员信息业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserJob>>,
    { data: 党员信息业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserJob(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserJobMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserJob>>
>;
export type PostPartyUserJobMutationBody = 党员信息业务对象;
export type PostPartyUserJobMutationError = unknown;

/**
 * @summary 新增在职党员信息
 */
export const usePostPartyUserJob = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserJob>>,
    TError,
    { data: 党员信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyUserJobMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 转为流动党员
 */
export const postPartyUserJobChangeToFlow = (
  转流动党员请求: MaybeRef<转流动党员请求>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/job/changeToFlow`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 转流动党员请求,
    },
    options,
  );
};

export const getPostPartyUserJobChangeToFlowMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserJobChangeToFlow>>,
    TError,
    { data: 转流动党员请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserJobChangeToFlow>>,
  TError,
  { data: 转流动党员请求 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserJobChangeToFlow>>,
    { data: 转流动党员请求 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserJobChangeToFlow(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserJobChangeToFlowMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserJobChangeToFlow>>
>;
export type PostPartyUserJobChangeToFlowMutationBody = 转流动党员请求;
export type PostPartyUserJobChangeToFlowMutationError = unknown;

/**
 * @summary 转为流动党员
 */
export const usePostPartyUserJobChangeToFlow = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserJobChangeToFlow>>,
    TError,
    { data: 转流动党员请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserJobChangeToFlowMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 下载导入模板
 */
export const getPartyUserJobDownloadTemplate = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/party/user/job/downloadTemplate`, method: "get", signal },
    options,
  );
};

export const getGetPartyUserJobDownloadTemplateQueryKey = () =>
  ["party", "user", "job", "downloadTemplate"] as const;

export const getGetPartyUserJobDownloadTemplateQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserJobDownloadTemplate>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyUserJobDownloadTemplate>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserJobDownloadTemplate>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserJobDownloadTemplateQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserJobDownloadTemplate>>
  > = ({ signal }) => getPartyUserJobDownloadTemplate(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserJobDownloadTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserJobDownloadTemplate>>
>;
export type GetPartyUserJobDownloadTemplateQueryError = unknown;

/**
 * @summary 下载导入模板
 */
export const useGetPartyUserJobDownloadTemplate = <
  TData = Awaited<ReturnType<typeof getPartyUserJobDownloadTemplate>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyUserJobDownloadTemplate>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserJobDownloadTemplateQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 导出在职党员信息列表
 */
export const postPartyUserJobExport = (
  params: MaybeRef<PostPartyUserJobExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/party/user/job/export`, method: "post", params },
    options,
  );
};

export const getPostPartyUserJobExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserJobExport>>,
    TError,
    { params: PostPartyUserJobExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserJobExport>>,
  TError,
  { params: PostPartyUserJobExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserJobExport>>,
    { params: PostPartyUserJobExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postPartyUserJobExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserJobExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserJobExport>>
>;

export type PostPartyUserJobExportMutationError = unknown;

/**
 * @summary 导出在职党员信息列表
 */
export const usePostPartyUserJobExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserJobExport>>,
    TError,
    { params: PostPartyUserJobExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyUserJobExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 根据组织id查询在职党员
 */
export const getPartyUserJobGetJobPartyMembers = (
  params: MaybeRef<GetPartyUserJobGetJobPartyMembersParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List党员信息视图对象>(
    {
      url: `/party/user/job/getJobPartyMembers`,
      method: "get",
      params,
      signal,
    },
    options,
  );
};

export const getGetPartyUserJobGetJobPartyMembersQueryKey = (
  params: MaybeRef<GetPartyUserJobGetJobPartyMembersParams>,
) =>
  [
    "party",
    "user",
    "job",
    "getJobPartyMembers",
    ...(params ? [params] : []),
  ] as const;

export const getGetPartyUserJobGetJobPartyMembersQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserJobGetJobPartyMembers>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserJobGetJobPartyMembersParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserJobGetJobPartyMembers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserJobGetJobPartyMembers>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserJobGetJobPartyMembersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserJobGetJobPartyMembers>>
  > = ({ signal }) =>
    getPartyUserJobGetJobPartyMembers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserJobGetJobPartyMembersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserJobGetJobPartyMembers>>
>;
export type GetPartyUserJobGetJobPartyMembersQueryError = void;

/**
 * @summary 根据组织id查询在职党员
 */
export const useGetPartyUserJobGetJobPartyMembers = <
  TData = Awaited<ReturnType<typeof getPartyUserJobGetJobPartyMembers>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserJobGetJobPartyMembersParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserJobGetJobPartyMembers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserJobGetJobPartyMembersQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 根据用户id查询在职党员
 */
export const getPartyUserJobGetUserByUserIds = (
  params: MaybeRef<GetPartyUserJobGetUserByUserIdsParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List党员信息视图对象>(
    { url: `/party/user/job/getUserByUserIds`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyUserJobGetUserByUserIdsQueryKey = (
  params: MaybeRef<GetPartyUserJobGetUserByUserIdsParams>,
) =>
  [
    "party",
    "user",
    "job",
    "getUserByUserIds",
    ...(params ? [params] : []),
  ] as const;

export const getGetPartyUserJobGetUserByUserIdsQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserJobGetUserByUserIds>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserJobGetUserByUserIdsParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserJobGetUserByUserIds>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserJobGetUserByUserIds>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserJobGetUserByUserIdsQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserJobGetUserByUserIds>>
  > = ({ signal }) =>
    getPartyUserJobGetUserByUserIds(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserJobGetUserByUserIdsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserJobGetUserByUserIds>>
>;
export type GetPartyUserJobGetUserByUserIdsQueryError = void;

/**
 * @summary 根据用户id查询在职党员
 */
export const useGetPartyUserJobGetUserByUserIds = <
  TData = Awaited<ReturnType<typeof getPartyUserJobGetUserByUserIds>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserJobGetUserByUserIdsParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserJobGetUserByUserIds>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserJobGetUserByUserIdsQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 导入数据
 */
export const postPartyUserJobImportData = (
  postPartyUserJobImportDataBody: MaybeRef<PostPartyUserJobImportDataBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData();
  formData.append("file", postPartyUserJobImportDataBody.file);

  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/job/importData`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getPostPartyUserJobImportDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserJobImportData>>,
    TError,
    { data: PostPartyUserJobImportDataBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserJobImportData>>,
  TError,
  { data: PostPartyUserJobImportDataBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserJobImportData>>,
    { data: PostPartyUserJobImportDataBody }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserJobImportData(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserJobImportDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserJobImportData>>
>;
export type PostPartyUserJobImportDataMutationBody =
  PostPartyUserJobImportDataBody;
export type PostPartyUserJobImportDataMutationError = unknown;

/**
 * @summary 导入数据
 */
export const usePostPartyUserJobImportData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserJobImportData>>,
    TError,
    { data: PostPartyUserJobImportDataBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyUserJobImportDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询在职党员信息列表
 */
export const getPartyUserJobList = (
  params: MaybeRef<GetPartyUserJobListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象党员信息视图对象>(
    { url: `/party/user/job/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyUserJobListQueryKey = (
  params: MaybeRef<GetPartyUserJobListParams>,
) => ["party", "user", "job", "list", ...(params ? [params] : [])] as const;

export const getGetPartyUserJobListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserJobList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserJobListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserJobList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserJobList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserJobListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserJobList>>
  > = ({ signal }) => getPartyUserJobList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserJobListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserJobList>>
>;
export type GetPartyUserJobListQueryError = void;

/**
 * @summary 查询在职党员信息列表
 */
export const useGetPartyUserJobList = <
  TData = Awaited<ReturnType<typeof getPartyUserJobList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserJobListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserJobList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserJobListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 重置密码
 */
export const postPartyUserJobReset = (
  重置密码请求: MaybeRef<重置密码请求>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/job/reset`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 重置密码请求,
    },
    options,
  );
};

export const getPostPartyUserJobResetMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserJobReset>>,
    TError,
    { data: 重置密码请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserJobReset>>,
  TError,
  { data: 重置密码请求 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserJobReset>>,
    { data: 重置密码请求 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserJobReset(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserJobResetMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserJobReset>>
>;
export type PostPartyUserJobResetMutationBody = 重置密码请求;
export type PostPartyUserJobResetMutationError = unknown;

/**
 * @summary 重置密码
 */
export const usePostPartyUserJobReset = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserJobReset>>,
    TError,
    { data: 重置密码请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyUserJobResetMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 删除在职党员信息
 */
export const deletePartyUserJobByUserIds = (
  userIds: MaybeRef<string>,
  deletePartyUserJobByUserIdsBody: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/job/${unref(userIds)}`,
      method: "delete",
      headers: { "Content-Type": "application/json" },
      data: deletePartyUserJobByUserIdsBody,
    },
    options,
  );
};

export const getDeletePartyUserJobByUserIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyUserJobByUserIds>>,
    TError,
    { userIds: string; data: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyUserJobByUserIds>>,
  TError,
  { userIds: string; data: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyUserJobByUserIds>>,
    { userIds: string; data: string }
  > = (props) => {
    const { userIds, data } = props ?? {};

    return deletePartyUserJobByUserIds(userIds, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyUserJobByUserIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyUserJobByUserIds>>
>;
export type DeletePartyUserJobByUserIdsMutationBody = string;
export type DeletePartyUserJobByUserIdsMutationError = unknown;

/**
 * @summary 删除在职党员信息
 */
export const useDeletePartyUserJobByUserIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyUserJobByUserIds>>,
    TError,
    { userIds: string; data: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyUserJobByUserIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取在职党员信息详细信息
 */
export const getPartyUserJobByUserId = (
  userId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象党员信息视图对象>(
    { url: `/party/user/job/${unref(userId)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyUserJobByUserIdQueryKey = (userId: MaybeRef<number>) =>
  ["party", "user", "job", userId] as const;

export const getGetPartyUserJobByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserJobByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserJobByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserJobByUserId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserJobByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserJobByUserId>>
  > = ({ signal }) => getPartyUserJobByUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPartyUserJobByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserJobByUserId>>
>;
export type GetPartyUserJobByUserIdQueryError = void;

/**
 * @summary 获取在职党员信息详细信息
 */
export const useGetPartyUserJobByUserId = <
  TData = Awaited<ReturnType<typeof getPartyUserJobByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserJobByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserJobByUserIdQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 转为入党申请人
 */
export const postPartyUserApplicationChangeToFormalById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/application/changeToFormal/${unref(id)}`,
      method: "post",
    },
    options,
  );
};

export const getPostPartyUserApplicationChangeToFormalByIdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserApplicationChangeToFormalById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserApplicationChangeToFormalById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserApplicationChangeToFormalById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return postPartyUserApplicationChangeToFormalById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserApplicationChangeToFormalByIdMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postPartyUserApplicationChangeToFormalById>>
  >;

export type PostPartyUserApplicationChangeToFormalByIdMutationError = unknown;

/**
 * @summary 转为入党申请人
 */
export const usePostPartyUserApplicationChangeToFormalById = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserApplicationChangeToFormalById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserApplicationChangeToFormalByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询入党申请书列表
 */
export const getPartyUserApplicationList = (
  params?: MaybeRef<GetPartyUserApplicationListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象党员发展视图对象>(
    { url: `/party/user/application/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyUserApplicationListQueryKey = (
  params?: MaybeRef<GetPartyUserApplicationListParams>,
) =>
  [
    "party",
    "user",
    "application",
    "list",
    ...(params ? [params] : []),
  ] as const;

export const getGetPartyUserApplicationListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserApplicationList>>,
  TError = void,
>(
  params?: MaybeRef<GetPartyUserApplicationListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserApplicationList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserApplicationList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserApplicationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserApplicationList>>
  > = ({ signal }) =>
    getPartyUserApplicationList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserApplicationListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserApplicationList>>
>;
export type GetPartyUserApplicationListQueryError = void;

/**
 * @summary 查询入党申请书列表
 */
export const useGetPartyUserApplicationList = <
  TData = Awaited<ReturnType<typeof getPartyUserApplicationList>>,
  TError = void,
>(
  params?: MaybeRef<GetPartyUserApplicationListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserApplicationList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserApplicationListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 入党申请书信息查询
 */
export const getPartyUserApplicationById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象党员信息视图对象>(
    { url: `/party/user/application/${unref(id)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyUserApplicationByIdQueryKey = (id: MaybeRef<number>) =>
  ["party", "user", "application", id] as const;

export const getGetPartyUserApplicationByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserApplicationById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserApplicationById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserApplicationById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserApplicationByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserApplicationById>>
  > = ({ signal }) => getPartyUserApplicationById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyUserApplicationByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserApplicationById>>
>;
export type GetPartyUserApplicationByIdQueryError = void;

/**
 * @summary 入党申请书信息查询
 */
export const useGetPartyUserApplicationById = <
  TData = Awaited<ReturnType<typeof getPartyUserApplicationById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserApplicationById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserApplicationByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 新增入党申请人
 */
export const postPartyUserDevelop = (
  入党申请请求: MaybeRef<入党申请请求>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/develop`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 入党申请请求,
    },
    options,
  );
};

export const getPostPartyUserDevelopMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelop>>,
    TError,
    { data: 入党申请请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserDevelop>>,
  TError,
  { data: 入党申请请求 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserDevelop>>,
    { data: 入党申请请求 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserDevelop(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserDevelopMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserDevelop>>
>;
export type PostPartyUserDevelopMutationBody = 入党申请请求;
export type PostPartyUserDevelopMutationError = unknown;

/**
 * @summary 新增入党申请人
 */
export const usePostPartyUserDevelop = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelop>>,
    TError,
    { data: 入党申请请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyUserDevelopMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 流程管理——积极分子保存
 */
export const postPartyUserDevelopActiveSave = (
  积极分子记录业务对象: MaybeRef<积极分子记录业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/develop/active/save`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 积极分子记录业务对象,
    },
    options,
  );
};

export const getPostPartyUserDevelopActiveSaveMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopActiveSave>>,
    TError,
    { data: 积极分子记录业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserDevelopActiveSave>>,
  TError,
  { data: 积极分子记录业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserDevelopActiveSave>>,
    { data: 积极分子记录业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserDevelopActiveSave(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserDevelopActiveSaveMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserDevelopActiveSave>>
>;
export type PostPartyUserDevelopActiveSaveMutationBody = 积极分子记录业务对象;
export type PostPartyUserDevelopActiveSaveMutationError = unknown;

/**
 * @summary 流程管理——积极分子保存
 */
export const usePostPartyUserDevelopActiveSave = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopActiveSave>>,
    TError,
    { data: 积极分子记录业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserDevelopActiveSaveMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 流程管理——积极分子查询
 */
export const getPartyUserDevelopActiveByUserId = (
  userId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象积极分子记录视图对象>(
    {
      url: `/party/user/develop/active/${unref(userId)}`,
      method: "get",
      signal,
    },
    options,
  );
};

export const getGetPartyUserDevelopActiveByUserIdQueryKey = (
  userId: MaybeRef<number>,
) => ["party", "user", "develop", "active", userId] as const;

export const getGetPartyUserDevelopActiveByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopActiveByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopActiveByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserDevelopActiveByUserId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserDevelopActiveByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserDevelopActiveByUserId>>
  > = ({ signal }) =>
    getPartyUserDevelopActiveByUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPartyUserDevelopActiveByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserDevelopActiveByUserId>>
>;
export type GetPartyUserDevelopActiveByUserIdQueryError = void;

/**
 * @summary 流程管理——积极分子查询
 */
export const useGetPartyUserDevelopActiveByUserId = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopActiveByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopActiveByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserDevelopActiveByUserIdQueryOptions(
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 流程管理——入党申请保存
 */
export const postPartyUserDevelopApplyEdit = (
  入党申请记录业务对象: MaybeRef<入党申请记录业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/develop/apply/edit`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 入党申请记录业务对象,
    },
    options,
  );
};

export const getPostPartyUserDevelopApplyEditMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopApplyEdit>>,
    TError,
    { data: 入党申请记录业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserDevelopApplyEdit>>,
  TError,
  { data: 入党申请记录业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserDevelopApplyEdit>>,
    { data: 入党申请记录业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserDevelopApplyEdit(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserDevelopApplyEditMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserDevelopApplyEdit>>
>;
export type PostPartyUserDevelopApplyEditMutationBody = 入党申请记录业务对象;
export type PostPartyUserDevelopApplyEditMutationError = unknown;

/**
 * @summary 流程管理——入党申请保存
 */
export const usePostPartyUserDevelopApplyEdit = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopApplyEdit>>,
    TError,
    { data: 入党申请记录业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserDevelopApplyEditMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 流程管理——入党申请查询
 */
export const getPartyUserDevelopApplyById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象入党申请记录视图对象>(
    { url: `/party/user/develop/apply/${unref(id)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyUserDevelopApplyByIdQueryKey = (id: MaybeRef<number>) =>
  ["party", "user", "develop", "apply", id] as const;

export const getGetPartyUserDevelopApplyByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopApplyById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopApplyById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserDevelopApplyById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserDevelopApplyByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserDevelopApplyById>>
  > = ({ signal }) => getPartyUserDevelopApplyById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyUserDevelopApplyByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserDevelopApplyById>>
>;
export type GetPartyUserDevelopApplyByIdQueryError = void;

/**
 * @summary 流程管理——入党申请查询
 */
export const useGetPartyUserDevelopApplyById = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopApplyById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopApplyById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserDevelopApplyByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 流程管理——基本信息修改
 */
export const postPartyUserDevelopBaseEdit = (
  党员发展基本信息业务对象: MaybeRef<党员发展基本信息业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/develop/base/edit`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 党员发展基本信息业务对象,
    },
    options,
  );
};

export const getPostPartyUserDevelopBaseEditMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopBaseEdit>>,
    TError,
    { data: 党员发展基本信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserDevelopBaseEdit>>,
  TError,
  { data: 党员发展基本信息业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserDevelopBaseEdit>>,
    { data: 党员发展基本信息业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserDevelopBaseEdit(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserDevelopBaseEditMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserDevelopBaseEdit>>
>;
export type PostPartyUserDevelopBaseEditMutationBody = 党员发展基本信息业务对象;
export type PostPartyUserDevelopBaseEditMutationError = unknown;

/**
 * @summary 流程管理——基本信息修改
 */
export const usePostPartyUserDevelopBaseEdit = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopBaseEdit>>,
    TError,
    { data: 党员发展基本信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserDevelopBaseEditMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 流程管理——基本信息查询
 */
export const getPartyUserDevelopBaseByUserId = (
  userId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象党员信息视图对象>(
    { url: `/party/user/develop/base/${unref(userId)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyUserDevelopBaseByUserIdQueryKey = (
  userId: MaybeRef<number>,
) => ["party", "user", "develop", "base", userId] as const;

export const getGetPartyUserDevelopBaseByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopBaseByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopBaseByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserDevelopBaseByUserId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserDevelopBaseByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserDevelopBaseByUserId>>
  > = ({ signal }) =>
    getPartyUserDevelopBaseByUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPartyUserDevelopBaseByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserDevelopBaseByUserId>>
>;
export type GetPartyUserDevelopBaseByUserIdQueryError = void;

/**
 * @summary 流程管理——基本信息查询
 */
export const useGetPartyUserDevelopBaseByUserId = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopBaseByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopBaseByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserDevelopBaseByUserIdQueryOptions(
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 流程管理——发展对象保存
 */
export const postPartyUserDevelopDevelopmentSave = (
  发展对象记录业务对象: MaybeRef<发展对象记录业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/develop/development/save`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 发展对象记录业务对象,
    },
    options,
  );
};

export const getPostPartyUserDevelopDevelopmentSaveMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopDevelopmentSave>>,
    TError,
    { data: 发展对象记录业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserDevelopDevelopmentSave>>,
  TError,
  { data: 发展对象记录业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserDevelopDevelopmentSave>>,
    { data: 发展对象记录业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserDevelopDevelopmentSave(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserDevelopDevelopmentSaveMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserDevelopDevelopmentSave>>
>;
export type PostPartyUserDevelopDevelopmentSaveMutationBody =
  发展对象记录业务对象;
export type PostPartyUserDevelopDevelopmentSaveMutationError = unknown;

/**
 * @summary 流程管理——发展对象保存
 */
export const usePostPartyUserDevelopDevelopmentSave = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopDevelopmentSave>>,
    TError,
    { data: 发展对象记录业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserDevelopDevelopmentSaveMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 流程管理——发展对象查询
 */
export const getPartyUserDevelopDevelopmentByUserId = (
  userId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象发展对象记录视图对象>(
    {
      url: `/party/user/develop/development/${unref(userId)}`,
      method: "get",
      signal,
    },
    options,
  );
};

export const getGetPartyUserDevelopDevelopmentByUserIdQueryKey = (
  userId: MaybeRef<number>,
) => ["party", "user", "develop", "development", userId] as const;

export const getGetPartyUserDevelopDevelopmentByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopDevelopmentByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopDevelopmentByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserDevelopDevelopmentByUserId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserDevelopDevelopmentByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserDevelopDevelopmentByUserId>>
  > = ({ signal }) =>
    getPartyUserDevelopDevelopmentByUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPartyUserDevelopDevelopmentByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserDevelopDevelopmentByUserId>>
>;
export type GetPartyUserDevelopDevelopmentByUserIdQueryError = void;

/**
 * @summary 流程管理——发展对象查询
 */
export const useGetPartyUserDevelopDevelopmentByUserId = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopDevelopmentByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopDevelopmentByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserDevelopDevelopmentByUserIdQueryOptions(
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 流程管理——正式党员保存
 */
export const postPartyUserDevelopFullSave = (
  正式党员档案记录业务对象: MaybeRef<正式党员档案记录业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/develop/full/save`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 正式党员档案记录业务对象,
    },
    options,
  );
};

export const getPostPartyUserDevelopFullSaveMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopFullSave>>,
    TError,
    { data: 正式党员档案记录业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserDevelopFullSave>>,
  TError,
  { data: 正式党员档案记录业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserDevelopFullSave>>,
    { data: 正式党员档案记录业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserDevelopFullSave(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserDevelopFullSaveMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserDevelopFullSave>>
>;
export type PostPartyUserDevelopFullSaveMutationBody = 正式党员档案记录业务对象;
export type PostPartyUserDevelopFullSaveMutationError = unknown;

/**
 * @summary 流程管理——正式党员保存
 */
export const usePostPartyUserDevelopFullSave = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopFullSave>>,
    TError,
    { data: 正式党员档案记录业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserDevelopFullSaveMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 流程管理——正式党员查询
 */
export const getPartyUserDevelopFullByUserId = (
  userId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象正式党员档案记录视图对象>(
    { url: `/party/user/develop/full/${unref(userId)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyUserDevelopFullByUserIdQueryKey = (
  userId: MaybeRef<number>,
) => ["party", "user", "develop", "full", userId] as const;

export const getGetPartyUserDevelopFullByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopFullByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopFullByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserDevelopFullByUserId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserDevelopFullByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserDevelopFullByUserId>>
  > = ({ signal }) =>
    getPartyUserDevelopFullByUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPartyUserDevelopFullByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserDevelopFullByUserId>>
>;
export type GetPartyUserDevelopFullByUserIdQueryError = void;

/**
 * @summary 流程管理——正式党员查询
 */
export const useGetPartyUserDevelopFullByUserId = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopFullByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopFullByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserDevelopFullByUserIdQueryOptions(
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询党员发展列表
 */
export const getPartyUserDevelopList = (
  params?: MaybeRef<GetPartyUserDevelopListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象党员发展视图对象>(
    { url: `/party/user/develop/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyUserDevelopListQueryKey = (
  params?: MaybeRef<GetPartyUserDevelopListParams>,
) => ["party", "user", "develop", "list", ...(params ? [params] : [])] as const;

export const getGetPartyUserDevelopListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopList>>,
  TError = void,
>(
  params?: MaybeRef<GetPartyUserDevelopListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserDevelopList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserDevelopListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserDevelopList>>
  > = ({ signal }) => getPartyUserDevelopList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserDevelopListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserDevelopList>>
>;
export type GetPartyUserDevelopListQueryError = void;

/**
 * @summary 查询党员发展列表
 */
export const useGetPartyUserDevelopList = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopList>>,
  TError = void,
>(
  params?: MaybeRef<GetPartyUserDevelopListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserDevelopListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 流程管理——预备党员保存
 */
export const postPartyUserDevelopProbationarySave = (
  预备党员记录业务对象: MaybeRef<预备党员记录业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/develop/probationary/save`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 预备党员记录业务对象,
    },
    options,
  );
};

export const getPostPartyUserDevelopProbationarySaveMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopProbationarySave>>,
    TError,
    { data: 预备党员记录业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserDevelopProbationarySave>>,
  TError,
  { data: 预备党员记录业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserDevelopProbationarySave>>,
    { data: 预备党员记录业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserDevelopProbationarySave(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserDevelopProbationarySaveMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserDevelopProbationarySave>>
>;
export type PostPartyUserDevelopProbationarySaveMutationBody =
  预备党员记录业务对象;
export type PostPartyUserDevelopProbationarySaveMutationError = unknown;

/**
 * @summary 流程管理——预备党员保存
 */
export const usePostPartyUserDevelopProbationarySave = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserDevelopProbationarySave>>,
    TError,
    { data: 预备党员记录业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserDevelopProbationarySaveMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 流程管理——预备党员查询
 */
export const getPartyUserDevelopProbationaryByUserId = (
  userId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象预备党员记录视图对象>(
    {
      url: `/party/user/develop/probationary/${unref(userId)}`,
      method: "get",
      signal,
    },
    options,
  );
};

export const getGetPartyUserDevelopProbationaryByUserIdQueryKey = (
  userId: MaybeRef<number>,
) => ["party", "user", "develop", "probationary", userId] as const;

export const getGetPartyUserDevelopProbationaryByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopProbationaryByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopProbationaryByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserDevelopProbationaryByUserId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserDevelopProbationaryByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserDevelopProbationaryByUserId>>
  > = ({ signal }) =>
    getPartyUserDevelopProbationaryByUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPartyUserDevelopProbationaryByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserDevelopProbationaryByUserId>>
>;
export type GetPartyUserDevelopProbationaryByUserIdQueryError = void;

/**
 * @summary 流程管理——预备党员查询
 */
export const useGetPartyUserDevelopProbationaryByUserId = <
  TData = Awaited<ReturnType<typeof getPartyUserDevelopProbationaryByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserDevelopProbationaryByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserDevelopProbationaryByUserIdQueryOptions(
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除党员发展信息
 */
export const deletePartyUserDevelopByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/user/develop/${unref(ids)}`, method: "delete" },
    options,
  );
};

export const getDeletePartyUserDevelopByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyUserDevelopByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyUserDevelopByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyUserDevelopByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyUserDevelopByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyUserDevelopByIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyUserDevelopByIds>>
>;

export type DeletePartyUserDevelopByIdsMutationError = unknown;

/**
 * @summary 删除党员发展信息
 */
export const useDeletePartyUserDevelopByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyUserDevelopByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyUserDevelopByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改活动
 */
export const putActivity = (
  活动业务对象: MaybeRef<活动业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/activity`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 活动业务对象,
    },
    options,
  );
};

export const getPutActivityMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putActivity>>,
    TError,
    { data: 活动业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putActivity>>,
  TError,
  { data: 活动业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putActivity>>,
    { data: 活动业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putActivity(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutActivityMutationResult = NonNullable<
  Awaited<ReturnType<typeof putActivity>>
>;
export type PutActivityMutationBody = 活动业务对象;
export type PutActivityMutationError = unknown;

/**
 * @summary 修改活动
 */
export const usePutActivity = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putActivity>>,
    TError,
    { data: 活动业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutActivityMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增活动
 */
export const postActivity = (
  活动业务对象: MaybeRef<活动业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/activity`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 活动业务对象,
    },
    options,
  );
};

export const getPostActivityMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postActivity>>,
    TError,
    { data: 活动业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postActivity>>,
  TError,
  { data: 活动业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postActivity>>,
    { data: 活动业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postActivity(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostActivityMutationResult = NonNullable<
  Awaited<ReturnType<typeof postActivity>>
>;
export type PostActivityMutationBody = 活动业务对象;
export type PostActivityMutationError = unknown;

/**
 * @summary 新增活动
 */
export const usePostActivity = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postActivity>>,
    TError,
    { data: 活动业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostActivityMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询活动列表
 */
export const getActivityList = (
  params: MaybeRef<GetActivityListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象活动视图对象>(
    { url: `/activity/list`, method: "get", params, signal },
    options,
  );
};

export const getGetActivityListQueryKey = (
  params: MaybeRef<GetActivityListParams>,
) => ["activity", "list", ...(params ? [params] : [])] as const;

export const getGetActivityListQueryOptions = <
  TData = Awaited<ReturnType<typeof getActivityList>>,
  TError = void,
>(
  params: MaybeRef<GetActivityListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getActivityList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getActivityList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetActivityListQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivityList>>> = ({
    signal,
  }) => getActivityList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetActivityListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActivityList>>
>;
export type GetActivityListQueryError = void;

/**
 * @summary 查询活动列表
 */
export const useGetActivityList = <
  TData = Awaited<ReturnType<typeof getActivityList>>,
  TError = void,
>(
  params: MaybeRef<GetActivityListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getActivityList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetActivityListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除活动
 */
export const deleteActivityByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/activity/${unref(ids)}`, method: "delete" },
    options,
  );
};

export const getDeleteActivityByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteActivityByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteActivityByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteActivityByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deleteActivityByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteActivityByIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteActivityByIds>>
>;

export type DeleteActivityByIdsMutationError = unknown;

/**
 * @summary 删除活动
 */
export const useDeleteActivityByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteActivityByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteActivityByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取活动详细信息
 */
export const getActivityById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象活动视图对象>(
    { url: `/activity/${unref(id)}`, method: "get", signal },
    options,
  );
};

export const getGetActivityByIdQueryKey = (id: MaybeRef<number>) =>
  ["activity", id] as const;

export const getGetActivityByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getActivityById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getActivityById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getActivityById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetActivityByIdQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivityById>>> = ({
    signal,
  }) => getActivityById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetActivityByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getActivityById>>
>;
export type GetActivityByIdQueryError = void;

/**
 * @summary 获取活动详细信息
 */
export const useGetActivityById = <
  TData = Awaited<ReturnType<typeof getActivityById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getActivityById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetActivityByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 新增民主评议
 */
export const postPartyDemocraticDeliberationAdd = (
  民主评议业务对象: MaybeRef<民主评议业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/democraticDeliberation/add`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 民主评议业务对象,
    },
    options,
  );
};

export const getPostPartyDemocraticDeliberationAddMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationAdd>>,
    TError,
    { data: 民主评议业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyDemocraticDeliberationAdd>>,
  TError,
  { data: 民主评议业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationAdd>>,
    { data: 民主评议业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyDemocraticDeliberationAdd(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyDemocraticDeliberationAddMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyDemocraticDeliberationAdd>>
>;
export type PostPartyDemocraticDeliberationAddMutationBody = 民主评议业务对象;
export type PostPartyDemocraticDeliberationAddMutationError = unknown;

/**
 * @summary 新增民主评议
 */
export const usePostPartyDemocraticDeliberationAdd = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationAdd>>,
    TError,
    { data: 民主评议业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyDemocraticDeliberationAddMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 民主评议审核
 */
export const postPartyDemocraticDeliberationAudit = (
  民主评议业务对象: MaybeRef<民主评议业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/democraticDeliberation/audit`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 民主评议业务对象,
    },
    options,
  );
};

export const getPostPartyDemocraticDeliberationAuditMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationAudit>>,
    TError,
    { data: 民主评议业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyDemocraticDeliberationAudit>>,
  TError,
  { data: 民主评议业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationAudit>>,
    { data: 民主评议业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyDemocraticDeliberationAudit(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyDemocraticDeliberationAuditMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyDemocraticDeliberationAudit>>
>;
export type PostPartyDemocraticDeliberationAuditMutationBody = 民主评议业务对象;
export type PostPartyDemocraticDeliberationAuditMutationError = unknown;

/**
 * @summary 民主评议审核
 */
export const usePostPartyDemocraticDeliberationAudit = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationAudit>>,
    TError,
    { data: 民主评议业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyDemocraticDeliberationAuditMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改民主评议
 */
export const postPartyDemocraticDeliberationEdit = (
  民主评议业务对象: MaybeRef<民主评议业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/democraticDeliberation/edit`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 民主评议业务对象,
    },
    options,
  );
};

export const getPostPartyDemocraticDeliberationEditMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationEdit>>,
    TError,
    { data: 民主评议业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyDemocraticDeliberationEdit>>,
  TError,
  { data: 民主评议业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationEdit>>,
    { data: 民主评议业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyDemocraticDeliberationEdit(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyDemocraticDeliberationEditMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyDemocraticDeliberationEdit>>
>;
export type PostPartyDemocraticDeliberationEditMutationBody = 民主评议业务对象;
export type PostPartyDemocraticDeliberationEditMutationError = unknown;

/**
 * @summary 修改民主评议
 */
export const usePostPartyDemocraticDeliberationEdit = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationEdit>>,
    TError,
    { data: 民主评议业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyDemocraticDeliberationEditMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导出评定结果
 */
export const postPartyDemocraticDeliberationExport = (
  params: MaybeRef<PostPartyDemocraticDeliberationExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/party/democraticDeliberation/export`, method: "post", params },
    options,
  );
};

export const getPostPartyDemocraticDeliberationExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationExport>>,
    TError,
    { params: PostPartyDemocraticDeliberationExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyDemocraticDeliberationExport>>,
  TError,
  { params: PostPartyDemocraticDeliberationExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationExport>>,
    { params: PostPartyDemocraticDeliberationExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postPartyDemocraticDeliberationExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyDemocraticDeliberationExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyDemocraticDeliberationExport>>
>;

export type PostPartyDemocraticDeliberationExportMutationError = unknown;

/**
 * @summary 导出评定结果
 */
export const usePostPartyDemocraticDeliberationExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationExport>>,
    TError,
    { params: PostPartyDemocraticDeliberationExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyDemocraticDeliberationExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取评定结果中各种人数
 */
export const getPartyDemocraticDeliberationGetQuantityByMeetingId = (
  meetingId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象评议结果视图对象>(
    {
      url: `/party/democraticDeliberation/getQuantity/${unref(meetingId)}`,
      method: "get",
      signal,
    },
    options,
  );
};

export const getGetPartyDemocraticDeliberationGetQuantityByMeetingIdQueryKey = (
  meetingId: MaybeRef<number>,
) => ["party", "democraticDeliberation", "getQuantity", meetingId] as const;

export const getGetPartyDemocraticDeliberationGetQuantityByMeetingIdQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof getPartyDemocraticDeliberationGetQuantityByMeetingId>
    >,
    TError = void,
  >(
    meetingId: MaybeRef<number>,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            typeof getPartyDemocraticDeliberationGetQuantityByMeetingId
          >
        >,
        TError,
        TData
      >;
      request?: SecondParameter<typeof customInstance>;
    },
  ): UseQueryOptions<
    Awaited<
      ReturnType<typeof getPartyDemocraticDeliberationGetQuantityByMeetingId>
    >,
    TError,
    TData
  > => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      getGetPartyDemocraticDeliberationGetQuantityByMeetingIdQueryKey(
        meetingId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof getPartyDemocraticDeliberationGetQuantityByMeetingId>
      >
    > = ({ signal }) =>
      getPartyDemocraticDeliberationGetQuantityByMeetingId(
        meetingId,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, enabled: !!meetingId, ...queryOptions };
  };

export type GetPartyDemocraticDeliberationGetQuantityByMeetingIdQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof getPartyDemocraticDeliberationGetQuantityByMeetingId>
    >
  >;
export type GetPartyDemocraticDeliberationGetQuantityByMeetingIdQueryError =
  void;

/**
 * @summary 获取评定结果中各种人数
 */
export const useGetPartyDemocraticDeliberationGetQuantityByMeetingId = <
  TData = Awaited<
    ReturnType<typeof getPartyDemocraticDeliberationGetQuantityByMeetingId>
  >,
  TError = void,
>(
  meetingId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<typeof getPartyDemocraticDeliberationGetQuantityByMeetingId>
      >,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPartyDemocraticDeliberationGetQuantityByMeetingIdQueryOptions(
      meetingId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询民主评议列表
 */
export const getPartyDemocraticDeliberationList = (
  params: MaybeRef<GetPartyDemocraticDeliberationListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象民主评议视图对象>(
    {
      url: `/party/democraticDeliberation/list`,
      method: "get",
      params,
      signal,
    },
    options,
  );
};

export const getGetPartyDemocraticDeliberationListQueryKey = (
  params: MaybeRef<GetPartyDemocraticDeliberationListParams>,
) =>
  [
    "party",
    "democraticDeliberation",
    "list",
    ...(params ? [params] : []),
  ] as const;

export const getGetPartyDemocraticDeliberationListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyDemocraticDeliberationList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyDemocraticDeliberationListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyDemocraticDeliberationList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyDemocraticDeliberationList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyDemocraticDeliberationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyDemocraticDeliberationList>>
  > = ({ signal }) =>
    getPartyDemocraticDeliberationList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyDemocraticDeliberationListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyDemocraticDeliberationList>>
>;
export type GetPartyDemocraticDeliberationListQueryError = void;

/**
 * @summary 查询民主评议列表
 */
export const useGetPartyDemocraticDeliberationList = <
  TData = Awaited<ReturnType<typeof getPartyDemocraticDeliberationList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyDemocraticDeliberationListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyDemocraticDeliberationList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyDemocraticDeliberationListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除互评
 */
export const deletePartyDemocraticDeliberationMutualByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/democraticDeliberation/mutual/${unref(ids)}`,
      method: "delete",
    },
    options,
  );
};

export const getDeletePartyDemocraticDeliberationMutualByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationMutualByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyDemocraticDeliberationMutualByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationMutualByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyDemocraticDeliberationMutualByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyDemocraticDeliberationMutualByIdsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationMutualByIds>>
  >;

export type DeletePartyDemocraticDeliberationMutualByIdsMutationError = unknown;

/**
 * @summary 删除互评
 */
export const useDeletePartyDemocraticDeliberationMutualByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationMutualByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyDemocraticDeliberationMutualByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询互评列表
 */
export const getPartyDemocraticDeliberationMutualList = (
  params: MaybeRef<GetPartyDemocraticDeliberationMutualListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象互评记录视图对象>(
    {
      url: `/party/democraticDeliberation/mutualList`,
      method: "get",
      params,
      signal,
    },
    options,
  );
};

export const getGetPartyDemocraticDeliberationMutualListQueryKey = (
  params: MaybeRef<GetPartyDemocraticDeliberationMutualListParams>,
) =>
  [
    "party",
    "democraticDeliberation",
    "mutualList",
    ...(params ? [params] : []),
  ] as const;

export const getGetPartyDemocraticDeliberationMutualListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyDemocraticDeliberationMutualList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyDemocraticDeliberationMutualListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyDemocraticDeliberationMutualList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyDemocraticDeliberationMutualList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyDemocraticDeliberationMutualListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyDemocraticDeliberationMutualList>>
  > = ({ signal }) =>
    getPartyDemocraticDeliberationMutualList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyDemocraticDeliberationMutualListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyDemocraticDeliberationMutualList>>
>;
export type GetPartyDemocraticDeliberationMutualListQueryError = void;

/**
 * @summary 查询互评列表
 */
export const useGetPartyDemocraticDeliberationMutualList = <
  TData = Awaited<ReturnType<typeof getPartyDemocraticDeliberationMutualList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyDemocraticDeliberationMutualListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyDemocraticDeliberationMutualList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyDemocraticDeliberationMutualListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 组织评定
 */
export const postPartyDemocraticDeliberationResultAssessment = (
  评议结果业务对象: MaybeRef<评议结果业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/democraticDeliberation/result/assessment`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 评议结果业务对象,
    },
    options,
  );
};

export const getPostPartyDemocraticDeliberationResultAssessmentMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postPartyDemocraticDeliberationResultAssessment>
      >,
      TError,
      { data: 评议结果业务对象 },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationResultAssessment>>,
    TError,
    { data: 评议结果业务对象 },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postPartyDemocraticDeliberationResultAssessment>
      >,
      { data: 评议结果业务对象 }
    > = (props) => {
      const { data } = props ?? {};

      return postPartyDemocraticDeliberationResultAssessment(
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostPartyDemocraticDeliberationResultAssessmentMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationResultAssessment>>
  >;
export type PostPartyDemocraticDeliberationResultAssessmentMutationBody =
  评议结果业务对象;
export type PostPartyDemocraticDeliberationResultAssessmentMutationError =
  unknown;

/**
 * @summary 组织评定
 */
export const usePostPartyDemocraticDeliberationResultAssessment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyDemocraticDeliberationResultAssessment>>,
    TError,
    { data: 评议结果业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyDemocraticDeliberationResultAssessmentMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 组织评定（批量）
 */
export const postPartyDemocraticDeliberationResultAssessmentBatch = (
  评议结果业务对象: MaybeRef<评议结果业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/democraticDeliberation/result/assessmentBatch`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 评议结果业务对象,
    },
    options,
  );
};

export const getPostPartyDemocraticDeliberationResultAssessmentBatchMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof postPartyDemocraticDeliberationResultAssessmentBatch>
      >,
      TError,
      { data: 评议结果业务对象 },
      TContext
    >;
    request?: SecondParameter<typeof customInstance>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof postPartyDemocraticDeliberationResultAssessmentBatch>
    >,
    TError,
    { data: 评议结果业务对象 },
    TContext
  > => {
    const { mutation: mutationOptions, request: requestOptions } =
      options ?? {};

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof postPartyDemocraticDeliberationResultAssessmentBatch>
      >,
      { data: 评议结果业务对象 }
    > = (props) => {
      const { data } = props ?? {};

      return postPartyDemocraticDeliberationResultAssessmentBatch(
        data,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type PostPartyDemocraticDeliberationResultAssessmentBatchMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof postPartyDemocraticDeliberationResultAssessmentBatch>
    >
  >;
export type PostPartyDemocraticDeliberationResultAssessmentBatchMutationBody =
  评议结果业务对象;
export type PostPartyDemocraticDeliberationResultAssessmentBatchMutationError =
  unknown;

/**
 * @summary 组织评定（批量）
 */
export const usePostPartyDemocraticDeliberationResultAssessmentBatch = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof postPartyDemocraticDeliberationResultAssessmentBatch>
    >,
    TError,
    { data: 评议结果业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyDemocraticDeliberationResultAssessmentBatchMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};

/**
 * @summary 删除评定结果
 */
export const deletePartyDemocraticDeliberationResultByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/democraticDeliberation/result/${unref(ids)}`,
      method: "delete",
    },
    options,
  );
};

export const getDeletePartyDemocraticDeliberationResultByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationResultByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyDemocraticDeliberationResultByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationResultByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyDemocraticDeliberationResultByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyDemocraticDeliberationResultByIdsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationResultByIds>>
  >;

export type DeletePartyDemocraticDeliberationResultByIdsMutationError = unknown;

/**
 * @summary 删除评定结果
 */
export const useDeletePartyDemocraticDeliberationResultByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationResultByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyDemocraticDeliberationResultByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询评定结果列表
 */
export const getPartyDemocraticDeliberationResultList = (
  params: MaybeRef<GetPartyDemocraticDeliberationResultListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象评议结果视图对象>(
    {
      url: `/party/democraticDeliberation/resultList`,
      method: "get",
      params,
      signal,
    },
    options,
  );
};

export const getGetPartyDemocraticDeliberationResultListQueryKey = (
  params: MaybeRef<GetPartyDemocraticDeliberationResultListParams>,
) =>
  [
    "party",
    "democraticDeliberation",
    "resultList",
    ...(params ? [params] : []),
  ] as const;

export const getGetPartyDemocraticDeliberationResultListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyDemocraticDeliberationResultList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyDemocraticDeliberationResultListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyDemocraticDeliberationResultList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyDemocraticDeliberationResultList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyDemocraticDeliberationResultListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyDemocraticDeliberationResultList>>
  > = ({ signal }) =>
    getPartyDemocraticDeliberationResultList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyDemocraticDeliberationResultListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyDemocraticDeliberationResultList>>
>;
export type GetPartyDemocraticDeliberationResultListQueryError = void;

/**
 * @summary 查询评定结果列表
 */
export const useGetPartyDemocraticDeliberationResultList = <
  TData = Awaited<ReturnType<typeof getPartyDemocraticDeliberationResultList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyDemocraticDeliberationResultListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyDemocraticDeliberationResultList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyDemocraticDeliberationResultListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除民主评议
 */
export const deletePartyDemocraticDeliberationByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/democraticDeliberation/${unref(ids)}`, method: "delete" },
    options,
  );
};

export const getDeletePartyDemocraticDeliberationByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyDemocraticDeliberationByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyDemocraticDeliberationByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyDemocraticDeliberationByIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyDemocraticDeliberationByIds>>
>;

export type DeletePartyDemocraticDeliberationByIdsMutationError = unknown;

/**
 * @summary 删除民主评议
 */
export const useDeletePartyDemocraticDeliberationByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyDemocraticDeliberationByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyDemocraticDeliberationByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取民主评议详细信息
 */
export const getPartyDemocraticDeliberationById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象民主评议视图对象>(
    {
      url: `/party/democraticDeliberation/${unref(id)}`,
      method: "get",
      signal,
    },
    options,
  );
};

export const getGetPartyDemocraticDeliberationByIdQueryKey = (
  id: MaybeRef<number>,
) => ["party", "democraticDeliberation", id] as const;

export const getGetPartyDemocraticDeliberationByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyDemocraticDeliberationById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyDemocraticDeliberationById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyDemocraticDeliberationById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyDemocraticDeliberationByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyDemocraticDeliberationById>>
  > = ({ signal }) =>
    getPartyDemocraticDeliberationById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyDemocraticDeliberationByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyDemocraticDeliberationById>>
>;
export type GetPartyDemocraticDeliberationByIdQueryError = void;

/**
 * @summary 获取民主评议详细信息
 */
export const useGetPartyDemocraticDeliberationById = <
  TData = Awaited<ReturnType<typeof getPartyDemocraticDeliberationById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyDemocraticDeliberationById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyDemocraticDeliberationByIdQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询信息库列表
 */
export const getPartyInfoBaseList = (
  params: MaybeRef<GetPartyInfoBaseListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象信息库视图对象>(
    { url: `/party/infoBase/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyInfoBaseListQueryKey = (
  params: MaybeRef<GetPartyInfoBaseListParams>,
) => ["party", "infoBase", "list", ...(params ? [params] : [])] as const;

export const getGetPartyInfoBaseListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyInfoBaseList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyInfoBaseListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoBaseList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyInfoBaseList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyInfoBaseListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyInfoBaseList>>
  > = ({ signal }) => getPartyInfoBaseList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyInfoBaseListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyInfoBaseList>>
>;
export type GetPartyInfoBaseListQueryError = void;

/**
 * @summary 查询信息库列表
 */
export const useGetPartyInfoBaseList = <
  TData = Awaited<ReturnType<typeof getPartyInfoBaseList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyInfoBaseListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoBaseList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyInfoBaseListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 推送本地库
 */
export const postPartyInfoBasePushLocal = (
  推送本地库业务对象: MaybeRef<推送本地库业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/infoBase/pushLocal`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 推送本地库业务对象,
    },
    options,
  );
};

export const getPostPartyInfoBasePushLocalMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyInfoBasePushLocal>>,
    TError,
    { data: 推送本地库业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyInfoBasePushLocal>>,
  TError,
  { data: 推送本地库业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyInfoBasePushLocal>>,
    { data: 推送本地库业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyInfoBasePushLocal(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyInfoBasePushLocalMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyInfoBasePushLocal>>
>;
export type PostPartyInfoBasePushLocalMutationBody = 推送本地库业务对象;
export type PostPartyInfoBasePushLocalMutationError = unknown;

/**
 * @summary 推送本地库
 */
export const usePostPartyInfoBasePushLocal = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyInfoBasePushLocal>>,
    TError,
    { data: 推送本地库业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyInfoBasePushLocalMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 删除信息库
 */
export const deletePartyInfoBaseByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/infoBase/${unref(ids)}`, method: "delete" },
    options,
  );
};

export const getDeletePartyInfoBaseByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyInfoBaseByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyInfoBaseByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyInfoBaseByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyInfoBaseByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyInfoBaseByIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyInfoBaseByIds>>
>;

export type DeletePartyInfoBaseByIdsMutationError = unknown;

/**
 * @summary 删除信息库
 */
export const useDeletePartyInfoBaseByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyInfoBaseByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeletePartyInfoBaseByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改信息列
 */
export const putPartyInfoList = (
  信息列业务对象: MaybeRef<信息列业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/infoList`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 信息列业务对象,
    },
    options,
  );
};

export const getPutPartyInfoListMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyInfoList>>,
    TError,
    { data: 信息列业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPartyInfoList>>,
  TError,
  { data: 信息列业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPartyInfoList>>,
    { data: 信息列业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putPartyInfoList(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPartyInfoListMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPartyInfoList>>
>;
export type PutPartyInfoListMutationBody = 信息列业务对象;
export type PutPartyInfoListMutationError = unknown;

/**
 * @summary 修改信息列
 */
export const usePutPartyInfoList = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyInfoList>>,
    TError,
    { data: 信息列业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutPartyInfoListMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增信息列
 */
export const postPartyInfoList = (
  信息列业务对象: MaybeRef<信息列业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/infoList`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 信息列业务对象,
    },
    options,
  );
};

export const getPostPartyInfoListMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyInfoList>>,
    TError,
    { data: 信息列业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyInfoList>>,
  TError,
  { data: 信息列业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyInfoList>>,
    { data: 信息列业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyInfoList(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyInfoListMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyInfoList>>
>;
export type PostPartyInfoListMutationBody = 信息列业务对象;
export type PostPartyInfoListMutationError = unknown;

/**
 * @summary 新增信息列
 */
export const usePostPartyInfoList = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyInfoList>>,
    TError,
    { data: 信息列业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyInfoListMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询信息列列表
 */
export const getPartyInfoListList = (
  params: MaybeRef<GetPartyInfoListListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象信息列视图对象>(
    { url: `/party/infoList/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyInfoListListQueryKey = (
  params: MaybeRef<GetPartyInfoListListParams>,
) => ["party", "infoList", "list", ...(params ? [params] : [])] as const;

export const getGetPartyInfoListListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyInfoListList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyInfoListListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoListList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyInfoListList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyInfoListListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyInfoListList>>
  > = ({ signal }) => getPartyInfoListList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyInfoListListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyInfoListList>>
>;
export type GetPartyInfoListListQueryError = void;

/**
 * @summary 查询信息列列表
 */
export const useGetPartyInfoListList = <
  TData = Awaited<ReturnType<typeof getPartyInfoListList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyInfoListListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoListList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyInfoListListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除信息列
 */
export const deletePartyInfoListByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/infoList/${unref(ids)}`, method: "delete" },
    options,
  );
};

export const getDeletePartyInfoListByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyInfoListByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyInfoListByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyInfoListByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyInfoListByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyInfoListByIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyInfoListByIds>>
>;

export type DeletePartyInfoListByIdsMutationError = unknown;

/**
 * @summary 删除信息列
 */
export const useDeletePartyInfoListByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyInfoListByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeletePartyInfoListByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取信息列详细信息
 */
export const getPartyInfoListById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象信息列视图对象>(
    { url: `/party/infoList/${unref(id)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyInfoListByIdQueryKey = (id: MaybeRef<number>) =>
  ["party", "infoList", id] as const;

export const getGetPartyInfoListByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyInfoListById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoListById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyInfoListById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyInfoListByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyInfoListById>>
  > = ({ signal }) => getPartyInfoListById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyInfoListByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyInfoListById>>
>;
export type GetPartyInfoListByIdQueryError = void;

/**
 * @summary 获取信息列详细信息
 */
export const useGetPartyInfoListById = <
  TData = Awaited<ReturnType<typeof getPartyInfoListById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoListById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyInfoListByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 修改信息栏目
 */
export const putPartyInfoSection = (
  信息栏目业务对象: MaybeRef<信息栏目业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/infoSection`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 信息栏目业务对象,
    },
    options,
  );
};

export const getPutPartyInfoSectionMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyInfoSection>>,
    TError,
    { data: 信息栏目业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPartyInfoSection>>,
  TError,
  { data: 信息栏目业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPartyInfoSection>>,
    { data: 信息栏目业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putPartyInfoSection(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPartyInfoSectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPartyInfoSection>>
>;
export type PutPartyInfoSectionMutationBody = 信息栏目业务对象;
export type PutPartyInfoSectionMutationError = unknown;

/**
 * @summary 修改信息栏目
 */
export const usePutPartyInfoSection = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyInfoSection>>,
    TError,
    { data: 信息栏目业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutPartyInfoSectionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增信息栏目
 */
export const postPartyInfoSection = (
  信息栏目业务对象: MaybeRef<信息栏目业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/infoSection`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 信息栏目业务对象,
    },
    options,
  );
};

export const getPostPartyInfoSectionMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyInfoSection>>,
    TError,
    { data: 信息栏目业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyInfoSection>>,
  TError,
  { data: 信息栏目业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyInfoSection>>,
    { data: 信息栏目业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyInfoSection(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyInfoSectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyInfoSection>>
>;
export type PostPartyInfoSectionMutationBody = 信息栏目业务对象;
export type PostPartyInfoSectionMutationError = unknown;

/**
 * @summary 新增信息栏目
 */
export const usePostPartyInfoSection = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyInfoSection>>,
    TError,
    { data: 信息栏目业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyInfoSectionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 判断栏目名称是否已存在
 */
export const getPartyInfoSectionCheckCategoryNameUnique = (
  params: MaybeRef<GetPartyInfoSectionCheckCategoryNameUniqueParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象Boolean>(
    {
      url: `/party/infoSection/checkCategoryNameUnique`,
      method: "get",
      params,
      signal,
    },
    options,
  );
};

export const getGetPartyInfoSectionCheckCategoryNameUniqueQueryKey = (
  params: MaybeRef<GetPartyInfoSectionCheckCategoryNameUniqueParams>,
) =>
  [
    "party",
    "infoSection",
    "checkCategoryNameUnique",
    ...(params ? [params] : []),
  ] as const;

export const getGetPartyInfoSectionCheckCategoryNameUniqueQueryOptions = <
  TData = Awaited<
    ReturnType<typeof getPartyInfoSectionCheckCategoryNameUnique>
  >,
  TError = void,
>(
  params: MaybeRef<GetPartyInfoSectionCheckCategoryNameUniqueParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoSectionCheckCategoryNameUnique>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyInfoSectionCheckCategoryNameUnique>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    getGetPartyInfoSectionCheckCategoryNameUniqueQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyInfoSectionCheckCategoryNameUnique>>
  > = ({ signal }) =>
    getPartyInfoSectionCheckCategoryNameUnique(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyInfoSectionCheckCategoryNameUniqueQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyInfoSectionCheckCategoryNameUnique>>
>;
export type GetPartyInfoSectionCheckCategoryNameUniqueQueryError = void;

/**
 * @summary 判断栏目名称是否已存在
 */
export const useGetPartyInfoSectionCheckCategoryNameUnique = <
  TData = Awaited<
    ReturnType<typeof getPartyInfoSectionCheckCategoryNameUnique>
  >,
  TError = void,
>(
  params: MaybeRef<GetPartyInfoSectionCheckCategoryNameUniqueParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoSectionCheckCategoryNameUnique>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPartyInfoSectionCheckCategoryNameUniqueQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 获取信息栏目下拉树列表
 */
export const getPartyInfoSectionTreeselect = (
  params: MaybeRef<GetPartyInfoSectionTreeselectParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象ListTreeLong>(
    { url: `/party/infoSection/treeselect`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyInfoSectionTreeselectQueryKey = (
  params: MaybeRef<GetPartyInfoSectionTreeselectParams>,
) =>
  ["party", "infoSection", "treeselect", ...(params ? [params] : [])] as const;

export const getGetPartyInfoSectionTreeselectQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyInfoSectionTreeselect>>,
  TError = void,
>(
  params: MaybeRef<GetPartyInfoSectionTreeselectParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoSectionTreeselect>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyInfoSectionTreeselect>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyInfoSectionTreeselectQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyInfoSectionTreeselect>>
  > = ({ signal }) =>
    getPartyInfoSectionTreeselect(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyInfoSectionTreeselectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyInfoSectionTreeselect>>
>;
export type GetPartyInfoSectionTreeselectQueryError = void;

/**
 * @summary 获取信息栏目下拉树列表
 */
export const useGetPartyInfoSectionTreeselect = <
  TData = Awaited<ReturnType<typeof getPartyInfoSectionTreeselect>>,
  TError = void,
>(
  params: MaybeRef<GetPartyInfoSectionTreeselectParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoSectionTreeselect>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyInfoSectionTreeselectQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 获取信息栏目详细信息
 */
export const getPartyInfoSectionById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象信息栏目视图对象>(
    { url: `/party/infoSection/${unref(id)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyInfoSectionByIdQueryKey = (id: MaybeRef<number>) =>
  ["party", "infoSection", id] as const;

export const getGetPartyInfoSectionByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyInfoSectionById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoSectionById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyInfoSectionById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyInfoSectionByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyInfoSectionById>>
  > = ({ signal }) => getPartyInfoSectionById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyInfoSectionByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyInfoSectionById>>
>;
export type GetPartyInfoSectionByIdQueryError = void;

/**
 * @summary 获取信息栏目详细信息
 */
export const useGetPartyInfoSectionById = <
  TData = Awaited<ReturnType<typeof getPartyInfoSectionById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyInfoSectionById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyInfoSectionByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除信息栏目
 */
export const deletePartyInfoSectionById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/infoSection/${unref(id)}`, method: "delete" },
    options,
  );
};

export const getDeletePartyInfoSectionByIdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyInfoSectionById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyInfoSectionById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyInfoSectionById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return deletePartyInfoSectionById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyInfoSectionByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyInfoSectionById>>
>;

export type DeletePartyInfoSectionByIdMutationError = unknown;

/**
 * @summary 删除信息栏目
 */
export const useDeletePartyInfoSectionById = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyInfoSectionById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeletePartyInfoSectionByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改领导班子
 */
export const putPartyLeadership = (
  领导班子业务对象: MaybeRef<领导班子业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/leadership`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 领导班子业务对象,
    },
    options,
  );
};

export const getPutPartyLeadershipMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyLeadership>>,
    TError,
    { data: 领导班子业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPartyLeadership>>,
  TError,
  { data: 领导班子业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPartyLeadership>>,
    { data: 领导班子业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putPartyLeadership(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPartyLeadershipMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPartyLeadership>>
>;
export type PutPartyLeadershipMutationBody = 领导班子业务对象;
export type PutPartyLeadershipMutationError = unknown;

/**
 * @summary 修改领导班子
 */
export const usePutPartyLeadership = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyLeadership>>,
    TError,
    { data: 领导班子业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutPartyLeadershipMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增领导班子
 */
export const postPartyLeadership = (
  领导班子业务对象: MaybeRef<领导班子业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/leadership`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 领导班子业务对象,
    },
    options,
  );
};

export const getPostPartyLeadershipMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyLeadership>>,
    TError,
    { data: 领导班子业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyLeadership>>,
  TError,
  { data: 领导班子业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyLeadership>>,
    { data: 领导班子业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyLeadership(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyLeadershipMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyLeadership>>
>;
export type PostPartyLeadershipMutationBody = 领导班子业务对象;
export type PostPartyLeadershipMutationError = unknown;

/**
 * @summary 新增领导班子
 */
export const usePostPartyLeadership = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyLeadership>>,
    TError,
    { data: 领导班子业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyLeadershipMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导出领导班子列表
 */
export const postPartyLeadershipExport = (
  params: MaybeRef<PostPartyLeadershipExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/party/leadership/export`, method: "post", params },
    options,
  );
};

export const getPostPartyLeadershipExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyLeadershipExport>>,
    TError,
    { params: PostPartyLeadershipExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyLeadershipExport>>,
  TError,
  { params: PostPartyLeadershipExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyLeadershipExport>>,
    { params: PostPartyLeadershipExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postPartyLeadershipExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyLeadershipExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyLeadershipExport>>
>;

export type PostPartyLeadershipExportMutationError = unknown;

/**
 * @summary 导出领导班子列表
 */
export const usePostPartyLeadershipExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyLeadershipExport>>,
    TError,
    { params: PostPartyLeadershipExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyLeadershipExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询领导班子列表
 */
export const getPartyLeadershipList = (
  params: MaybeRef<GetPartyLeadershipListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象领导班子视图对象>(
    { url: `/party/leadership/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyLeadershipListQueryKey = (
  params: MaybeRef<GetPartyLeadershipListParams>,
) => ["party", "leadership", "list", ...(params ? [params] : [])] as const;

export const getGetPartyLeadershipListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyLeadershipList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyLeadershipListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyLeadershipList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyLeadershipList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyLeadershipListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyLeadershipList>>
  > = ({ signal }) => getPartyLeadershipList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyLeadershipListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyLeadershipList>>
>;
export type GetPartyLeadershipListQueryError = void;

/**
 * @summary 查询领导班子列表
 */
export const useGetPartyLeadershipList = <
  TData = Awaited<ReturnType<typeof getPartyLeadershipList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyLeadershipListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyLeadershipList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyLeadershipListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除领导班子
 */
export const deletePartyLeadershipByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/leadership/${unref(ids)}`, method: "delete" },
    options,
  );
};

export const getDeletePartyLeadershipByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyLeadershipByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyLeadershipByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyLeadershipByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyLeadershipByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyLeadershipByIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyLeadershipByIds>>
>;

export type DeletePartyLeadershipByIdsMutationError = unknown;

/**
 * @summary 删除领导班子
 */
export const useDeletePartyLeadershipByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyLeadershipByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeletePartyLeadershipByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取领导班子详细信息
 */
export const getPartyLeadershipById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象领导班子视图对象>(
    { url: `/party/leadership/${unref(id)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyLeadershipByIdQueryKey = (id: MaybeRef<number>) =>
  ["party", "leadership", id] as const;

export const getGetPartyLeadershipByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyLeadershipById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyLeadershipById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyLeadershipById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyLeadershipByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyLeadershipById>>
  > = ({ signal }) => getPartyLeadershipById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyLeadershipByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyLeadershipById>>
>;
export type GetPartyLeadershipByIdQueryError = void;

/**
 * @summary 获取领导班子详细信息
 */
export const useGetPartyLeadershipById = <
  TData = Awaited<ReturnType<typeof getPartyLeadershipById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyLeadershipById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyLeadershipByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 修改组织换届
 */
export const putPartyOrgTransition = (
  组织换届业务对象: MaybeRef<组织换届业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/orgTransition`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 组织换届业务对象,
    },
    options,
  );
};

export const getPutPartyOrgTransitionMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyOrgTransition>>,
    TError,
    { data: 组织换届业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPartyOrgTransition>>,
  TError,
  { data: 组织换届业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPartyOrgTransition>>,
    { data: 组织换届业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putPartyOrgTransition(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPartyOrgTransitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPartyOrgTransition>>
>;
export type PutPartyOrgTransitionMutationBody = 组织换届业务对象;
export type PutPartyOrgTransitionMutationError = unknown;

/**
 * @summary 修改组织换届
 */
export const usePutPartyOrgTransition = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyOrgTransition>>,
    TError,
    { data: 组织换届业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutPartyOrgTransitionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增组织换届
 */
export const postPartyOrgTransition = (
  组织换届业务对象: MaybeRef<组织换届业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/orgTransition`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 组织换届业务对象,
    },
    options,
  );
};

export const getPostPartyOrgTransitionMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyOrgTransition>>,
    TError,
    { data: 组织换届业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyOrgTransition>>,
  TError,
  { data: 组织换届业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyOrgTransition>>,
    { data: 组织换届业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyOrgTransition(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyOrgTransitionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyOrgTransition>>
>;
export type PostPartyOrgTransitionMutationBody = 组织换届业务对象;
export type PostPartyOrgTransitionMutationError = unknown;

/**
 * @summary 新增组织换届
 */
export const usePostPartyOrgTransition = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyOrgTransition>>,
    TError,
    { data: 组织换届业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyOrgTransitionMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导出组织换届列表
 */
export const postPartyOrgTransitionExport = (
  params: MaybeRef<PostPartyOrgTransitionExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/party/orgTransition/export`, method: "post", params },
    options,
  );
};

export const getPostPartyOrgTransitionExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyOrgTransitionExport>>,
    TError,
    { params: PostPartyOrgTransitionExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyOrgTransitionExport>>,
  TError,
  { params: PostPartyOrgTransitionExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyOrgTransitionExport>>,
    { params: PostPartyOrgTransitionExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postPartyOrgTransitionExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyOrgTransitionExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyOrgTransitionExport>>
>;

export type PostPartyOrgTransitionExportMutationError = unknown;

/**
 * @summary 导出组织换届列表
 */
export const usePostPartyOrgTransitionExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyOrgTransitionExport>>,
    TError,
    { params: PostPartyOrgTransitionExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyOrgTransitionExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 转为往届
 */
export const postPartyOrgTransitionHistory = (
  postPartyOrgTransitionHistoryBody: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/orgTransition/history`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: postPartyOrgTransitionHistoryBody,
    },
    options,
  );
};

export const getPostPartyOrgTransitionHistoryMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyOrgTransitionHistory>>,
    TError,
    { data: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyOrgTransitionHistory>>,
  TError,
  { data: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyOrgTransitionHistory>>,
    { data: number }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyOrgTransitionHistory(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyOrgTransitionHistoryMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyOrgTransitionHistory>>
>;
export type PostPartyOrgTransitionHistoryMutationBody = number;
export type PostPartyOrgTransitionHistoryMutationError = unknown;

/**
 * @summary 转为往届
 */
export const usePostPartyOrgTransitionHistory = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyOrgTransitionHistory>>,
    TError,
    { data: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyOrgTransitionHistoryMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询组织换届列表
 */
export const getPartyOrgTransitionList = (
  params: MaybeRef<GetPartyOrgTransitionListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象组织换届视图对象>(
    { url: `/party/orgTransition/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyOrgTransitionListQueryKey = (
  params: MaybeRef<GetPartyOrgTransitionListParams>,
) => ["party", "orgTransition", "list", ...(params ? [params] : [])] as const;

export const getGetPartyOrgTransitionListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyOrgTransitionList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyOrgTransitionListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyOrgTransitionList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyOrgTransitionList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyOrgTransitionListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyOrgTransitionList>>
  > = ({ signal }) => getPartyOrgTransitionList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyOrgTransitionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyOrgTransitionList>>
>;
export type GetPartyOrgTransitionListQueryError = void;

/**
 * @summary 查询组织换届列表
 */
export const useGetPartyOrgTransitionList = <
  TData = Awaited<ReturnType<typeof getPartyOrgTransitionList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyOrgTransitionListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyOrgTransitionList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyOrgTransitionListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除组织换届
 */
export const deletePartyOrgTransitionByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/orgTransition/${unref(ids)}`, method: "delete" },
    options,
  );
};

export const getDeletePartyOrgTransitionByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyOrgTransitionByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyOrgTransitionByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyOrgTransitionByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyOrgTransitionByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyOrgTransitionByIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyOrgTransitionByIds>>
>;

export type DeletePartyOrgTransitionByIdsMutationError = unknown;

/**
 * @summary 删除组织换届
 */
export const useDeletePartyOrgTransitionByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyOrgTransitionByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyOrgTransitionByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取组织换届详细信息
 */
export const getPartyOrgTransitionById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象组织换届视图对象>(
    { url: `/party/orgTransition/${unref(id)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyOrgTransitionByIdQueryKey = (id: MaybeRef<number>) =>
  ["party", "orgTransition", id] as const;

export const getGetPartyOrgTransitionByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyOrgTransitionById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyOrgTransitionById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyOrgTransitionById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyOrgTransitionByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyOrgTransitionById>>
  > = ({ signal }) => getPartyOrgTransitionById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyOrgTransitionByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyOrgTransitionById>>
>;
export type GetPartyOrgTransitionByIdQueryError = void;

/**
 * @summary 获取组织换届详细信息
 */
export const useGetPartyOrgTransitionById = <
  TData = Awaited<ReturnType<typeof getPartyOrgTransitionById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyOrgTransitionById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyOrgTransitionByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 新增组织生活
 */
export const postPartyPdOrganizeLifeAdd = (
  组织生活视图对象0: MaybeRef<组织生活视图对象0>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/pdOrganizeLife/add`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 组织生活视图对象0,
    },
    options,
  );
};

export const getPostPartyPdOrganizeLifeAddMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeAdd>>,
    TError,
    { data: 组织生活视图对象0 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeAdd>>,
  TError,
  { data: 组织生活视图对象0 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeAdd>>,
    { data: 组织生活视图对象0 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyPdOrganizeLifeAdd(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyPdOrganizeLifeAddMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeAdd>>
>;
export type PostPartyPdOrganizeLifeAddMutationBody = 组织生活视图对象0;
export type PostPartyPdOrganizeLifeAddMutationError = unknown;

/**
 * @summary 新增组织生活
 */
export const usePostPartyPdOrganizeLifeAdd = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeAdd>>,
    TError,
    { data: 组织生活视图对象0 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyPdOrganizeLifeAddMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 审核
 */
export const postPartyPdOrganizeLifeAudit = (
  组织生活视图对象0: MaybeRef<组织生活视图对象0>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/pdOrganizeLife/audit`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 组织生活视图对象0,
    },
    options,
  );
};

export const getPostPartyPdOrganizeLifeAuditMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeAudit>>,
    TError,
    { data: 组织生活视图对象0 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeAudit>>,
  TError,
  { data: 组织生活视图对象0 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeAudit>>,
    { data: 组织生活视图对象0 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyPdOrganizeLifeAudit(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyPdOrganizeLifeAuditMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeAudit>>
>;
export type PostPartyPdOrganizeLifeAuditMutationBody = 组织生活视图对象0;
export type PostPartyPdOrganizeLifeAuditMutationError = unknown;

/**
 * @summary 审核
 */
export const usePostPartyPdOrganizeLifeAudit = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeAudit>>,
    TError,
    { data: 组织生活视图对象0 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyPdOrganizeLifeAuditMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改组织生活
 */
export const postPartyPdOrganizeLifeEdit = (
  组织生活视图对象0: MaybeRef<组织生活视图对象0>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/pdOrganizeLife/edit`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 组织生活视图对象0,
    },
    options,
  );
};

export const getPostPartyPdOrganizeLifeEditMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeEdit>>,
    TError,
    { data: 组织生活视图对象0 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeEdit>>,
  TError,
  { data: 组织生活视图对象0 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeEdit>>,
    { data: 组织生活视图对象0 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyPdOrganizeLifeEdit(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyPdOrganizeLifeEditMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeEdit>>
>;
export type PostPartyPdOrganizeLifeEditMutationBody = 组织生活视图对象0;
export type PostPartyPdOrganizeLifeEditMutationError = unknown;

/**
 * @summary 修改组织生活
 */
export const usePostPartyPdOrganizeLifeEdit = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeEdit>>,
    TError,
    { data: 组织生活视图对象0 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyPdOrganizeLifeEditMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询党员到场列表
 */
export const getPartyPdOrganizeLifeGetPartyList = (
  params: MaybeRef<GetPartyPdOrganizeLifeGetPartyListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象组织生活党员到场记录对象>(
    {
      url: `/party/pdOrganizeLife/getPartyList`,
      method: "get",
      params,
      signal,
    },
    options,
  );
};

export const getGetPartyPdOrganizeLifeGetPartyListQueryKey = (
  params: MaybeRef<GetPartyPdOrganizeLifeGetPartyListParams>,
) =>
  [
    "party",
    "pdOrganizeLife",
    "getPartyList",
    ...(params ? [params] : []),
  ] as const;

export const getGetPartyPdOrganizeLifeGetPartyListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyPdOrganizeLifeGetPartyList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyPdOrganizeLifeGetPartyListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyPdOrganizeLifeGetPartyList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyPdOrganizeLifeGetPartyList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyPdOrganizeLifeGetPartyListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyPdOrganizeLifeGetPartyList>>
  > = ({ signal }) =>
    getPartyPdOrganizeLifeGetPartyList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyPdOrganizeLifeGetPartyListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyPdOrganizeLifeGetPartyList>>
>;
export type GetPartyPdOrganizeLifeGetPartyListQueryError = void;

/**
 * @summary 查询党员到场列表
 */
export const useGetPartyPdOrganizeLifeGetPartyList = <
  TData = Awaited<ReturnType<typeof getPartyPdOrganizeLifeGetPartyList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyPdOrganizeLifeGetPartyListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyPdOrganizeLifeGetPartyList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyPdOrganizeLifeGetPartyListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询组织生活详情
 */
export const getPartyPdOrganizeLifeInfoById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象组织生活视图对象>(
    { url: `/party/pdOrganizeLife/info/${unref(id)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyPdOrganizeLifeInfoByIdQueryKey = (
  id: MaybeRef<number>,
) => ["party", "pdOrganizeLife", "info", id] as const;

export const getGetPartyPdOrganizeLifeInfoByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyPdOrganizeLifeInfoByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoById>>
  > = ({ signal }) =>
    getPartyPdOrganizeLifeInfoById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyPdOrganizeLifeInfoByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoById>>
>;
export type GetPartyPdOrganizeLifeInfoByIdQueryError = void;

/**
 * @summary 查询组织生活详情
 */
export const useGetPartyPdOrganizeLifeInfoById = <
  TData = Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyPdOrganizeLifeInfoByIdQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询党员到场详情
 */
export const getPartyPdOrganizeLifeInfoPartyById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象组织生活党员到场记录对象>(
    {
      url: `/party/pdOrganizeLife/infoParty/${unref(id)}`,
      method: "get",
      signal,
    },
    options,
  );
};

export const getGetPartyPdOrganizeLifeInfoPartyByIdQueryKey = (
  id: MaybeRef<number>,
) => ["party", "pdOrganizeLife", "infoParty", id] as const;

export const getGetPartyPdOrganizeLifeInfoPartyByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoPartyById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoPartyById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoPartyById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyPdOrganizeLifeInfoPartyByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoPartyById>>
  > = ({ signal }) =>
    getPartyPdOrganizeLifeInfoPartyById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyPdOrganizeLifeInfoPartyByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoPartyById>>
>;
export type GetPartyPdOrganizeLifeInfoPartyByIdQueryError = void;

/**
 * @summary 查询党员到场详情
 */
export const useGetPartyPdOrganizeLifeInfoPartyById = <
  TData = Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoPartyById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyPdOrganizeLifeInfoPartyById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyPdOrganizeLifeInfoPartyByIdQueryOptions(
    id,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询组织生活列表
 */
export const getPartyPdOrganizeLifeList = (
  params: MaybeRef<GetPartyPdOrganizeLifeListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象组织生活视图对象>(
    { url: `/party/pdOrganizeLife/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyPdOrganizeLifeListQueryKey = (
  params: MaybeRef<GetPartyPdOrganizeLifeListParams>,
) => ["party", "pdOrganizeLife", "list", ...(params ? [params] : [])] as const;

export const getGetPartyPdOrganizeLifeListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyPdOrganizeLifeList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyPdOrganizeLifeListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyPdOrganizeLifeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyPdOrganizeLifeList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyPdOrganizeLifeListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyPdOrganizeLifeList>>
  > = ({ signal }) =>
    getPartyPdOrganizeLifeList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyPdOrganizeLifeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyPdOrganizeLifeList>>
>;
export type GetPartyPdOrganizeLifeListQueryError = void;

/**
 * @summary 查询组织生活列表
 */
export const useGetPartyPdOrganizeLifeList = <
  TData = Awaited<ReturnType<typeof getPartyPdOrganizeLifeList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyPdOrganizeLifeListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyPdOrganizeLifeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyPdOrganizeLifeListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 通知党员详情
 */
export const getPartyPdOrganizeLifeNoticeInfoById = (
  id: MaybeRef<number>,
  params?: MaybeRef<GetPartyPdOrganizeLifeNoticeInfoByIdParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象消息视图对象>(
    {
      url: `/party/pdOrganizeLife/noticeInfo/${unref(id)}`,
      method: "get",
      params,
      signal,
    },
    options,
  );
};

export const getGetPartyPdOrganizeLifeNoticeInfoByIdQueryKey = (
  id: MaybeRef<number>,
  params?: MaybeRef<GetPartyPdOrganizeLifeNoticeInfoByIdParams>,
) =>
  [
    "party",
    "pdOrganizeLife",
    "noticeInfo",
    id,
    ...(params ? [params] : []),
  ] as const;

export const getGetPartyPdOrganizeLifeNoticeInfoByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyPdOrganizeLifeNoticeInfoById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  params?: MaybeRef<GetPartyPdOrganizeLifeNoticeInfoByIdParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyPdOrganizeLifeNoticeInfoById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyPdOrganizeLifeNoticeInfoById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyPdOrganizeLifeNoticeInfoByIdQueryKey(id, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyPdOrganizeLifeNoticeInfoById>>
  > = ({ signal }) =>
    getPartyPdOrganizeLifeNoticeInfoById(id, params, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyPdOrganizeLifeNoticeInfoByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyPdOrganizeLifeNoticeInfoById>>
>;
export type GetPartyPdOrganizeLifeNoticeInfoByIdQueryError = void;

/**
 * @summary 通知党员详情
 */
export const useGetPartyPdOrganizeLifeNoticeInfoById = <
  TData = Awaited<ReturnType<typeof getPartyPdOrganizeLifeNoticeInfoById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  params?: MaybeRef<GetPartyPdOrganizeLifeNoticeInfoByIdParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyPdOrganizeLifeNoticeInfoById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyPdOrganizeLifeNoticeInfoByIdQueryOptions(
    id,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除组织生活信息
 */
export const deletePartyPdOrganizeLifeRemoveByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/pdOrganizeLife/remove/${unref(ids)}`, method: "delete" },
    options,
  );
};

export const getDeletePartyPdOrganizeLifeRemoveByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyPdOrganizeLifeRemoveByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyPdOrganizeLifeRemoveByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyPdOrganizeLifeRemoveByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyPdOrganizeLifeRemoveByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyPdOrganizeLifeRemoveByIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyPdOrganizeLifeRemoveByIds>>
>;

export type DeletePartyPdOrganizeLifeRemoveByIdsMutationError = unknown;

/**
 * @summary 删除组织生活信息
 */
export const useDeletePartyPdOrganizeLifeRemoveByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyPdOrganizeLifeRemoveByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyPdOrganizeLifeRemoveByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 删除党员到场详情
 */
export const deletePartyPdOrganizeLifeRemovePartyByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/pdOrganizeLife/removeParty/${unref(ids)}`,
      method: "delete",
    },
    options,
  );
};

export const getDeletePartyPdOrganizeLifeRemovePartyByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyPdOrganizeLifeRemovePartyByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyPdOrganizeLifeRemovePartyByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyPdOrganizeLifeRemovePartyByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyPdOrganizeLifeRemovePartyByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyPdOrganizeLifeRemovePartyByIdsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof deletePartyPdOrganizeLifeRemovePartyByIds>>
  >;

export type DeletePartyPdOrganizeLifeRemovePartyByIdsMutationError = unknown;

/**
 * @summary 删除党员到场详情
 */
export const useDeletePartyPdOrganizeLifeRemovePartyByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyPdOrganizeLifeRemovePartyByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyPdOrganizeLifeRemovePartyByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 发送通知
 */
export const postPartyPdOrganizeLifeSendNoticeById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/pdOrganizeLife/sendNotice/${unref(id)}`, method: "post" },
    options,
  );
};

export const getPostPartyPdOrganizeLifeSendNoticeByIdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeSendNoticeById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeSendNoticeById>>,
  TError,
  { id: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeSendNoticeById>>,
    { id: number }
  > = (props) => {
    const { id } = props ?? {};

    return postPartyPdOrganizeLifeSendNoticeById(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyPdOrganizeLifeSendNoticeByIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeSendNoticeById>>
>;

export type PostPartyPdOrganizeLifeSendNoticeByIdMutationError = unknown;

/**
 * @summary 发送通知
 */
export const usePostPartyPdOrganizeLifeSendNoticeById = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeSendNoticeById>>,
    TError,
    { id: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyPdOrganizeLifeSendNoticeByIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改党员到场详情
 */
export const postPartyPdOrganizeLifeUpdateParty = (
  组织生活党员到场记录对象0: MaybeRef<组织生活党员到场记录对象0>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/pdOrganizeLife/updateParty`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 组织生活党员到场记录对象0,
    },
    options,
  );
};

export const getPostPartyPdOrganizeLifeUpdatePartyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeUpdateParty>>,
    TError,
    { data: 组织生活党员到场记录对象0 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeUpdateParty>>,
  TError,
  { data: 组织生活党员到场记录对象0 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeUpdateParty>>,
    { data: 组织生活党员到场记录对象0 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyPdOrganizeLifeUpdateParty(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyPdOrganizeLifeUpdatePartyMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeUpdateParty>>
>;
export type PostPartyPdOrganizeLifeUpdatePartyMutationBody =
  组织生活党员到场记录对象0;
export type PostPartyPdOrganizeLifeUpdatePartyMutationError = unknown;

/**
 * @summary 修改党员到场详情
 */
export const usePostPartyPdOrganizeLifeUpdateParty = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeUpdateParty>>,
    TError,
    { data: 组织生活党员到场记录对象0 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyPdOrganizeLifeUpdatePartyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改党员到场详情
 */
export const postPartyPdOrganizeLifeUpdatePartyBatch = (
  组织生活党员到场记录对象0: MaybeRef<组织生活党员到场记录对象0>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/pdOrganizeLife/updatePartyBatch`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 组织生活党员到场记录对象0,
    },
    options,
  );
};

export const getPostPartyPdOrganizeLifeUpdatePartyBatchMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeUpdatePartyBatch>>,
    TError,
    { data: 组织生活党员到场记录对象0 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeUpdatePartyBatch>>,
  TError,
  { data: 组织生活党员到场记录对象0 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeUpdatePartyBatch>>,
    { data: 组织生活党员到场记录对象0 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyPdOrganizeLifeUpdatePartyBatch(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyPdOrganizeLifeUpdatePartyBatchMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyPdOrganizeLifeUpdatePartyBatch>>
>;
export type PostPartyPdOrganizeLifeUpdatePartyBatchMutationBody =
  组织生活党员到场记录对象0;
export type PostPartyPdOrganizeLifeUpdatePartyBatchMutationError = unknown;

/**
 * @summary 修改党员到场详情
 */
export const usePostPartyPdOrganizeLifeUpdatePartyBatch = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyPdOrganizeLifeUpdatePartyBatch>>,
    TError,
    { data: 组织生活党员到场记录对象0 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyPdOrganizeLifeUpdatePartyBatchMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改用户积分
 */
export const putPartyUserIntegration = (
  用户积分业务对象: MaybeRef<用户积分业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/userIntegration`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 用户积分业务对象,
    },
    options,
  );
};

export const getPutPartyUserIntegrationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyUserIntegration>>,
    TError,
    { data: 用户积分业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPartyUserIntegration>>,
  TError,
  { data: 用户积分业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putPartyUserIntegration>>,
    { data: 用户积分业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putPartyUserIntegration(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutPartyUserIntegrationMutationResult = NonNullable<
  Awaited<ReturnType<typeof putPartyUserIntegration>>
>;
export type PutPartyUserIntegrationMutationBody = 用户积分业务对象;
export type PutPartyUserIntegrationMutationError = unknown;

/**
 * @summary 修改用户积分
 */
export const usePutPartyUserIntegration = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPartyUserIntegration>>,
    TError,
    { data: 用户积分业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutPartyUserIntegrationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增用户积分
 */
export const postPartyUserIntegration = (
  用户积分业务对象: MaybeRef<用户积分业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/userIntegration`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 用户积分业务对象,
    },
    options,
  );
};

export const getPostPartyUserIntegrationMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserIntegration>>,
    TError,
    { data: 用户积分业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserIntegration>>,
  TError,
  { data: 用户积分业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserIntegration>>,
    { data: 用户积分业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserIntegration(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserIntegrationMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserIntegration>>
>;
export type PostPartyUserIntegrationMutationBody = 用户积分业务对象;
export type PostPartyUserIntegrationMutationError = unknown;

/**
 * @summary 新增用户积分
 */
export const usePostPartyUserIntegration = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserIntegration>>,
    TError,
    { data: 用户积分业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyUserIntegrationMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 下载导入模板
 */
export const getPartyUserIntegrationDownloadTemplate = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/party/userIntegration/downloadTemplate`, method: "get", signal },
    options,
  );
};

export const getGetPartyUserIntegrationDownloadTemplateQueryKey = () =>
  ["party", "userIntegration", "downloadTemplate"] as const;

export const getGetPartyUserIntegrationDownloadTemplateQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserIntegrationDownloadTemplate>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyUserIntegrationDownloadTemplate>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserIntegrationDownloadTemplate>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserIntegrationDownloadTemplateQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserIntegrationDownloadTemplate>>
  > = ({ signal }) =>
    getPartyUserIntegrationDownloadTemplate(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserIntegrationDownloadTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserIntegrationDownloadTemplate>>
>;
export type GetPartyUserIntegrationDownloadTemplateQueryError = unknown;

/**
 * @summary 下载导入模板
 */
export const useGetPartyUserIntegrationDownloadTemplate = <
  TData = Awaited<ReturnType<typeof getPartyUserIntegrationDownloadTemplate>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getPartyUserIntegrationDownloadTemplate>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    getGetPartyUserIntegrationDownloadTemplateQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 导出用户积分列表
 */
export const postPartyUserIntegrationExport = (
  params?: MaybeRef<PostPartyUserIntegrationExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/party/userIntegration/export`, method: "post", params },
    options,
  );
};

export const getPostPartyUserIntegrationExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserIntegrationExport>>,
    TError,
    { params?: PostPartyUserIntegrationExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserIntegrationExport>>,
  TError,
  { params?: PostPartyUserIntegrationExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserIntegrationExport>>,
    { params?: PostPartyUserIntegrationExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postPartyUserIntegrationExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserIntegrationExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserIntegrationExport>>
>;

export type PostPartyUserIntegrationExportMutationError = unknown;

/**
 * @summary 导出用户积分列表
 */
export const usePostPartyUserIntegrationExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserIntegrationExport>>,
    TError,
    { params?: PostPartyUserIntegrationExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserIntegrationExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导入数据
 */
export const postPartyUserIntegrationImportData = (
  postPartyUserIntegrationImportDataBody: MaybeRef<PostPartyUserIntegrationImportDataBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData();
  formData.append("file", postPartyUserIntegrationImportDataBody.file);

  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/userIntegration/importData`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getPostPartyUserIntegrationImportDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserIntegrationImportData>>,
    TError,
    { data: PostPartyUserIntegrationImportDataBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserIntegrationImportData>>,
  TError,
  { data: PostPartyUserIntegrationImportDataBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserIntegrationImportData>>,
    { data: PostPartyUserIntegrationImportDataBody }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserIntegrationImportData(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserIntegrationImportDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserIntegrationImportData>>
>;
export type PostPartyUserIntegrationImportDataMutationBody =
  PostPartyUserIntegrationImportDataBody;
export type PostPartyUserIntegrationImportDataMutationError = unknown;

/**
 * @summary 导入数据
 */
export const usePostPartyUserIntegrationImportData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserIntegrationImportData>>,
    TError,
    { data: PostPartyUserIntegrationImportDataBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserIntegrationImportDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询用户积分列表
 */
export const getPartyUserIntegrationList = (
  params?: MaybeRef<GetPartyUserIntegrationListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象用户积分视图对象>(
    { url: `/party/userIntegration/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyUserIntegrationListQueryKey = (
  params?: MaybeRef<GetPartyUserIntegrationListParams>,
) => ["party", "userIntegration", "list", ...(params ? [params] : [])] as const;

export const getGetPartyUserIntegrationListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserIntegrationList>>,
  TError = void,
>(
  params?: MaybeRef<GetPartyUserIntegrationListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserIntegrationList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserIntegrationList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserIntegrationListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserIntegrationList>>
  > = ({ signal }) =>
    getPartyUserIntegrationList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserIntegrationListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserIntegrationList>>
>;
export type GetPartyUserIntegrationListQueryError = void;

/**
 * @summary 查询用户积分列表
 */
export const useGetPartyUserIntegrationList = <
  TData = Awaited<ReturnType<typeof getPartyUserIntegrationList>>,
  TError = void,
>(
  params?: MaybeRef<GetPartyUserIntegrationListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserIntegrationList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserIntegrationListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除用户积分
 */
export const deletePartyUserIntegrationByIds = (
  ids: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/party/userIntegration/${unref(ids)}`, method: "delete" },
    options,
  );
};

export const getDeletePartyUserIntegrationByIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyUserIntegrationByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deletePartyUserIntegrationByIds>>,
  TError,
  { ids: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deletePartyUserIntegrationByIds>>,
    { ids: string }
  > = (props) => {
    const { ids } = props ?? {};

    return deletePartyUserIntegrationByIds(ids, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeletePartyUserIntegrationByIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePartyUserIntegrationByIds>>
>;

export type DeletePartyUserIntegrationByIdsMutationError = unknown;

/**
 * @summary 删除用户积分
 */
export const useDeletePartyUserIntegrationByIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deletePartyUserIntegrationByIds>>,
    TError,
    { ids: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeletePartyUserIntegrationByIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取用户积分详细信息
 */
export const getPartyUserIntegrationById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象用户积分视图对象>(
    { url: `/party/userIntegration/${unref(id)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyUserIntegrationByIdQueryKey = (id: MaybeRef<number>) =>
  ["party", "userIntegration", id] as const;

export const getGetPartyUserIntegrationByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserIntegrationById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserIntegrationById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserIntegrationById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserIntegrationByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserIntegrationById>>
  > = ({ signal }) => getPartyUserIntegrationById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyUserIntegrationByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserIntegrationById>>
>;
export type GetPartyUserIntegrationByIdQueryError = void;

/**
 * @summary 获取用户积分详细信息
 */
export const useGetPartyUserIntegrationById = <
  TData = Awaited<ReturnType<typeof getPartyUserIntegrationById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserIntegrationById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserIntegrationByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 党员死亡
 */
export const postPartyUserTransferDead = (
  党员死亡请求: MaybeRef<党员死亡请求>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/transfer/dead`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 党员死亡请求,
    },
    options,
  );
};

export const getPostPartyUserTransferDeadMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferDead>>,
    TError,
    { data: 党员死亡请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserTransferDead>>,
  TError,
  { data: 党员死亡请求 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserTransferDead>>,
    { data: 党员死亡请求 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserTransferDead(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserTransferDeadMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserTransferDead>>
>;
export type PostPartyUserTransferDeadMutationBody = 党员死亡请求;
export type PostPartyUserTransferDeadMutationError = unknown;

/**
 * @summary 党员死亡
 */
export const usePostPartyUserTransferDead = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferDead>>,
    TError,
    { data: 党员死亡请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyUserTransferDeadMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取停止党籍的党员
 */
export const getPartyUserTransferGetStopPartyMembers = (
  params: MaybeRef<GetPartyUserTransferGetStopPartyMembersParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List党员信息视图对象>(
    {
      url: `/party/user/transfer/getStopPartyMembers`,
      method: "get",
      params,
      signal,
    },
    options,
  );
};

export const getGetPartyUserTransferGetStopPartyMembersQueryKey = (
  params: MaybeRef<GetPartyUserTransferGetStopPartyMembersParams>,
) =>
  [
    "party",
    "user",
    "transfer",
    "getStopPartyMembers",
    ...(params ? [params] : []),
  ] as const;

export const getGetPartyUserTransferGetStopPartyMembersQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserTransferGetStopPartyMembers>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserTransferGetStopPartyMembersParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserTransferGetStopPartyMembers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserTransferGetStopPartyMembers>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserTransferGetStopPartyMembersQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserTransferGetStopPartyMembers>>
  > = ({ signal }) =>
    getPartyUserTransferGetStopPartyMembers(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserTransferGetStopPartyMembersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserTransferGetStopPartyMembers>>
>;
export type GetPartyUserTransferGetStopPartyMembersQueryError = void;

/**
 * @summary 获取停止党籍的党员
 */
export const useGetPartyUserTransferGetStopPartyMembers = <
  TData = Awaited<ReturnType<typeof getPartyUserTransferGetStopPartyMembers>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserTransferGetStopPartyMembersParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserTransferGetStopPartyMembers>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserTransferGetStopPartyMembersQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 内部调动
 */
export const postPartyUserTransferInnerTransfer = (
  内部调动请求: MaybeRef<内部调动请求>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/transfer/innerTransfer`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 内部调动请求,
    },
    options,
  );
};

export const getPostPartyUserTransferInnerTransferMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferInnerTransfer>>,
    TError,
    { data: 内部调动请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserTransferInnerTransfer>>,
  TError,
  { data: 内部调动请求 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserTransferInnerTransfer>>,
    { data: 内部调动请求 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserTransferInnerTransfer(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserTransferInnerTransferMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserTransferInnerTransfer>>
>;
export type PostPartyUserTransferInnerTransferMutationBody = 内部调动请求;
export type PostPartyUserTransferInnerTransferMutationError = unknown;

/**
 * @summary 内部调动
 */
export const usePostPartyUserTransferInnerTransfer = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferInnerTransfer>>,
    TError,
    { data: 内部调动请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserTransferInnerTransferMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询人员转移记录列表
 */
export const getPartyUserTransferList = (
  params: MaybeRef<GetPartyUserTransferListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象人员转移记录视图对象>(
    { url: `/party/user/transfer/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyUserTransferListQueryKey = (
  params: MaybeRef<GetPartyUserTransferListParams>,
) =>
  ["party", "user", "transfer", "list", ...(params ? [params] : [])] as const;

export const getGetPartyUserTransferListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserTransferList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserTransferListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserTransferList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserTransferList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserTransferListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserTransferList>>
  > = ({ signal }) => getPartyUserTransferList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserTransferListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserTransferList>>
>;
export type GetPartyUserTransferListQueryError = void;

/**
 * @summary 查询人员转移记录列表
 */
export const useGetPartyUserTransferList = <
  TData = Awaited<ReturnType<typeof getPartyUserTransferList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserTransferListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserTransferList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserTransferListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 党员出党
 */
export const postPartyUserTransferOutParty = (
  党员出党请求: MaybeRef<党员出党请求>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/transfer/outParty`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 党员出党请求,
    },
    options,
  );
};

export const getPostPartyUserTransferOutPartyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferOutParty>>,
    TError,
    { data: 党员出党请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserTransferOutParty>>,
  TError,
  { data: 党员出党请求 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserTransferOutParty>>,
    { data: 党员出党请求 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserTransferOutParty(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserTransferOutPartyMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserTransferOutParty>>
>;
export type PostPartyUserTransferOutPartyMutationBody = 党员出党请求;
export type PostPartyUserTransferOutPartyMutationError = unknown;

/**
 * @summary 党员出党
 */
export const usePostPartyUserTransferOutParty = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferOutParty>>,
    TError,
    { data: 党员出党请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserTransferOutPartyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 恢复党籍
 */
export const postPartyUserTransferRecoverParty = (
  恢复党籍请求: MaybeRef<恢复党籍请求>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/transfer/recoverParty`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 恢复党籍请求,
    },
    options,
  );
};

export const getPostPartyUserTransferRecoverPartyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferRecoverParty>>,
    TError,
    { data: 恢复党籍请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserTransferRecoverParty>>,
  TError,
  { data: 恢复党籍请求 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserTransferRecoverParty>>,
    { data: 恢复党籍请求 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserTransferRecoverParty(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserTransferRecoverPartyMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserTransferRecoverParty>>
>;
export type PostPartyUserTransferRecoverPartyMutationBody = 恢复党籍请求;
export type PostPartyUserTransferRecoverPartyMutationError = unknown;

/**
 * @summary 恢复党籍
 */
export const usePostPartyUserTransferRecoverParty = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferRecoverParty>>,
    TError,
    { data: 恢复党籍请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserTransferRecoverPartyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 停止党籍
 */
export const postPartyUserTransferStopParty = (
  停止党籍请求: MaybeRef<停止党籍请求>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/transfer/stopParty`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 停止党籍请求,
    },
    options,
  );
};

export const getPostPartyUserTransferStopPartyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferStopParty>>,
    TError,
    { data: 停止党籍请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserTransferStopParty>>,
  TError,
  { data: 停止党籍请求 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserTransferStopParty>>,
    { data: 停止党籍请求 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserTransferStopParty(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserTransferStopPartyMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserTransferStopParty>>
>;
export type PostPartyUserTransferStopPartyMutationBody = 停止党籍请求;
export type PostPartyUserTransferStopPartyMutationError = unknown;

/**
 * @summary 停止党籍
 */
export const usePostPartyUserTransferStopParty = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferStopParty>>,
    TError,
    { data: 停止党籍请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserTransferStopPartyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 转入党员
 */
export const postPartyUserTransferTransferInto = (
  转入党员请求: MaybeRef<转入党员请求>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/transfer/transferInto`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 转入党员请求,
    },
    options,
  );
};

export const getPostPartyUserTransferTransferIntoMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferTransferInto>>,
    TError,
    { data: 转入党员请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserTransferTransferInto>>,
  TError,
  { data: 转入党员请求 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserTransferTransferInto>>,
    { data: 转入党员请求 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserTransferTransferInto(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserTransferTransferIntoMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserTransferTransferInto>>
>;
export type PostPartyUserTransferTransferIntoMutationBody = 转入党员请求;
export type PostPartyUserTransferTransferIntoMutationError = unknown;

/**
 * @summary 转入党员
 */
export const usePostPartyUserTransferTransferInto = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferTransferInto>>,
    TError,
    { data: 转入党员请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserTransferTransferIntoMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 党员转出
 */
export const postPartyUserTransferTransferOut = (
  党员转出请求: MaybeRef<党员转出请求>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/user/transfer/transferOut`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 党员转出请求,
    },
    options,
  );
};

export const getPostPartyUserTransferTransferOutMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferTransferOut>>,
    TError,
    { data: 党员转出请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserTransferTransferOut>>,
  TError,
  { data: 党员转出请求 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserTransferTransferOut>>,
    { data: 党员转出请求 }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyUserTransferTransferOut(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserTransferTransferOutMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserTransferTransferOut>>
>;
export type PostPartyUserTransferTransferOutMutationBody = 党员转出请求;
export type PostPartyUserTransferTransferOutMutationError = unknown;

/**
 * @summary 党员转出
 */
export const usePostPartyUserTransferTransferOut = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserTransferTransferOut>>,
    TError,
    { data: 党员转出请求 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyUserTransferTransferOutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取人员转移记录详细信息
 */
export const getPartyUserTransferById = (
  id: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象人员转移记录视图对象>(
    { url: `/party/user/transfer/${unref(id)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyUserTransferByIdQueryKey = (id: MaybeRef<number>) =>
  ["party", "user", "transfer", id] as const;

export const getGetPartyUserTransferByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserTransferById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserTransferById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserTransferById>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserTransferByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserTransferById>>
  > = ({ signal }) => getPartyUserTransferById(id, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetPartyUserTransferByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserTransferById>>
>;
export type GetPartyUserTransferByIdQueryError = void;

/**
 * @summary 获取人员转移记录详细信息
 */
export const useGetPartyUserTransferById = <
  TData = Awaited<ReturnType<typeof getPartyUserTransferById>>,
  TError = void,
>(
  id: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserTransferById>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserTransferByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 今年是否已发送过通知
 */
export const getPartyBirthdayHasSendByUserId = (
  userId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象Boolean>(
    { url: `/party/birthday/hasSend/${unref(userId)}`, method: "get", signal },
    options,
  );
};

export const getGetPartyBirthdayHasSendByUserIdQueryKey = (
  userId: MaybeRef<number>,
) => ["party", "birthday", "hasSend", userId] as const;

export const getGetPartyBirthdayHasSendByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyBirthdayHasSendByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyBirthdayHasSendByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyBirthdayHasSendByUserId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyBirthdayHasSendByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyBirthdayHasSendByUserId>>
  > = ({ signal }) =>
    getPartyBirthdayHasSendByUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetPartyBirthdayHasSendByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyBirthdayHasSendByUserId>>
>;
export type GetPartyBirthdayHasSendByUserIdQueryError = void;

/**
 * @summary 今年是否已发送过通知
 */
export const useGetPartyBirthdayHasSendByUserId = <
  TData = Awaited<ReturnType<typeof getPartyBirthdayHasSendByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyBirthdayHasSendByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyBirthdayHasSendByUserIdQueryOptions(
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询政治生日信息列表
 */
export const getPartyBirthdayList = (
  params: MaybeRef<GetPartyBirthdayListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象党员信息视图对象>(
    { url: `/party/birthday/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyBirthdayListQueryKey = (
  params: MaybeRef<GetPartyBirthdayListParams>,
) => ["party", "birthday", "list", ...(params ? [params] : [])] as const;

export const getGetPartyBirthdayListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyBirthdayList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyBirthdayListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyBirthdayList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyBirthdayList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyBirthdayListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyBirthdayList>>
  > = ({ signal }) => getPartyBirthdayList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyBirthdayListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyBirthdayList>>
>;
export type GetPartyBirthdayListQueryError = void;

/**
 * @summary 查询政治生日信息列表
 */
export const useGetPartyBirthdayList = <
  TData = Awaited<ReturnType<typeof getPartyBirthdayList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyBirthdayListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyBirthdayList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyBirthdayListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 通知党员详情
 */
export const getPartyBirthdayNoticeDetail = (
  params?: MaybeRef<GetPartyBirthdayNoticeDetailParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象消息视图对象>(
    { url: `/party/birthday/noticeDetail`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyBirthdayNoticeDetailQueryKey = (
  params?: MaybeRef<GetPartyBirthdayNoticeDetailParams>,
) =>
  ["party", "birthday", "noticeDetail", ...(params ? [params] : [])] as const;

export const getGetPartyBirthdayNoticeDetailQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyBirthdayNoticeDetail>>,
  TError = void,
>(
  params?: MaybeRef<GetPartyBirthdayNoticeDetailParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyBirthdayNoticeDetail>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyBirthdayNoticeDetail>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyBirthdayNoticeDetailQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyBirthdayNoticeDetail>>
  > = ({ signal }) =>
    getPartyBirthdayNoticeDetail(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyBirthdayNoticeDetailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyBirthdayNoticeDetail>>
>;
export type GetPartyBirthdayNoticeDetailQueryError = void;

/**
 * @summary 通知党员详情
 */
export const useGetPartyBirthdayNoticeDetail = <
  TData = Awaited<ReturnType<typeof getPartyBirthdayNoticeDetail>>,
  TError = void,
>(
  params?: MaybeRef<GetPartyBirthdayNoticeDetailParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyBirthdayNoticeDetail>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyBirthdayNoticeDetailQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 发送通知
 */
export const postPartyBirthdayPushNotice = (
  postPartyBirthdayPushNoticeBody: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/party/birthday/pushNotice`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: postPartyBirthdayPushNoticeBody,
    },
    options,
  );
};

export const getPostPartyBirthdayPushNoticeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyBirthdayPushNotice>>,
    TError,
    { data: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyBirthdayPushNotice>>,
  TError,
  { data: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyBirthdayPushNotice>>,
    { data: number }
  > = (props) => {
    const { data } = props ?? {};

    return postPartyBirthdayPushNotice(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyBirthdayPushNoticeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyBirthdayPushNotice>>
>;
export type PostPartyBirthdayPushNoticeMutationBody = number;
export type PostPartyBirthdayPushNoticeMutationError = unknown;

/**
 * @summary 发送通知
 */
export const usePostPartyBirthdayPushNotice = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyBirthdayPushNotice>>,
    TError,
    { data: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostPartyBirthdayPushNoticeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导出在册党员信息列表
 */
export const postPartyUserRecordExport = (
  params: MaybeRef<PostPartyUserRecordExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/party/user/record/export`, method: "post", params },
    options,
  );
};

export const getPostPartyUserRecordExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserRecordExport>>,
    TError,
    { params: PostPartyUserRecordExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postPartyUserRecordExport>>,
  TError,
  { params: PostPartyUserRecordExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postPartyUserRecordExport>>,
    { params: PostPartyUserRecordExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postPartyUserRecordExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostPartyUserRecordExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postPartyUserRecordExport>>
>;

export type PostPartyUserRecordExportMutationError = unknown;

/**
 * @summary 导出在册党员信息列表
 */
export const usePostPartyUserRecordExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postPartyUserRecordExport>>,
    TError,
    { params: PostPartyUserRecordExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostPartyUserRecordExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询在册党员信息列表
 */
export const getPartyUserRecordList = (
  params: MaybeRef<GetPartyUserRecordListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象党员信息视图对象>(
    { url: `/party/user/record/list`, method: "get", params, signal },
    options,
  );
};

export const getGetPartyUserRecordListQueryKey = (
  params: MaybeRef<GetPartyUserRecordListParams>,
) => ["party", "user", "record", "list", ...(params ? [params] : [])] as const;

export const getGetPartyUserRecordListQueryOptions = <
  TData = Awaited<ReturnType<typeof getPartyUserRecordList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserRecordListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserRecordList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getPartyUserRecordList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetPartyUserRecordListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getPartyUserRecordList>>
  > = ({ signal }) => getPartyUserRecordList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetPartyUserRecordListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPartyUserRecordList>>
>;
export type GetPartyUserRecordListQueryError = void;

/**
 * @summary 查询在册党员信息列表
 */
export const useGetPartyUserRecordList = <
  TData = Awaited<ReturnType<typeof getPartyUserRecordList>>,
  TError = void,
>(
  params: MaybeRef<GetPartyUserRecordListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getPartyUserRecordList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPartyUserRecordListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 修改参数配置
 */
export const putSystemConfig = (
  参数配置业务对象: MaybeRef<参数配置业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/config`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 参数配置业务对象,
    },
    options,
  );
};

export const getPutSystemConfigMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemConfig>>,
    TError,
    { data: 参数配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemConfig>>,
  TError,
  { data: 参数配置业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemConfig>>,
    { data: 参数配置业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemConfig(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemConfigMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemConfig>>
>;
export type PutSystemConfigMutationBody = 参数配置业务对象;
export type PutSystemConfigMutationError = unknown;

/**
 * @summary 修改参数配置
 */
export const usePutSystemConfig = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemConfig>>,
    TError,
    { data: 参数配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemConfigMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增参数配置
 */
export const postSystemConfig = (
  参数配置业务对象: MaybeRef<参数配置业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/config`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 参数配置业务对象,
    },
    options,
  );
};

export const getPostSystemConfigMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemConfig>>,
    TError,
    { data: 参数配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemConfig>>,
  TError,
  { data: 参数配置业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemConfig>>,
    { data: 参数配置业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemConfig(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemConfigMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemConfig>>
>;
export type PostSystemConfigMutationBody = 参数配置业务对象;
export type PostSystemConfigMutationError = unknown;

/**
 * @summary 新增参数配置
 */
export const usePostSystemConfig = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemConfig>>,
    TError,
    { data: 参数配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemConfigMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 根据参数键名查询参数值
 */
export const getSystemConfigConfigKeyByConfigKey = (
  configKey: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象Void>(
    {
      url: `/system/config/configKey/${unref(configKey)}`,
      method: "get",
      signal,
    },
    options,
  );
};

export const getGetSystemConfigConfigKeyByConfigKeyQueryKey = (
  configKey: MaybeRef<string>,
) => ["system", "config", "configKey", configKey] as const;

export const getGetSystemConfigConfigKeyByConfigKeyQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemConfigConfigKeyByConfigKey>>,
  TError = void,
>(
  configKey: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemConfigConfigKeyByConfigKey>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemConfigConfigKeyByConfigKey>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemConfigConfigKeyByConfigKeyQueryKey(configKey);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemConfigConfigKeyByConfigKey>>
  > = ({ signal }) =>
    getSystemConfigConfigKeyByConfigKey(configKey, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!configKey, ...queryOptions };
};

export type GetSystemConfigConfigKeyByConfigKeyQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemConfigConfigKeyByConfigKey>>
>;
export type GetSystemConfigConfigKeyByConfigKeyQueryError = void;

/**
 * @summary 根据参数键名查询参数值
 */
export const useGetSystemConfigConfigKeyByConfigKey = <
  TData = Awaited<ReturnType<typeof getSystemConfigConfigKeyByConfigKey>>,
  TError = void,
>(
  configKey: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemConfigConfigKeyByConfigKey>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemConfigConfigKeyByConfigKeyQueryOptions(
    configKey,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 导出参数配置列表
 */
export const postSystemConfigExport = (
  params: MaybeRef<PostSystemConfigExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/system/config/export`, method: "post", params },
    options,
  );
};

export const getPostSystemConfigExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemConfigExport>>,
    TError,
    { params: PostSystemConfigExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemConfigExport>>,
  TError,
  { params: PostSystemConfigExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemConfigExport>>,
    { params: PostSystemConfigExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postSystemConfigExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemConfigExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemConfigExport>>
>;

export type PostSystemConfigExportMutationError = unknown;

/**
 * @summary 导出参数配置列表
 */
export const usePostSystemConfigExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemConfigExport>>,
    TError,
    { params: PostSystemConfigExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemConfigExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取参数配置列表
 */
export const getSystemConfigList = (
  params: MaybeRef<GetSystemConfigListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象参数配置业务对象>(
    { url: `/system/config/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemConfigListQueryKey = (
  params: MaybeRef<GetSystemConfigListParams>,
) => ["system", "config", "list", ...(params ? [params] : [])] as const;

export const getGetSystemConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemConfigList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemConfigListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemConfigList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemConfigListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemConfigList>>
  > = ({ signal }) => getSystemConfigList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemConfigList>>
>;
export type GetSystemConfigListQueryError = void;

/**
 * @summary 获取参数配置列表
 */
export const useGetSystemConfigList = <
  TData = Awaited<ReturnType<typeof getSystemConfigList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemConfigListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemConfigListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 刷新参数缓存
 */
export const deleteSystemConfigRefreshCache = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/config/refreshCache`, method: "delete" },
    options,
  );
};

export const getDeleteSystemConfigRefreshCacheMutationOptions = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemConfigRefreshCache>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemConfigRefreshCache>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemConfigRefreshCache>>,
    TVariables
  > = () => {
    return deleteSystemConfigRefreshCache(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemConfigRefreshCacheMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemConfigRefreshCache>>
>;

export type DeleteSystemConfigRefreshCacheMutationError = unknown;

/**
 * @summary 刷新参数缓存
 */
export const useDeleteSystemConfigRefreshCache = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemConfigRefreshCache>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteSystemConfigRefreshCacheMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 根据参数键名修改参数配置
 */
export const putSystemConfigUpdateByKey = (
  参数配置业务对象: MaybeRef<参数配置业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/config/updateByKey`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 参数配置业务对象,
    },
    options,
  );
};

export const getPutSystemConfigUpdateByKeyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemConfigUpdateByKey>>,
    TError,
    { data: 参数配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemConfigUpdateByKey>>,
  TError,
  { data: 参数配置业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemConfigUpdateByKey>>,
    { data: 参数配置业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemConfigUpdateByKey(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemConfigUpdateByKeyMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemConfigUpdateByKey>>
>;
export type PutSystemConfigUpdateByKeyMutationBody = 参数配置业务对象;
export type PutSystemConfigUpdateByKeyMutationError = unknown;

/**
 * @summary 根据参数键名修改参数配置
 */
export const usePutSystemConfigUpdateByKey = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemConfigUpdateByKey>>,
    TError,
    { data: 参数配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemConfigUpdateByKeyMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 删除参数配置
 */
export const deleteSystemConfigByConfigIds = (
  configIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/config/${unref(configIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemConfigByConfigIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemConfigByConfigIds>>,
    TError,
    { configIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemConfigByConfigIds>>,
  TError,
  { configIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemConfigByConfigIds>>,
    { configIds: string }
  > = (props) => {
    const { configIds } = props ?? {};

    return deleteSystemConfigByConfigIds(configIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemConfigByConfigIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemConfigByConfigIds>>
>;

export type DeleteSystemConfigByConfigIdsMutationError = unknown;

/**
 * @summary 删除参数配置
 */
export const useDeleteSystemConfigByConfigIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemConfigByConfigIds>>,
    TError,
    { configIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteSystemConfigByConfigIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 根据参数编号获取详细信息
 */
export const getSystemConfigByConfigId = (
  configId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象参数配置业务对象>(
    { url: `/system/config/${unref(configId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemConfigByConfigIdQueryKey = (
  configId: MaybeRef<number>,
) => ["system", "config", configId] as const;

export const getGetSystemConfigByConfigIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemConfigByConfigId>>,
  TError = void,
>(
  configId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemConfigByConfigId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemConfigByConfigId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemConfigByConfigIdQueryKey(configId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemConfigByConfigId>>
  > = ({ signal }) =>
    getSystemConfigByConfigId(configId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!configId, ...queryOptions };
};

export type GetSystemConfigByConfigIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemConfigByConfigId>>
>;
export type GetSystemConfigByConfigIdQueryError = void;

/**
 * @summary 根据参数编号获取详细信息
 */
export const useGetSystemConfigByConfigId = <
  TData = Awaited<ReturnType<typeof getSystemConfigByConfigId>>,
  TError = void,
>(
  configId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemConfigByConfigId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemConfigByConfigIdQueryOptions(
    configId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 修改组织
 */
export const putSystemDept = (
  组织对象: MaybeRef<组织对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/dept`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 组织对象,
    },
    options,
  );
};

export const getPutSystemDeptMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemDept>>,
    TError,
    { data: 组织对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemDept>>,
  TError,
  { data: 组织对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemDept>>,
    { data: 组织对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemDept(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemDeptMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemDept>>
>;
export type PutSystemDeptMutationBody = 组织对象;
export type PutSystemDeptMutationError = unknown;

/**
 * @summary 修改组织
 */
export const usePutSystemDept = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemDept>>,
    TError,
    { data: 组织对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemDeptMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增组织
 */
export const postSystemDept = (
  组织对象: MaybeRef<组织对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/dept`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 组织对象,
    },
    options,
  );
};

export const getPostSystemDeptMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDept>>,
    TError,
    { data: 组织对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemDept>>,
  TError,
  { data: 组织对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemDept>>,
    { data: 组织对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemDept(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemDeptMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemDept>>
>;
export type PostSystemDeptMutationBody = 组织对象;
export type PostSystemDeptMutationError = unknown;

/**
 * @summary 新增组织
 */
export const usePostSystemDept = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDept>>,
    TError,
    { data: 组织对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemDeptMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 判断组织名称是否已存在
 */
export const postSystemDeptCheckDeptNameUnique = (
  postSystemDeptCheckDeptNameUniqueBody: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Boolean | void>(
    {
      url: `/system/dept/checkDeptNameUnique`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: postSystemDeptCheckDeptNameUniqueBody,
    },
    options,
  );
};

export const getPostSystemDeptCheckDeptNameUniqueMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDeptCheckDeptNameUnique>>,
    TError,
    { data: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemDeptCheckDeptNameUnique>>,
  TError,
  { data: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemDeptCheckDeptNameUnique>>,
    { data: string }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemDeptCheckDeptNameUnique(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemDeptCheckDeptNameUniqueMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemDeptCheckDeptNameUnique>>
>;
export type PostSystemDeptCheckDeptNameUniqueMutationBody = string;
export type PostSystemDeptCheckDeptNameUniqueMutationError = unknown;

/**
 * @summary 判断组织名称是否已存在
 */
export const usePostSystemDeptCheckDeptNameUnique = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDeptCheckDeptNameUnique>>,
    TError,
    { data: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostSystemDeptCheckDeptNameUniqueMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导入数据
 */
export const postSystemDeptImportData = (
  postSystemDeptImportDataBody: MaybeRef<PostSystemDeptImportDataBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData();
  formData.append("file", postSystemDeptImportDataBody.file);

  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/dept/importData`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getPostSystemDeptImportDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDeptImportData>>,
    TError,
    { data: PostSystemDeptImportDataBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemDeptImportData>>,
  TError,
  { data: PostSystemDeptImportDataBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemDeptImportData>>,
    { data: PostSystemDeptImportDataBody }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemDeptImportData(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemDeptImportDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemDeptImportData>>
>;
export type PostSystemDeptImportDataMutationBody = PostSystemDeptImportDataBody;
export type PostSystemDeptImportDataMutationError = unknown;

/**
 * @summary 导入数据
 */
export const usePostSystemDeptImportData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDeptImportData>>,
    TError,
    { data: PostSystemDeptImportDataBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemDeptImportDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取组织列表
 */
export const getSystemDeptList = (
  params: MaybeRef<GetSystemDeptListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List组织对象>(
    { url: `/system/dept/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemDeptListQueryKey = (
  params: MaybeRef<GetSystemDeptListParams>,
) => ["system", "dept", "list", ...(params ? [params] : [])] as const;

export const getGetSystemDeptListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDeptList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemDeptListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDeptList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDeptList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDeptListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDeptList>>
  > = ({ signal }) => getSystemDeptList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemDeptListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDeptList>>
>;
export type GetSystemDeptListQueryError = void;

/**
 * @summary 获取组织列表
 */
export const useGetSystemDeptList = <
  TData = Awaited<ReturnType<typeof getSystemDeptList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemDeptListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDeptList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDeptListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询组织列表（排除节点）
 */
export const getSystemDeptListExcludeByDeptId = (
  deptId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List组织对象>(
    {
      url: `/system/dept/list/exclude/${unref(deptId)}`,
      method: "get",
      signal,
    },
    options,
  );
};

export const getGetSystemDeptListExcludeByDeptIdQueryKey = (
  deptId: MaybeRef<number>,
) => ["system", "dept", "list", "exclude", deptId] as const;

export const getGetSystemDeptListExcludeByDeptIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDeptListExcludeByDeptId>>,
  TError = void,
>(
  deptId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDeptListExcludeByDeptId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDeptListExcludeByDeptId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDeptListExcludeByDeptIdQueryKey(deptId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDeptListExcludeByDeptId>>
  > = ({ signal }) =>
    getSystemDeptListExcludeByDeptId(deptId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!deptId, ...queryOptions };
};

export type GetSystemDeptListExcludeByDeptIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDeptListExcludeByDeptId>>
>;
export type GetSystemDeptListExcludeByDeptIdQueryError = void;

/**
 * @summary 查询组织列表（排除节点）
 */
export const useGetSystemDeptListExcludeByDeptId = <
  TData = Awaited<ReturnType<typeof getSystemDeptListExcludeByDeptId>>,
  TError = void,
>(
  deptId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDeptListExcludeByDeptId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDeptListExcludeByDeptIdQueryOptions(
    deptId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 加载对应角色组织列表树
 */
export const getSystemDeptRoleDeptTreeselectByRoleId = (
  roleId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象MapStringObject>(
    {
      url: `/system/dept/roleDeptTreeselect/${unref(roleId)}`,
      method: "get",
      signal,
    },
    options,
  );
};

export const getGetSystemDeptRoleDeptTreeselectByRoleIdQueryKey = (
  roleId: MaybeRef<number>,
) => ["system", "dept", "roleDeptTreeselect", roleId] as const;

export const getGetSystemDeptRoleDeptTreeselectByRoleIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDeptRoleDeptTreeselectByRoleId>>,
  TError = void,
>(
  roleId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDeptRoleDeptTreeselectByRoleId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDeptRoleDeptTreeselectByRoleId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDeptRoleDeptTreeselectByRoleIdQueryKey(roleId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDeptRoleDeptTreeselectByRoleId>>
  > = ({ signal }) =>
    getSystemDeptRoleDeptTreeselectByRoleId(roleId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!roleId, ...queryOptions };
};

export type GetSystemDeptRoleDeptTreeselectByRoleIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDeptRoleDeptTreeselectByRoleId>>
>;
export type GetSystemDeptRoleDeptTreeselectByRoleIdQueryError = void;

/**
 * @summary 加载对应角色组织列表树
 */
export const useGetSystemDeptRoleDeptTreeselectByRoleId = <
  TData = Awaited<ReturnType<typeof getSystemDeptRoleDeptTreeselectByRoleId>>,
  TError = void,
>(
  roleId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDeptRoleDeptTreeselectByRoleId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDeptRoleDeptTreeselectByRoleIdQueryOptions(
    roleId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 获取组织下拉树列表
 */
export const getSystemDeptTreeselect = (
  params: MaybeRef<GetSystemDeptTreeselectParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象ListTreeLong>(
    { url: `/system/dept/treeselect`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemDeptTreeselectQueryKey = (
  params: MaybeRef<GetSystemDeptTreeselectParams>,
) => ["system", "dept", "treeselect", ...(params ? [params] : [])] as const;

export const getGetSystemDeptTreeselectQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDeptTreeselect>>,
  TError = void,
>(
  params: MaybeRef<GetSystemDeptTreeselectParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDeptTreeselect>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDeptTreeselect>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDeptTreeselectQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDeptTreeselect>>
  > = ({ signal }) => getSystemDeptTreeselect(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemDeptTreeselectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDeptTreeselect>>
>;
export type GetSystemDeptTreeselectQueryError = void;

/**
 * @summary 获取组织下拉树列表
 */
export const useGetSystemDeptTreeselect = <
  TData = Awaited<ReturnType<typeof getSystemDeptTreeselect>>,
  TError = void,
>(
  params: MaybeRef<GetSystemDeptTreeselectParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDeptTreeselect>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDeptTreeselectQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 根据组织编号获取详细信息
 */
export const getSystemDeptByDeptId = (
  deptId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象SysDeptVo>(
    { url: `/system/dept/${unref(deptId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemDeptByDeptIdQueryKey = (deptId: MaybeRef<number>) =>
  ["system", "dept", deptId] as const;

export const getGetSystemDeptByDeptIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDeptByDeptId>>,
  TError = void,
>(
  deptId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDeptByDeptId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDeptByDeptId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDeptByDeptIdQueryKey(deptId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDeptByDeptId>>
  > = ({ signal }) => getSystemDeptByDeptId(deptId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!deptId, ...queryOptions };
};

export type GetSystemDeptByDeptIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDeptByDeptId>>
>;
export type GetSystemDeptByDeptIdQueryError = void;

/**
 * @summary 根据组织编号获取详细信息
 */
export const useGetSystemDeptByDeptId = <
  TData = Awaited<ReturnType<typeof getSystemDeptByDeptId>>,
  TError = void,
>(
  deptId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDeptByDeptId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDeptByDeptIdQueryOptions(deptId, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除组织
 */
export const deleteSystemDeptByDeptId = (
  deptId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/dept/${unref(deptId)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemDeptByDeptIdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemDeptByDeptId>>,
    TError,
    { deptId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemDeptByDeptId>>,
  TError,
  { deptId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemDeptByDeptId>>,
    { deptId: number }
  > = (props) => {
    const { deptId } = props ?? {};

    return deleteSystemDeptByDeptId(deptId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemDeptByDeptIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemDeptByDeptId>>
>;

export type DeleteSystemDeptByDeptIdMutationError = unknown;

/**
 * @summary 删除组织
 */
export const useDeleteSystemDeptByDeptId = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemDeptByDeptId>>,
    TError,
    { deptId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteSystemDeptByDeptIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改保存字典类型
 */
export const putSystemDictData = (
  字典数据业务对象Req: MaybeRef<字典数据业务对象Req>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/dict/data`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 字典数据业务对象Req,
    },
    options,
  );
};

export const getPutSystemDictDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemDictData>>,
    TError,
    { data: 字典数据业务对象Req },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemDictData>>,
  TError,
  { data: 字典数据业务对象Req },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemDictData>>,
    { data: 字典数据业务对象Req }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemDictData(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemDictDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemDictData>>
>;
export type PutSystemDictDataMutationBody = 字典数据业务对象Req;
export type PutSystemDictDataMutationError = unknown;

/**
 * @summary 修改保存字典类型
 */
export const usePutSystemDictData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemDictData>>,
    TError,
    { data: 字典数据业务对象Req },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemDictDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增字典类型
 */
export const postSystemDictData = (
  字典数据业务对象Req: MaybeRef<字典数据业务对象Req>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/dict/data`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 字典数据业务对象Req,
    },
    options,
  );
};

export const getPostSystemDictDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDictData>>,
    TError,
    { data: 字典数据业务对象Req },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemDictData>>,
  TError,
  { data: 字典数据业务对象Req },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemDictData>>,
    { data: 字典数据业务对象Req }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemDictData(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemDictDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemDictData>>
>;
export type PostSystemDictDataMutationBody = 字典数据业务对象Req;
export type PostSystemDictDataMutationError = unknown;

/**
 * @summary 新增字典类型
 */
export const usePostSystemDictData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDictData>>,
    TError,
    { data: 字典数据业务对象Req },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemDictDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导出字典数据列表
 */
export const postSystemDictDataExport = (
  params: MaybeRef<PostSystemDictDataExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/system/dict/data/export`, method: "post", params },
    options,
  );
};

export const getPostSystemDictDataExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDictDataExport>>,
    TError,
    { params: PostSystemDictDataExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemDictDataExport>>,
  TError,
  { params: PostSystemDictDataExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemDictDataExport>>,
    { params: PostSystemDictDataExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postSystemDictDataExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemDictDataExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemDictDataExport>>
>;

export type PostSystemDictDataExportMutationError = unknown;

/**
 * @summary 导出字典数据列表
 */
export const usePostSystemDictDataExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDictDataExport>>,
    TError,
    { params: PostSystemDictDataExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemDictDataExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询字典数据列表
 */
export const getSystemDictDataList = (
  params: MaybeRef<GetSystemDictDataListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象字典数据业务对象>(
    { url: `/system/dict/data/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemDictDataListQueryKey = (
  params: MaybeRef<GetSystemDictDataListParams>,
) => ["system", "dict", "data", "list", ...(params ? [params] : [])] as const;

export const getGetSystemDictDataListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDictDataList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemDictDataListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDictDataList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDictDataList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDictDataListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDictDataList>>
  > = ({ signal }) => getSystemDictDataList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemDictDataListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDictDataList>>
>;
export type GetSystemDictDataListQueryError = void;

/**
 * @summary 查询字典数据列表
 */
export const useGetSystemDictDataList = <
  TData = Awaited<ReturnType<typeof getSystemDictDataList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemDictDataListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDictDataList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDictDataListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 根据字典类型查询字典数据信息
 */
export const getSystemDictDataTypeByDictType = (
  dictType: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List字典数据业务对象>(
    { url: `/system/dict/data/type/${unref(dictType)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemDictDataTypeByDictTypeQueryKey = (
  dictType: MaybeRef<string>,
) => ["system", "dict", "data", "type", dictType] as const;

export const getGetSystemDictDataTypeByDictTypeQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDictDataTypeByDictType>>,
  TError = void,
>(
  dictType: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDictDataTypeByDictType>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDictDataTypeByDictType>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDictDataTypeByDictTypeQueryKey(dictType);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDictDataTypeByDictType>>
  > = ({ signal }) =>
    getSystemDictDataTypeByDictType(dictType, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!dictType, ...queryOptions };
};

export type GetSystemDictDataTypeByDictTypeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDictDataTypeByDictType>>
>;
export type GetSystemDictDataTypeByDictTypeQueryError = void;

/**
 * @summary 根据字典类型查询字典数据信息
 */
export const useGetSystemDictDataTypeByDictType = <
  TData = Awaited<ReturnType<typeof getSystemDictDataTypeByDictType>>,
  TError = void,
>(
  dictType: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDictDataTypeByDictType>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDictDataTypeByDictTypeQueryOptions(
    dictType,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除字典类型
 */
export const deleteSystemDictDataByDictCodes = (
  dictCodes: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/dict/data/${unref(dictCodes)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemDictDataByDictCodesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemDictDataByDictCodes>>,
    TError,
    { dictCodes: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemDictDataByDictCodes>>,
  TError,
  { dictCodes: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemDictDataByDictCodes>>,
    { dictCodes: string }
  > = (props) => {
    const { dictCodes } = props ?? {};

    return deleteSystemDictDataByDictCodes(dictCodes, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemDictDataByDictCodesMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemDictDataByDictCodes>>
>;

export type DeleteSystemDictDataByDictCodesMutationError = unknown;

/**
 * @summary 删除字典类型
 */
export const useDeleteSystemDictDataByDictCodes = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemDictDataByDictCodes>>,
    TError,
    { dictCodes: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteSystemDictDataByDictCodesMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询字典数据详细
 */
export const getSystemDictDataByDictCode = (
  dictCode: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象字典数据业务对象>(
    { url: `/system/dict/data/${unref(dictCode)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemDictDataByDictCodeQueryKey = (
  dictCode: MaybeRef<number>,
) => ["system", "dict", "data", dictCode] as const;

export const getGetSystemDictDataByDictCodeQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDictDataByDictCode>>,
  TError = void,
>(
  dictCode: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDictDataByDictCode>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDictDataByDictCode>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDictDataByDictCodeQueryKey(dictCode);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDictDataByDictCode>>
  > = ({ signal }) =>
    getSystemDictDataByDictCode(dictCode, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!dictCode, ...queryOptions };
};

export type GetSystemDictDataByDictCodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDictDataByDictCode>>
>;
export type GetSystemDictDataByDictCodeQueryError = void;

/**
 * @summary 查询字典数据详细
 */
export const useGetSystemDictDataByDictCode = <
  TData = Awaited<ReturnType<typeof getSystemDictDataByDictCode>>,
  TError = void,
>(
  dictCode: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDictDataByDictCode>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDictDataByDictCodeQueryOptions(
    dictCode,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 修改字典类型
 */
export const putSystemDictType = (
  字典类型业务对象: MaybeRef<字典类型业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/dict/type`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 字典类型业务对象,
    },
    options,
  );
};

export const getPutSystemDictTypeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemDictType>>,
    TError,
    { data: 字典类型业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemDictType>>,
  TError,
  { data: 字典类型业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemDictType>>,
    { data: 字典类型业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemDictType(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemDictTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemDictType>>
>;
export type PutSystemDictTypeMutationBody = 字典类型业务对象;
export type PutSystemDictTypeMutationError = unknown;

/**
 * @summary 修改字典类型
 */
export const usePutSystemDictType = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemDictType>>,
    TError,
    { data: 字典类型业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemDictTypeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增字典类型
 */
export const postSystemDictType = (
  字典类型业务对象: MaybeRef<字典类型业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/dict/type`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 字典类型业务对象,
    },
    options,
  );
};

export const getPostSystemDictTypeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDictType>>,
    TError,
    { data: 字典类型业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemDictType>>,
  TError,
  { data: 字典类型业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemDictType>>,
    { data: 字典类型业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemDictType(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemDictTypeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemDictType>>
>;
export type PostSystemDictTypeMutationBody = 字典类型业务对象;
export type PostSystemDictTypeMutationError = unknown;

/**
 * @summary 新增字典类型
 */
export const usePostSystemDictType = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDictType>>,
    TError,
    { data: 字典类型业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemDictTypeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导出字典类型列表
 */
export const postSystemDictTypeExport = (
  params: MaybeRef<PostSystemDictTypeExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/system/dict/type/export`, method: "post", params },
    options,
  );
};

export const getPostSystemDictTypeExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDictTypeExport>>,
    TError,
    { params: PostSystemDictTypeExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemDictTypeExport>>,
  TError,
  { params: PostSystemDictTypeExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemDictTypeExport>>,
    { params: PostSystemDictTypeExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postSystemDictTypeExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemDictTypeExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemDictTypeExport>>
>;

export type PostSystemDictTypeExportMutationError = unknown;

/**
 * @summary 导出字典类型列表
 */
export const usePostSystemDictTypeExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemDictTypeExport>>,
    TError,
    { params: PostSystemDictTypeExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemDictTypeExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询字典类型列表
 */
export const getSystemDictTypeList = (
  params: MaybeRef<GetSystemDictTypeListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象字典类型业务对象>(
    { url: `/system/dict/type/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemDictTypeListQueryKey = (
  params: MaybeRef<GetSystemDictTypeListParams>,
) => ["system", "dict", "type", "list", ...(params ? [params] : [])] as const;

export const getGetSystemDictTypeListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDictTypeList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemDictTypeListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDictTypeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDictTypeList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDictTypeListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDictTypeList>>
  > = ({ signal }) => getSystemDictTypeList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemDictTypeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDictTypeList>>
>;
export type GetSystemDictTypeListQueryError = void;

/**
 * @summary 查询字典类型列表
 */
export const useGetSystemDictTypeList = <
  TData = Awaited<ReturnType<typeof getSystemDictTypeList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemDictTypeListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDictTypeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDictTypeListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 获取字典选择框列表
 */
export const getSystemDictTypeOptionselect = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List字典类型业务对象>(
    { url: `/system/dict/type/optionselect`, method: "get", signal },
    options,
  );
};

export const getGetSystemDictTypeOptionselectQueryKey = () =>
  ["system", "dict", "type", "optionselect"] as const;

export const getGetSystemDictTypeOptionselectQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDictTypeOptionselect>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSystemDictTypeOptionselect>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDictTypeOptionselect>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDictTypeOptionselectQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDictTypeOptionselect>>
  > = ({ signal }) => getSystemDictTypeOptionselect(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemDictTypeOptionselectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDictTypeOptionselect>>
>;
export type GetSystemDictTypeOptionselectQueryError = void;

/**
 * @summary 获取字典选择框列表
 */
export const useGetSystemDictTypeOptionselect = <
  TData = Awaited<ReturnType<typeof getSystemDictTypeOptionselect>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSystemDictTypeOptionselect>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDictTypeOptionselectQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 刷新字典缓存
 */
export const deleteSystemDictTypeRefreshCache = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/dict/type/refreshCache`, method: "delete" },
    options,
  );
};

export const getDeleteSystemDictTypeRefreshCacheMutationOptions = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemDictTypeRefreshCache>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemDictTypeRefreshCache>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemDictTypeRefreshCache>>,
    TVariables
  > = () => {
    return deleteSystemDictTypeRefreshCache(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemDictTypeRefreshCacheMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemDictTypeRefreshCache>>
>;

export type DeleteSystemDictTypeRefreshCacheMutationError = unknown;

/**
 * @summary 刷新字典缓存
 */
export const useDeleteSystemDictTypeRefreshCache = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemDictTypeRefreshCache>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteSystemDictTypeRefreshCacheMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 删除字典类型
 */
export const deleteSystemDictTypeByDictIds = (
  dictIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/dict/type/${unref(dictIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemDictTypeByDictIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemDictTypeByDictIds>>,
    TError,
    { dictIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemDictTypeByDictIds>>,
  TError,
  { dictIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemDictTypeByDictIds>>,
    { dictIds: string }
  > = (props) => {
    const { dictIds } = props ?? {};

    return deleteSystemDictTypeByDictIds(dictIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemDictTypeByDictIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemDictTypeByDictIds>>
>;

export type DeleteSystemDictTypeByDictIdsMutationError = unknown;

/**
 * @summary 删除字典类型
 */
export const useDeleteSystemDictTypeByDictIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemDictTypeByDictIds>>,
    TError,
    { dictIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteSystemDictTypeByDictIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询字典类型详细
 */
export const getSystemDictTypeByDictId = (
  dictId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象字典类型业务对象>(
    { url: `/system/dict/type/${unref(dictId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemDictTypeByDictIdQueryKey = (
  dictId: MaybeRef<number>,
) => ["system", "dict", "type", dictId] as const;

export const getGetSystemDictTypeByDictIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemDictTypeByDictId>>,
  TError = void,
>(
  dictId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDictTypeByDictId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemDictTypeByDictId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemDictTypeByDictIdQueryKey(dictId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemDictTypeByDictId>>
  > = ({ signal }) => getSystemDictTypeByDictId(dictId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!dictId, ...queryOptions };
};

export type GetSystemDictTypeByDictIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemDictTypeByDictId>>
>;
export type GetSystemDictTypeByDictIdQueryError = void;

/**
 * @summary 查询字典类型详细
 */
export const useGetSystemDictTypeByDictId = <
  TData = Awaited<ReturnType<typeof getSystemDictTypeByDictId>>,
  TError = void,
>(
  dictId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemDictTypeByDictId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemDictTypeByDictIdQueryOptions(
    dictId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 下载模板
 */
export const getDownloadByTemplateName = (
  templateName: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/download/${unref(templateName)}`, method: "get", signal },
    options,
  );
};

export const getGetDownloadByTemplateNameQueryKey = (
  templateName: MaybeRef<string>,
) => ["download", templateName] as const;

export const getGetDownloadByTemplateNameQueryOptions = <
  TData = Awaited<ReturnType<typeof getDownloadByTemplateName>>,
  TError = unknown,
>(
  templateName: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDownloadByTemplateName>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getDownloadByTemplateName>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetDownloadByTemplateNameQueryKey(templateName);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDownloadByTemplateName>>
  > = ({ signal }) =>
    getDownloadByTemplateName(templateName, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!templateName, ...queryOptions };
};

export type GetDownloadByTemplateNameQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDownloadByTemplateName>>
>;
export type GetDownloadByTemplateNameQueryError = unknown;

/**
 * @summary 下载模板
 */
export const useGetDownloadByTemplateName = <
  TData = Awaited<ReturnType<typeof getDownloadByTemplateName>>,
  TError = unknown,
>(
  templateName: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDownloadByTemplateName>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDownloadByTemplateNameQueryOptions(
    templateName,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 下载缩略图
 */
export const getDownloadThumbnail = (
  params: MaybeRef<GetDownloadThumbnailParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/downloadThumbnail`, method: "get", params, signal },
    options,
  );
};

export const getGetDownloadThumbnailQueryKey = (
  params: MaybeRef<GetDownloadThumbnailParams>,
) => ["downloadThumbnail", ...(params ? [params] : [])] as const;

export const getGetDownloadThumbnailQueryOptions = <
  TData = Awaited<ReturnType<typeof getDownloadThumbnail>>,
  TError = unknown,
>(
  params: MaybeRef<GetDownloadThumbnailParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDownloadThumbnail>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getDownloadThumbnail>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetDownloadThumbnailQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDownloadThumbnail>>
  > = ({ signal }) => getDownloadThumbnail(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDownloadThumbnailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDownloadThumbnail>>
>;
export type GetDownloadThumbnailQueryError = unknown;

/**
 * @summary 下载缩略图
 */
export const useGetDownloadThumbnail = <
  TData = Awaited<ReturnType<typeof getDownloadThumbnail>>,
  TError = unknown,
>(
  params: MaybeRef<GetDownloadThumbnailParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDownloadThumbnail>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDownloadThumbnailQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 上传文件
 */
export const postUpload = (
  postUploadBody: MaybeRef<Blob>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象String | void>(
    {
      url: `/upload`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: postUploadBody,
    },
    options,
  );
};

export const getPostUploadMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUpload>>,
    TError,
    { data: Blob },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postUpload>>,
  TError,
  { data: Blob },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postUpload>>,
    { data: Blob }
  > = (props) => {
    const { data } = props ?? {};

    return postUpload(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof postUpload>>
>;
export type PostUploadMutationBody = Blob;
export type PostUploadMutationError = unknown;

/**
 * @summary 上传文件
 */
export const usePostUpload = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postUpload>>,
    TError,
    { data: Blob },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostUploadMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 访问首页，提示语
 */
export const get = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<string>({ url: `/`, method: "get", signal }, options);
};

export const getGetQueryKey = () => [] as const;

export const getGetQueryOptions = <
  TData = Awaited<ReturnType<typeof get>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof get>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<Awaited<ReturnType<typeof get>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof get>>> = ({
    signal,
  }) => get(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetQueryResult = NonNullable<Awaited<ReturnType<typeof get>>>;
export type GetQueryError = void;

/**
 * @summary 访问首页，提示语
 */
export const useGet = <
  TData = Awaited<ReturnType<typeof get>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof get>>, TError, TData>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 获取用户信息
 */
export const getGetInfo = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象MapStringObject>(
    { url: `/getInfo`, method: "get", signal },
    options,
  );
};

export const getGetGetInfoQueryKey = () => ["getInfo"] as const;

export const getGetGetInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getGetInfo>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getGetInfo>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<Awaited<ReturnType<typeof getGetInfo>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetGetInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGetInfo>>> = ({
    signal,
  }) => getGetInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGetInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGetInfo>>
>;
export type GetGetInfoQueryError = void;

/**
 * @summary 获取用户信息
 */
export const useGetGetInfo = <
  TData = Awaited<ReturnType<typeof getGetInfo>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getGetInfo>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGetInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 获取路由信息
 */
export const getGetRouters = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List路由配置信息>(
    { url: `/getRouters`, method: "get", signal },
    options,
  );
};

export const getGetGetRoutersQueryKey = () => ["getRouters"] as const;

export const getGetGetRoutersQueryOptions = <
  TData = Awaited<ReturnType<typeof getGetRouters>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getGetRouters>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getGetRouters>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetGetRoutersQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGetRouters>>> = ({
    signal,
  }) => getGetRouters(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetGetRoutersQueryResult = NonNullable<
  Awaited<ReturnType<typeof getGetRouters>>
>;
export type GetGetRoutersQueryError = void;

/**
 * @summary 获取路由信息
 */
export const useGetGetRouters = <
  TData = Awaited<ReturnType<typeof getGetRouters>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getGetRouters>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetGetRoutersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 登录方法
 */
export const postLogin = (
  用户登录对象: MaybeRef<用户登录对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象MapStringObject | void>(
    {
      url: `/login`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 用户登录对象,
    },
    options,
  );
};

export const getPostLoginMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLogin>>,
    TError,
    { data: 用户登录对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLogin>>,
  TError,
  { data: 用户登录对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLogin>>,
    { data: 用户登录对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postLogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLogin>>
>;
export type PostLoginMutationBody = 用户登录对象;
export type PostLoginMutationError = unknown;

/**
 * @summary 登录方法
 */
export const usePostLogin = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLogin>>,
    TError,
    { data: 用户登录对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostLoginMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 登出方法
 */
export const postLogout = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/logout`, method: "post" },
    options,
  );
};

export const getPostLogoutMutationOptions = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLogout>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postLogout>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postLogout>>,
    TVariables
  > = () => {
    return postLogout(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostLogoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof postLogout>>
>;

export type PostLogoutMutationError = unknown;

/**
 * @summary 登出方法
 */
export const usePostLogout = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postLogout>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostLogoutMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 清空系统访问记录
 */
export const deleteMonitorLogininforClean = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/monitor/logininfor/clean`, method: "delete" },
    options,
  );
};

export const getDeleteMonitorLogininforCleanMutationOptions = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorLogininforClean>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMonitorLogininforClean>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMonitorLogininforClean>>,
    TVariables
  > = () => {
    return deleteMonitorLogininforClean(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMonitorLogininforCleanMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMonitorLogininforClean>>
>;

export type DeleteMonitorLogininforCleanMutationError = unknown;

/**
 * @summary 清空系统访问记录
 */
export const useDeleteMonitorLogininforClean = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorLogininforClean>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteMonitorLogininforCleanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导出系统访问记录列表
 */
export const postMonitorLogininforExport = (
  params?: MaybeRef<PostMonitorLogininforExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/monitor/logininfor/export`, method: "post", params },
    options,
  );
};

export const getPostMonitorLogininforExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postMonitorLogininforExport>>,
    TError,
    { params?: PostMonitorLogininforExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postMonitorLogininforExport>>,
  TError,
  { params?: PostMonitorLogininforExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postMonitorLogininforExport>>,
    { params?: PostMonitorLogininforExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postMonitorLogininforExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostMonitorLogininforExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postMonitorLogininforExport>>
>;

export type PostMonitorLogininforExportMutationError = unknown;

/**
 * @summary 导出系统访问记录列表
 */
export const usePostMonitorLogininforExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postMonitorLogininforExport>>,
    TError,
    { params?: PostMonitorLogininforExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostMonitorLogininforExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询系统访问记录列表
 */
export const getMonitorLogininforList = (
  params?: MaybeRef<GetMonitorLogininforListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象系统访问记录业务对象>(
    { url: `/monitor/logininfor/list`, method: "get", params, signal },
    options,
  );
};

export const getGetMonitorLogininforListQueryKey = (
  params?: MaybeRef<GetMonitorLogininforListParams>,
) => ["monitor", "logininfor", "list", ...(params ? [params] : [])] as const;

export const getGetMonitorLogininforListQueryOptions = <
  TData = Awaited<ReturnType<typeof getMonitorLogininforList>>,
  TError = void,
>(
  params?: MaybeRef<GetMonitorLogininforListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMonitorLogininforList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getMonitorLogininforList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetMonitorLogininforListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMonitorLogininforList>>
  > = ({ signal }) => getMonitorLogininforList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetMonitorLogininforListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMonitorLogininforList>>
>;
export type GetMonitorLogininforListQueryError = void;

/**
 * @summary 查询系统访问记录列表
 */
export const useGetMonitorLogininforList = <
  TData = Awaited<ReturnType<typeof getMonitorLogininforList>>,
  TError = void,
>(
  params?: MaybeRef<GetMonitorLogininforListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMonitorLogininforList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMonitorLogininforListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除系统访问记录
 */
export const deleteMonitorLogininforByInfoIds = (
  infoIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/monitor/logininfor/${unref(infoIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteMonitorLogininforByInfoIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorLogininforByInfoIds>>,
    TError,
    { infoIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMonitorLogininforByInfoIds>>,
  TError,
  { infoIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMonitorLogininforByInfoIds>>,
    { infoIds: string }
  > = (props) => {
    const { infoIds } = props ?? {};

    return deleteMonitorLogininforByInfoIds(infoIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMonitorLogininforByInfoIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMonitorLogininforByInfoIds>>
>;

export type DeleteMonitorLogininforByInfoIdsMutationError = unknown;

/**
 * @summary 删除系统访问记录
 */
export const useDeleteMonitorLogininforByInfoIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorLogininforByInfoIds>>,
    TError,
    { infoIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteMonitorLogininforByInfoIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改菜单
 */
export const putSystemMenu = (
  菜单权限业务对象: MaybeRef<菜单权限业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/menu`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 菜单权限业务对象,
    },
    options,
  );
};

export const getPutSystemMenuMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemMenu>>,
    TError,
    { data: 菜单权限业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemMenu>>,
  TError,
  { data: 菜单权限业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemMenu>>,
    { data: 菜单权限业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemMenu(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemMenuMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemMenu>>
>;
export type PutSystemMenuMutationBody = 菜单权限业务对象;
export type PutSystemMenuMutationError = unknown;

/**
 * @summary 修改菜单
 */
export const usePutSystemMenu = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemMenu>>,
    TError,
    { data: 菜单权限业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemMenuMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增菜单
 */
export const postSystemMenu = (
  菜单权限业务对象: MaybeRef<菜单权限业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/menu`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 菜单权限业务对象,
    },
    options,
  );
};

export const getPostSystemMenuMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemMenu>>,
    TError,
    { data: 菜单权限业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemMenu>>,
  TError,
  { data: 菜单权限业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemMenu>>,
    { data: 菜单权限业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemMenu(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemMenuMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemMenu>>
>;
export type PostSystemMenuMutationBody = 菜单权限业务对象;
export type PostSystemMenuMutationError = unknown;

/**
 * @summary 新增菜单
 */
export const usePostSystemMenu = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemMenu>>,
    TError,
    { data: 菜单权限业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemMenuMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取菜单列表
 */
export const getSystemMenuList = (
  params: MaybeRef<GetSystemMenuListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List菜单权限业务对象>(
    { url: `/system/menu/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemMenuListQueryKey = (
  params: MaybeRef<GetSystemMenuListParams>,
) => ["system", "menu", "list", ...(params ? [params] : [])] as const;

export const getGetSystemMenuListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemMenuList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemMenuListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemMenuList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemMenuList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemMenuListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemMenuList>>
  > = ({ signal }) => getSystemMenuList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemMenuListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemMenuList>>
>;
export type GetSystemMenuListQueryError = void;

/**
 * @summary 获取菜单列表
 */
export const useGetSystemMenuList = <
  TData = Awaited<ReturnType<typeof getSystemMenuList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemMenuListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemMenuList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemMenuListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 加载对应角色菜单列表树
 */
export const getSystemMenuRoleMenuTreeselect = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象MapStringObject>(
    { url: `/system/menu/roleMenuTreeselect`, method: "get", signal },
    options,
  );
};

export const getGetSystemMenuRoleMenuTreeselectQueryKey = () =>
  ["system", "menu", "roleMenuTreeselect"] as const;

export const getGetSystemMenuRoleMenuTreeselectQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemMenuRoleMenuTreeselect>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSystemMenuRoleMenuTreeselect>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemMenuRoleMenuTreeselect>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemMenuRoleMenuTreeselectQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemMenuRoleMenuTreeselect>>
  > = ({ signal }) => getSystemMenuRoleMenuTreeselect(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemMenuRoleMenuTreeselectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemMenuRoleMenuTreeselect>>
>;
export type GetSystemMenuRoleMenuTreeselectQueryError = void;

/**
 * @summary 加载对应角色菜单列表树
 */
export const useGetSystemMenuRoleMenuTreeselect = <
  TData = Awaited<ReturnType<typeof getSystemMenuRoleMenuTreeselect>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSystemMenuRoleMenuTreeselect>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemMenuRoleMenuTreeselectQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 获取菜单下拉树列表
 */
export const getSystemMenuTreeselect = (
  params: MaybeRef<GetSystemMenuTreeselectParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象ListTreeLong>(
    { url: `/system/menu/treeselect`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemMenuTreeselectQueryKey = (
  params: MaybeRef<GetSystemMenuTreeselectParams>,
) => ["system", "menu", "treeselect", ...(params ? [params] : [])] as const;

export const getGetSystemMenuTreeselectQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemMenuTreeselect>>,
  TError = void,
>(
  params: MaybeRef<GetSystemMenuTreeselectParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemMenuTreeselect>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemMenuTreeselect>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemMenuTreeselectQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemMenuTreeselect>>
  > = ({ signal }) => getSystemMenuTreeselect(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemMenuTreeselectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemMenuTreeselect>>
>;
export type GetSystemMenuTreeselectQueryError = void;

/**
 * @summary 获取菜单下拉树列表
 */
export const useGetSystemMenuTreeselect = <
  TData = Awaited<ReturnType<typeof getSystemMenuTreeselect>>,
  TError = void,
>(
  params: MaybeRef<GetSystemMenuTreeselectParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemMenuTreeselect>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemMenuTreeselectQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 根据菜单编号获取详细信息
 */
export const getSystemMenuByMenuId = (
  menuId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象菜单权限业务对象>(
    { url: `/system/menu/${unref(menuId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemMenuByMenuIdQueryKey = (menuId: MaybeRef<number>) =>
  ["system", "menu", menuId] as const;

export const getGetSystemMenuByMenuIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemMenuByMenuId>>,
  TError = void,
>(
  menuId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemMenuByMenuId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemMenuByMenuId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemMenuByMenuIdQueryKey(menuId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemMenuByMenuId>>
  > = ({ signal }) => getSystemMenuByMenuId(menuId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!menuId, ...queryOptions };
};

export type GetSystemMenuByMenuIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemMenuByMenuId>>
>;
export type GetSystemMenuByMenuIdQueryError = void;

/**
 * @summary 根据菜单编号获取详细信息
 */
export const useGetSystemMenuByMenuId = <
  TData = Awaited<ReturnType<typeof getSystemMenuByMenuId>>,
  TError = void,
>(
  menuId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemMenuByMenuId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemMenuByMenuIdQueryOptions(menuId, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除菜单
 */
export const deleteSystemMenuByMenuId = (
  menuId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/menu/${unref(menuId)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemMenuByMenuIdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemMenuByMenuId>>,
    TError,
    { menuId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemMenuByMenuId>>,
  TError,
  { menuId: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemMenuByMenuId>>,
    { menuId: number }
  > = (props) => {
    const { menuId } = props ?? {};

    return deleteSystemMenuByMenuId(menuId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemMenuByMenuIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemMenuByMenuId>>
>;

export type DeleteSystemMenuByMenuIdMutationError = unknown;

/**
 * @summary 删除菜单
 */
export const useDeleteSystemMenuByMenuId = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemMenuByMenuId>>,
    TError,
    { menuId: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteSystemMenuByMenuIdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 消息已读
 */
export const getSystemMsgHasRead = (
  params: MaybeRef<GetSystemMsgHasReadParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<string>(
    { url: `/system/msg/hasRead`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemMsgHasReadQueryKey = (
  params: MaybeRef<GetSystemMsgHasReadParams>,
) => ["system", "msg", "hasRead", ...(params ? [params] : [])] as const;

export const getGetSystemMsgHasReadQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemMsgHasRead>>,
  TError = void,
>(
  params: MaybeRef<GetSystemMsgHasReadParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemMsgHasRead>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemMsgHasRead>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemMsgHasReadQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemMsgHasRead>>
  > = ({ signal }) => getSystemMsgHasRead(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemMsgHasReadQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemMsgHasRead>>
>;
export type GetSystemMsgHasReadQueryError = void;

/**
 * @summary 消息已读
 */
export const useGetSystemMsgHasRead = <
  TData = Awaited<ReturnType<typeof getSystemMsgHasRead>>,
  TError = void,
>(
  params: MaybeRef<GetSystemMsgHasReadParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemMsgHasRead>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemMsgHasReadQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除消息
 */
export const deleteSystemMsgByMsgIds = (
  msgIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/msg/${unref(msgIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemMsgByMsgIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemMsgByMsgIds>>,
    TError,
    { msgIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemMsgByMsgIds>>,
  TError,
  { msgIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemMsgByMsgIds>>,
    { msgIds: string }
  > = (props) => {
    const { msgIds } = props ?? {};

    return deleteSystemMsgByMsgIds(msgIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemMsgByMsgIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemMsgByMsgIds>>
>;

export type DeleteSystemMsgByMsgIdsMutationError = unknown;

/**
 * @summary 删除消息
 */
export const useDeleteSystemMsgByMsgIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemMsgByMsgIds>>,
    TError,
    { msgIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteSystemMsgByMsgIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改通知公告
 */
export const putSystemNotice = (
  sysNotice: MaybeRef<SysNotice>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/notice`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: sysNotice,
    },
    options,
  );
};

export const getPutSystemNoticeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemNotice>>,
    TError,
    { data: SysNotice },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemNotice>>,
  TError,
  { data: SysNotice },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemNotice>>,
    { data: SysNotice }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemNotice(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemNoticeMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemNotice>>
>;
export type PutSystemNoticeMutationBody = SysNotice;
export type PutSystemNoticeMutationError = unknown;

/**
 * @summary 修改通知公告
 */
export const usePutSystemNotice = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemNotice>>,
    TError,
    { data: SysNotice },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemNoticeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增通知公告
 */
export const postSystemNotice = (
  sysNotice: MaybeRef<SysNotice>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/notice`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: sysNotice,
    },
    options,
  );
};

export const getPostSystemNoticeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemNotice>>,
    TError,
    { data: SysNotice },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemNotice>>,
  TError,
  { data: SysNotice },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemNotice>>,
    { data: SysNotice }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemNotice(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemNoticeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemNotice>>
>;
export type PostSystemNoticeMutationBody = SysNotice;
export type PostSystemNoticeMutationError = unknown;

/**
 * @summary 新增通知公告
 */
export const usePostSystemNotice = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemNotice>>,
    TError,
    { data: SysNotice },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemNoticeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取通知公告列表
 */
export const getSystemNoticeList = (
  params: MaybeRef<GetSystemNoticeListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象SysNotice>(
    { url: `/system/notice/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemNoticeListQueryKey = (
  params: MaybeRef<GetSystemNoticeListParams>,
) => ["system", "notice", "list", ...(params ? [params] : [])] as const;

export const getGetSystemNoticeListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemNoticeList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemNoticeListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemNoticeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemNoticeList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemNoticeListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemNoticeList>>
  > = ({ signal }) => getSystemNoticeList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemNoticeListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemNoticeList>>
>;
export type GetSystemNoticeListQueryError = void;

/**
 * @summary 获取通知公告列表
 */
export const useGetSystemNoticeList = <
  TData = Awaited<ReturnType<typeof getSystemNoticeList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemNoticeListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemNoticeList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemNoticeListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 通知党员详情
 */
export const getSystemNoticeNoticeDetail = (
  params?: MaybeRef<GetSystemNoticeNoticeDetailParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象消息视图对象>(
    { url: `/system/notice/noticeDetail`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemNoticeNoticeDetailQueryKey = (
  params?: MaybeRef<GetSystemNoticeNoticeDetailParams>,
) => ["system", "notice", "noticeDetail", ...(params ? [params] : [])] as const;

export const getGetSystemNoticeNoticeDetailQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemNoticeNoticeDetail>>,
  TError = void,
>(
  params?: MaybeRef<GetSystemNoticeNoticeDetailParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemNoticeNoticeDetail>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemNoticeNoticeDetail>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemNoticeNoticeDetailQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemNoticeNoticeDetail>>
  > = ({ signal }) =>
    getSystemNoticeNoticeDetail(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemNoticeNoticeDetailQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemNoticeNoticeDetail>>
>;
export type GetSystemNoticeNoticeDetailQueryError = void;

/**
 * @summary 通知党员详情
 */
export const useGetSystemNoticeNoticeDetail = <
  TData = Awaited<ReturnType<typeof getSystemNoticeNoticeDetail>>,
  TError = void,
>(
  params?: MaybeRef<GetSystemNoticeNoticeDetailParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemNoticeNoticeDetail>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemNoticeNoticeDetailQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 点击推送通知
 */
export const postSystemNoticePushNotice = (
  postSystemNoticePushNoticeBody: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/notice/pushNotice`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: postSystemNoticePushNoticeBody,
    },
    options,
  );
};

export const getPostSystemNoticePushNoticeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemNoticePushNotice>>,
    TError,
    { data: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemNoticePushNotice>>,
  TError,
  { data: number },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemNoticePushNotice>>,
    { data: number }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemNoticePushNotice(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemNoticePushNoticeMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemNoticePushNotice>>
>;
export type PostSystemNoticePushNoticeMutationBody = number;
export type PostSystemNoticePushNoticeMutationError = unknown;

/**
 * @summary 点击推送通知
 */
export const usePostSystemNoticePushNotice = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemNoticePushNotice>>,
    TError,
    { data: number },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemNoticePushNoticeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 删除通知公告
 */
export const deleteSystemNoticeByNoticeIds = (
  noticeIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/notice/${unref(noticeIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemNoticeByNoticeIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemNoticeByNoticeIds>>,
    TError,
    { noticeIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemNoticeByNoticeIds>>,
  TError,
  { noticeIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemNoticeByNoticeIds>>,
    { noticeIds: string }
  > = (props) => {
    const { noticeIds } = props ?? {};

    return deleteSystemNoticeByNoticeIds(noticeIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemNoticeByNoticeIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemNoticeByNoticeIds>>
>;

export type DeleteSystemNoticeByNoticeIdsMutationError = unknown;

/**
 * @summary 删除通知公告
 */
export const useDeleteSystemNoticeByNoticeIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemNoticeByNoticeIds>>,
    TError,
    { noticeIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteSystemNoticeByNoticeIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 根据通知公告编号获取详细信息
 */
export const getSystemNoticeByNoticeId = (
  noticeId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象SysNotice>(
    { url: `/system/notice/${unref(noticeId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemNoticeByNoticeIdQueryKey = (
  noticeId: MaybeRef<number>,
) => ["system", "notice", noticeId] as const;

export const getGetSystemNoticeByNoticeIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemNoticeByNoticeId>>,
  TError = void,
>(
  noticeId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemNoticeByNoticeId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemNoticeByNoticeId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemNoticeByNoticeIdQueryKey(noticeId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemNoticeByNoticeId>>
  > = ({ signal }) =>
    getSystemNoticeByNoticeId(noticeId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!noticeId, ...queryOptions };
};

export type GetSystemNoticeByNoticeIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemNoticeByNoticeId>>
>;
export type GetSystemNoticeByNoticeIdQueryError = void;

/**
 * @summary 根据通知公告编号获取详细信息
 */
export const useGetSystemNoticeByNoticeId = <
  TData = Awaited<ReturnType<typeof getSystemNoticeByNoticeId>>,
  TError = void,
>(
  noticeId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemNoticeByNoticeId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemNoticeByNoticeIdQueryOptions(
    noticeId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 清空操作日志记录
 */
export const deleteMonitorOperlogClean = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/monitor/operlog/clean`, method: "delete" },
    options,
  );
};

export const getDeleteMonitorOperlogCleanMutationOptions = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorOperlogClean>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMonitorOperlogClean>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMonitorOperlogClean>>,
    TVariables
  > = () => {
    return deleteMonitorOperlogClean(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMonitorOperlogCleanMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMonitorOperlogClean>>
>;

export type DeleteMonitorOperlogCleanMutationError = unknown;

/**
 * @summary 清空操作日志记录
 */
export const useDeleteMonitorOperlogClean = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorOperlogClean>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteMonitorOperlogCleanMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导出操作日志记录列表
 */
export const postMonitorOperlogExport = (
  params?: MaybeRef<PostMonitorOperlogExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/monitor/operlog/export`, method: "post", params },
    options,
  );
};

export const getPostMonitorOperlogExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postMonitorOperlogExport>>,
    TError,
    { params?: PostMonitorOperlogExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postMonitorOperlogExport>>,
  TError,
  { params?: PostMonitorOperlogExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postMonitorOperlogExport>>,
    { params?: PostMonitorOperlogExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postMonitorOperlogExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostMonitorOperlogExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postMonitorOperlogExport>>
>;

export type PostMonitorOperlogExportMutationError = unknown;

/**
 * @summary 导出操作日志记录列表
 */
export const usePostMonitorOperlogExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postMonitorOperlogExport>>,
    TError,
    { params?: PostMonitorOperlogExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostMonitorOperlogExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询操作日志记录列表
 */
export const getMonitorOperlogList = (
  params?: MaybeRef<GetMonitorOperlogListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象操作日志记录业务对象>(
    { url: `/monitor/operlog/list`, method: "get", params, signal },
    options,
  );
};

export const getGetMonitorOperlogListQueryKey = (
  params?: MaybeRef<GetMonitorOperlogListParams>,
) => ["monitor", "operlog", "list", ...(params ? [params] : [])] as const;

export const getGetMonitorOperlogListQueryOptions = <
  TData = Awaited<ReturnType<typeof getMonitorOperlogList>>,
  TError = void,
>(
  params?: MaybeRef<GetMonitorOperlogListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMonitorOperlogList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getMonitorOperlogList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetMonitorOperlogListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMonitorOperlogList>>
  > = ({ signal }) => getMonitorOperlogList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetMonitorOperlogListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMonitorOperlogList>>
>;
export type GetMonitorOperlogListQueryError = void;

/**
 * @summary 查询操作日志记录列表
 */
export const useGetMonitorOperlogList = <
  TData = Awaited<ReturnType<typeof getMonitorOperlogList>>,
  TError = void,
>(
  params?: MaybeRef<GetMonitorOperlogListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMonitorOperlogList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMonitorOperlogListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除操作日志记录
 */
export const deleteMonitorOperlogByOperIds = (
  operIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/monitor/operlog/${unref(operIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteMonitorOperlogByOperIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorOperlogByOperIds>>,
    TError,
    { operIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMonitorOperlogByOperIds>>,
  TError,
  { operIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMonitorOperlogByOperIds>>,
    { operIds: string }
  > = (props) => {
    const { operIds } = props ?? {};

    return deleteMonitorOperlogByOperIds(operIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMonitorOperlogByOperIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMonitorOperlogByOperIds>>
>;

export type DeleteMonitorOperlogByOperIdsMutationError = unknown;

/**
 * @summary 删除操作日志记录
 */
export const useDeleteMonitorOperlogByOperIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorOperlogByOperIds>>,
    TError,
    { operIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteMonitorOperlogByOperIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改对象存储配置
 */
export const putSystemOssConfig = (
  对象存储配置业务对象: MaybeRef<对象存储配置业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/oss/config`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 对象存储配置业务对象,
    },
    options,
  );
};

export const getPutSystemOssConfigMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemOssConfig>>,
    TError,
    { data: 对象存储配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemOssConfig>>,
  TError,
  { data: 对象存储配置业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemOssConfig>>,
    { data: 对象存储配置业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemOssConfig(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemOssConfigMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemOssConfig>>
>;
export type PutSystemOssConfigMutationBody = 对象存储配置业务对象;
export type PutSystemOssConfigMutationError = unknown;

/**
 * @summary 修改对象存储配置
 */
export const usePutSystemOssConfig = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemOssConfig>>,
    TError,
    { data: 对象存储配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemOssConfigMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增对象存储配置
 */
export const postSystemOssConfig = (
  对象存储配置业务对象: MaybeRef<对象存储配置业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/oss/config`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 对象存储配置业务对象,
    },
    options,
  );
};

export const getPostSystemOssConfigMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemOssConfig>>,
    TError,
    { data: 对象存储配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemOssConfig>>,
  TError,
  { data: 对象存储配置业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemOssConfig>>,
    { data: 对象存储配置业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemOssConfig(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemOssConfigMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemOssConfig>>
>;
export type PostSystemOssConfigMutationBody = 对象存储配置业务对象;
export type PostSystemOssConfigMutationError = unknown;

/**
 * @summary 新增对象存储配置
 */
export const usePostSystemOssConfig = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemOssConfig>>,
    TError,
    { data: 对象存储配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemOssConfigMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 状态修改
 */
export const putSystemOssConfigChangeStatus = (
  对象存储配置业务对象: MaybeRef<对象存储配置业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/oss/config/changeStatus`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 对象存储配置业务对象,
    },
    options,
  );
};

export const getPutSystemOssConfigChangeStatusMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemOssConfigChangeStatus>>,
    TError,
    { data: 对象存储配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemOssConfigChangeStatus>>,
  TError,
  { data: 对象存储配置业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemOssConfigChangeStatus>>,
    { data: 对象存储配置业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemOssConfigChangeStatus(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemOssConfigChangeStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemOssConfigChangeStatus>>
>;
export type PutSystemOssConfigChangeStatusMutationBody = 对象存储配置业务对象;
export type PutSystemOssConfigChangeStatusMutationError = unknown;

/**
 * @summary 状态修改
 */
export const usePutSystemOssConfigChangeStatus = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemOssConfigChangeStatus>>,
    TError,
    { data: 对象存储配置业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPutSystemOssConfigChangeStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询对象存储配置列表
 */
export const getSystemOssConfigList = (
  params: MaybeRef<GetSystemOssConfigListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象对象存储配置视图对象>(
    { url: `/system/oss/config/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemOssConfigListQueryKey = (
  params: MaybeRef<GetSystemOssConfigListParams>,
) => ["system", "oss", "config", "list", ...(params ? [params] : [])] as const;

export const getGetSystemOssConfigListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemOssConfigList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemOssConfigListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemOssConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemOssConfigList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemOssConfigListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemOssConfigList>>
  > = ({ signal }) => getSystemOssConfigList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemOssConfigListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemOssConfigList>>
>;
export type GetSystemOssConfigListQueryError = void;

/**
 * @summary 查询对象存储配置列表
 */
export const useGetSystemOssConfigList = <
  TData = Awaited<ReturnType<typeof getSystemOssConfigList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemOssConfigListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemOssConfigList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemOssConfigListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除对象存储配置
 */
export const deleteSystemOssConfigByOssConfigIds = (
  ossConfigIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/oss/config/${unref(ossConfigIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemOssConfigByOssConfigIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemOssConfigByOssConfigIds>>,
    TError,
    { ossConfigIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemOssConfigByOssConfigIds>>,
  TError,
  { ossConfigIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemOssConfigByOssConfigIds>>,
    { ossConfigIds: string }
  > = (props) => {
    const { ossConfigIds } = props ?? {};

    return deleteSystemOssConfigByOssConfigIds(ossConfigIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemOssConfigByOssConfigIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemOssConfigByOssConfigIds>>
>;

export type DeleteSystemOssConfigByOssConfigIdsMutationError = unknown;

/**
 * @summary 删除对象存储配置
 */
export const useDeleteSystemOssConfigByOssConfigIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemOssConfigByOssConfigIds>>,
    TError,
    { ossConfigIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteSystemOssConfigByOssConfigIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取对象存储配置详细信息
 */
export const getSystemOssConfigByOssConfigId = (
  ossConfigId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象对象存储配置视图对象>(
    { url: `/system/oss/config/${unref(ossConfigId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemOssConfigByOssConfigIdQueryKey = (
  ossConfigId: MaybeRef<number>,
) => ["system", "oss", "config", ossConfigId] as const;

export const getGetSystemOssConfigByOssConfigIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemOssConfigByOssConfigId>>,
  TError = void,
>(
  ossConfigId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemOssConfigByOssConfigId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemOssConfigByOssConfigId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemOssConfigByOssConfigIdQueryKey(ossConfigId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemOssConfigByOssConfigId>>
  > = ({ signal }) =>
    getSystemOssConfigByOssConfigId(ossConfigId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!ossConfigId, ...queryOptions };
};

export type GetSystemOssConfigByOssConfigIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemOssConfigByOssConfigId>>
>;
export type GetSystemOssConfigByOssConfigIdQueryError = void;

/**
 * @summary 获取对象存储配置详细信息
 */
export const useGetSystemOssConfigByOssConfigId = <
  TData = Awaited<ReturnType<typeof getSystemOssConfigByOssConfigId>>,
  TError = void,
>(
  ossConfigId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemOssConfigByOssConfigId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemOssConfigByOssConfigIdQueryOptions(
    ossConfigId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 下载OSS对象存储
 */
export const getSystemOssDownloadByOssId = (
  ossId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<void>(
    { url: `/system/oss/download/${unref(ossId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemOssDownloadByOssIdQueryKey = (
  ossId: MaybeRef<number>,
) => ["system", "oss", "download", ossId] as const;

export const getGetSystemOssDownloadByOssIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemOssDownloadByOssId>>,
  TError = unknown,
>(
  ossId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemOssDownloadByOssId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemOssDownloadByOssId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemOssDownloadByOssIdQueryKey(ossId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemOssDownloadByOssId>>
  > = ({ signal }) =>
    getSystemOssDownloadByOssId(ossId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!ossId, ...queryOptions };
};

export type GetSystemOssDownloadByOssIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemOssDownloadByOssId>>
>;
export type GetSystemOssDownloadByOssIdQueryError = unknown;

/**
 * @summary 下载OSS对象存储
 */
export const useGetSystemOssDownloadByOssId = <
  TData = Awaited<ReturnType<typeof getSystemOssDownloadByOssId>>,
  TError = unknown,
>(
  ossId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemOssDownloadByOssId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemOssDownloadByOssIdQueryOptions(
    ossId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 查询OSS对象存储列表
 */
export const getSystemOssList = (
  params?: MaybeRef<GetSystemOssListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象Oss对象存储视图对象>(
    { url: `/system/oss/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemOssListQueryKey = (
  params?: MaybeRef<GetSystemOssListParams>,
) => ["system", "oss", "list", ...(params ? [params] : [])] as const;

export const getGetSystemOssListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemOssList>>,
  TError = void,
>(
  params?: MaybeRef<GetSystemOssListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemOssList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemOssList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemOssListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemOssList>>
  > = ({ signal }) => getSystemOssList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemOssListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemOssList>>
>;
export type GetSystemOssListQueryError = void;

/**
 * @summary 查询OSS对象存储列表
 */
export const useGetSystemOssList = <
  TData = Awaited<ReturnType<typeof getSystemOssList>>,
  TError = void,
>(
  params?: MaybeRef<GetSystemOssListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemOssList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemOssListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 上传OSS对象存储
 */
export const postSystemOssUpload = (
  postSystemOssUploadBody: MaybeRef<PostSystemOssUploadBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData();
  formData.append("file", postSystemOssUploadBody.file);

  return customInstance<请求响应对象MapStringString | void>(
    {
      url: `/system/oss/upload`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getPostSystemOssUploadMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemOssUpload>>,
    TError,
    { data: PostSystemOssUploadBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemOssUpload>>,
  TError,
  { data: PostSystemOssUploadBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemOssUpload>>,
    { data: PostSystemOssUploadBody }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemOssUpload(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemOssUploadMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemOssUpload>>
>;
export type PostSystemOssUploadMutationBody = PostSystemOssUploadBody;
export type PostSystemOssUploadMutationError = unknown;

/**
 * @summary 上传OSS对象存储
 */
export const usePostSystemOssUpload = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemOssUpload>>,
    TError,
    { data: PostSystemOssUploadBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemOssUploadMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 删除OSS对象存储
 */
export const deleteSystemOssByOssIds = (
  ossIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/oss/${unref(ossIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemOssByOssIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemOssByOssIds>>,
    TError,
    { ossIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemOssByOssIds>>,
  TError,
  { ossIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemOssByOssIds>>,
    { ossIds: string }
  > = (props) => {
    const { ossIds } = props ?? {};

    return deleteSystemOssByOssIds(ossIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemOssByOssIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemOssByOssIds>>
>;

export type DeleteSystemOssByOssIdsMutationError = unknown;

/**
 * @summary 删除OSS对象存储
 */
export const useDeleteSystemOssByOssIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemOssByOssIds>>,
    TError,
    { ossIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteSystemOssByOssIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改岗位
 */
export const putSystemPost = (
  岗位信息业务对象: MaybeRef<岗位信息业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/post`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 岗位信息业务对象,
    },
    options,
  );
};

export const getPutSystemPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemPost>>,
    TError,
    { data: 岗位信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemPost>>,
  TError,
  { data: 岗位信息业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemPost>>,
    { data: 岗位信息业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemPost>>
>;
export type PutSystemPostMutationBody = 岗位信息业务对象;
export type PutSystemPostMutationError = unknown;

/**
 * @summary 修改岗位
 */
export const usePutSystemPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemPost>>,
    TError,
    { data: 岗位信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增岗位
 */
export const postSystemPost = (
  岗位信息业务对象: MaybeRef<岗位信息业务对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/post`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 岗位信息业务对象,
    },
    options,
  );
};

export const getPostSystemPostMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemPost>>,
    TError,
    { data: 岗位信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemPost>>,
  TError,
  { data: 岗位信息业务对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemPost>>,
    { data: 岗位信息业务对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemPost(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemPostMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemPost>>
>;
export type PostSystemPostMutationBody = 岗位信息业务对象;
export type PostSystemPostMutationError = unknown;

/**
 * @summary 新增岗位
 */
export const usePostSystemPost = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemPost>>,
    TError,
    { data: 岗位信息业务对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemPostMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导出岗位列表
 */
export const postSystemPostExport = (
  params: MaybeRef<PostSystemPostExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/system/post/export`, method: "post", params },
    options,
  );
};

export const getPostSystemPostExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemPostExport>>,
    TError,
    { params: PostSystemPostExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemPostExport>>,
  TError,
  { params: PostSystemPostExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemPostExport>>,
    { params: PostSystemPostExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postSystemPostExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemPostExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemPostExport>>
>;

export type PostSystemPostExportMutationError = unknown;

/**
 * @summary 导出岗位列表
 */
export const usePostSystemPostExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemPostExport>>,
    TError,
    { params: PostSystemPostExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemPostExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取岗位列表
 */
export const getSystemPostList = (
  params: MaybeRef<GetSystemPostListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象岗位信息业务对象>(
    { url: `/system/post/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemPostListQueryKey = (
  params: MaybeRef<GetSystemPostListParams>,
) => ["system", "post", "list", ...(params ? [params] : [])] as const;

export const getGetSystemPostListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemPostList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemPostListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemPostList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemPostList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemPostListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemPostList>>
  > = ({ signal }) => getSystemPostList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemPostListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemPostList>>
>;
export type GetSystemPostListQueryError = void;

/**
 * @summary 获取岗位列表
 */
export const useGetSystemPostList = <
  TData = Awaited<ReturnType<typeof getSystemPostList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemPostListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemPostList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemPostListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 获取岗位选择框列表
 */
export const getSystemPostOptionselect = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象List岗位信息业务对象>(
    { url: `/system/post/optionselect`, method: "get", signal },
    options,
  );
};

export const getGetSystemPostOptionselectQueryKey = () =>
  ["system", "post", "optionselect"] as const;

export const getGetSystemPostOptionselectQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemPostOptionselect>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSystemPostOptionselect>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemPostOptionselect>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemPostOptionselectQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemPostOptionselect>>
  > = ({ signal }) => getSystemPostOptionselect(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemPostOptionselectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemPostOptionselect>>
>;
export type GetSystemPostOptionselectQueryError = void;

/**
 * @summary 获取岗位选择框列表
 */
export const useGetSystemPostOptionselect = <
  TData = Awaited<ReturnType<typeof getSystemPostOptionselect>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSystemPostOptionselect>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemPostOptionselectQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除岗位
 */
export const deleteSystemPostByPostIds = (
  postIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/post/${unref(postIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemPostByPostIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemPostByPostIds>>,
    TError,
    { postIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemPostByPostIds>>,
  TError,
  { postIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemPostByPostIds>>,
    { postIds: string }
  > = (props) => {
    const { postIds } = props ?? {};

    return deleteSystemPostByPostIds(postIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemPostByPostIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemPostByPostIds>>
>;

export type DeleteSystemPostByPostIdsMutationError = unknown;

/**
 * @summary 删除岗位
 */
export const useDeleteSystemPostByPostIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemPostByPostIds>>,
    TError,
    { postIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteSystemPostByPostIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 根据岗位编号获取详细信息
 */
export const getSystemPostByPostId = (
  postId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象岗位信息业务对象>(
    { url: `/system/post/${unref(postId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemPostByPostIdQueryKey = (postId: MaybeRef<number>) =>
  ["system", "post", postId] as const;

export const getGetSystemPostByPostIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemPostByPostId>>,
  TError = void,
>(
  postId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemPostByPostId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemPostByPostId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemPostByPostIdQueryKey(postId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemPostByPostId>>
  > = ({ signal }) => getSystemPostByPostId(postId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!postId, ...queryOptions };
};

export type GetSystemPostByPostIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemPostByPostId>>
>;
export type GetSystemPostByPostIdQueryError = void;

/**
 * @summary 根据岗位编号获取详细信息
 */
export const useGetSystemPostByPostId = <
  TData = Awaited<ReturnType<typeof getSystemPostByPostId>>,
  TError = void,
>(
  postId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemPostByPostId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemPostByPostIdQueryOptions(postId, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 个人信息
 */
export const getSystemUserProfile = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象MapStringObject>(
    { url: `/system/user/profile`, method: "get", signal },
    options,
  );
};

export const getGetSystemUserProfileQueryKey = () =>
  ["system", "user", "profile"] as const;

export const getGetSystemUserProfileQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemUserProfile>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSystemUserProfile>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemUserProfile>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemUserProfileQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemUserProfile>>
  > = ({ signal }) => getSystemUserProfile(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemUserProfileQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemUserProfile>>
>;
export type GetSystemUserProfileQueryError = void;

/**
 * @summary 个人信息
 */
export const useGetSystemUserProfile = <
  TData = Awaited<ReturnType<typeof getSystemUserProfile>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSystemUserProfile>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemUserProfileQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 修改用户
 */
export const putSystemUserProfile = (
  sysUser: MaybeRef<SysUser>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/user/profile`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: sysUser,
    },
    options,
  );
};

export const getPutSystemUserProfileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUserProfile>>,
    TError,
    { data: SysUser },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemUserProfile>>,
  TError,
  { data: SysUser },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemUserProfile>>,
    { data: SysUser }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemUserProfile(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemUserProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemUserProfile>>
>;
export type PutSystemUserProfileMutationBody = SysUser;
export type PutSystemUserProfileMutationError = unknown;

/**
 * @summary 修改用户
 */
export const usePutSystemUserProfile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUserProfile>>,
    TError,
    { data: SysUser },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemUserProfileMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 头像上传
 */
export const postSystemUserProfileAvatar = (
  postSystemUserProfileAvatarBody: MaybeRef<PostSystemUserProfileAvatarBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData();
  formData.append("avatarfile", postSystemUserProfileAvatarBody.avatarfile);

  return customInstance<请求响应对象MapStringObject | void>(
    {
      url: `/system/user/profile/avatar`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};

export const getPostSystemUserProfileAvatarMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemUserProfileAvatar>>,
    TError,
    { data: PostSystemUserProfileAvatarBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemUserProfileAvatar>>,
  TError,
  { data: PostSystemUserProfileAvatarBody },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemUserProfileAvatar>>,
    { data: PostSystemUserProfileAvatarBody }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemUserProfileAvatar(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemUserProfileAvatarMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemUserProfileAvatar>>
>;
export type PostSystemUserProfileAvatarMutationBody =
  PostSystemUserProfileAvatarBody;
export type PostSystemUserProfileAvatarMutationError = unknown;

/**
 * @summary 头像上传
 */
export const usePostSystemUserProfileAvatar = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemUserProfileAvatar>>,
    TError,
    { data: PostSystemUserProfileAvatarBody },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostSystemUserProfileAvatarMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 重置密码
 */
export const putSystemUserProfileUpdatePwd = (
  putSystemUserProfileUpdatePwdBody: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/user/profile/updatePwd`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: putSystemUserProfileUpdatePwdBody,
    },
    options,
  );
};

export const getPutSystemUserProfileUpdatePwdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUserProfileUpdatePwd>>,
    TError,
    { data: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemUserProfileUpdatePwd>>,
  TError,
  { data: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemUserProfileUpdatePwd>>,
    { data: string }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemUserProfileUpdatePwd(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemUserProfileUpdatePwdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemUserProfileUpdatePwd>>
>;
export type PutSystemUserProfileUpdatePwdMutationBody = string;
export type PutSystemUserProfileUpdatePwdMutationError = unknown;

/**
 * @summary 重置密码
 */
export const usePutSystemUserProfileUpdatePwd = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUserProfileUpdatePwd>>,
    TError,
    { data: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPutSystemUserProfileUpdatePwdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 用户注册
 */
export const postRegister = (
  用户注册对象: MaybeRef<用户注册对象>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/register`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: 用户注册对象,
    },
    options,
  );
};

export const getPostRegisterMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postRegister>>,
    TError,
    { data: 用户注册对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postRegister>>,
  TError,
  { data: 用户注册对象 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postRegister>>,
    { data: 用户注册对象 }
  > = (props) => {
    const { data } = props ?? {};

    return postRegister(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostRegisterMutationResult = NonNullable<
  Awaited<ReturnType<typeof postRegister>>
>;
export type PostRegisterMutationBody = 用户注册对象;
export type PostRegisterMutationError = unknown;

/**
 * @summary 用户注册
 */
export const usePostRegister = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postRegister>>,
    TError,
    { data: 用户注册对象 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostRegisterMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改保存角色
 */
export const putSystemRole = (
  sysRoleReq: MaybeRef<SysRoleReq>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/role`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: sysRoleReq,
    },
    options,
  );
};

export const getPutSystemRoleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRole>>,
    TError,
    { data: SysRoleReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemRole>>,
  TError,
  { data: SysRoleReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemRole>>,
    { data: SysRoleReq }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemRole(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemRole>>
>;
export type PutSystemRoleMutationBody = SysRoleReq;
export type PutSystemRoleMutationError = unknown;

/**
 * @summary 修改保存角色
 */
export const usePutSystemRole = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRole>>,
    TError,
    { data: SysRoleReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增角色
 */
export const postSystemRole = (
  sysRoleReq: MaybeRef<SysRoleReq>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/role`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: sysRoleReq,
    },
    options,
  );
};

export const getPostSystemRoleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemRole>>,
    TError,
    { data: SysRoleReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemRole>>,
  TError,
  { data: SysRoleReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemRole>>,
    { data: SysRoleReq }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemRole(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemRole>>
>;
export type PostSystemRoleMutationBody = SysRoleReq;
export type PostSystemRoleMutationError = unknown;

/**
 * @summary 新增角色
 */
export const usePostSystemRole = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemRole>>,
    TError,
    { data: SysRoleReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询已分配用户角色列表
 */
export const getSystemRoleAuthUserAllocatedList = (
  params: MaybeRef<GetSystemRoleAuthUserAllocatedListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象SysUser>(
    {
      url: `/system/role/authUser/allocatedList`,
      method: "get",
      params,
      signal,
    },
    options,
  );
};

export const getGetSystemRoleAuthUserAllocatedListQueryKey = (
  params: MaybeRef<GetSystemRoleAuthUserAllocatedListParams>,
) =>
  [
    "system",
    "role",
    "authUser",
    "allocatedList",
    ...(params ? [params] : []),
  ] as const;

export const getGetSystemRoleAuthUserAllocatedListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemRoleAuthUserAllocatedList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemRoleAuthUserAllocatedListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemRoleAuthUserAllocatedList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemRoleAuthUserAllocatedList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemRoleAuthUserAllocatedListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemRoleAuthUserAllocatedList>>
  > = ({ signal }) =>
    getSystemRoleAuthUserAllocatedList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemRoleAuthUserAllocatedListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemRoleAuthUserAllocatedList>>
>;
export type GetSystemRoleAuthUserAllocatedListQueryError = void;

/**
 * @summary 查询已分配用户角色列表
 */
export const useGetSystemRoleAuthUserAllocatedList = <
  TData = Awaited<ReturnType<typeof getSystemRoleAuthUserAllocatedList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemRoleAuthUserAllocatedListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemRoleAuthUserAllocatedList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemRoleAuthUserAllocatedListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 取消授权用户
 */
export const putSystemRoleAuthUserCancel = (
  用户和角色关联: MaybeRef<用户和角色关联>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/role/authUser/cancel`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: 用户和角色关联,
    },
    options,
  );
};

export const getPutSystemRoleAuthUserCancelMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRoleAuthUserCancel>>,
    TError,
    { data: 用户和角色关联 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemRoleAuthUserCancel>>,
  TError,
  { data: 用户和角色关联 },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemRoleAuthUserCancel>>,
    { data: 用户和角色关联 }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemRoleAuthUserCancel(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemRoleAuthUserCancelMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemRoleAuthUserCancel>>
>;
export type PutSystemRoleAuthUserCancelMutationBody = 用户和角色关联;
export type PutSystemRoleAuthUserCancelMutationError = unknown;

/**
 * @summary 取消授权用户
 */
export const usePutSystemRoleAuthUserCancel = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRoleAuthUserCancel>>,
    TError,
    { data: 用户和角色关联 },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPutSystemRoleAuthUserCancelMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 批量取消授权用户
 */
export const putSystemRoleAuthUserCancelAll = (
  params?: MaybeRef<PutSystemRoleAuthUserCancelAllParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/role/authUser/cancelAll`, method: "put", params },
    options,
  );
};

export const getPutSystemRoleAuthUserCancelAllMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRoleAuthUserCancelAll>>,
    TError,
    { params?: PutSystemRoleAuthUserCancelAllParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemRoleAuthUserCancelAll>>,
  TError,
  { params?: PutSystemRoleAuthUserCancelAllParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemRoleAuthUserCancelAll>>,
    { params?: PutSystemRoleAuthUserCancelAllParams }
  > = (props) => {
    const { params } = props ?? {};

    return putSystemRoleAuthUserCancelAll(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemRoleAuthUserCancelAllMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemRoleAuthUserCancelAll>>
>;

export type PutSystemRoleAuthUserCancelAllMutationError = unknown;

/**
 * @summary 批量取消授权用户
 */
export const usePutSystemRoleAuthUserCancelAll = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRoleAuthUserCancelAll>>,
    TError,
    { params?: PutSystemRoleAuthUserCancelAllParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPutSystemRoleAuthUserCancelAllMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 批量选择用户授权
 */
export const putSystemRoleAuthUserSelectAll = (
  params?: MaybeRef<PutSystemRoleAuthUserSelectAllParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/role/authUser/selectAll`, method: "put", params },
    options,
  );
};

export const getPutSystemRoleAuthUserSelectAllMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRoleAuthUserSelectAll>>,
    TError,
    { params?: PutSystemRoleAuthUserSelectAllParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemRoleAuthUserSelectAll>>,
  TError,
  { params?: PutSystemRoleAuthUserSelectAllParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemRoleAuthUserSelectAll>>,
    { params?: PutSystemRoleAuthUserSelectAllParams }
  > = (props) => {
    const { params } = props ?? {};

    return putSystemRoleAuthUserSelectAll(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemRoleAuthUserSelectAllMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemRoleAuthUserSelectAll>>
>;

export type PutSystemRoleAuthUserSelectAllMutationError = unknown;

/**
 * @summary 批量选择用户授权
 */
export const usePutSystemRoleAuthUserSelectAll = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRoleAuthUserSelectAll>>,
    TError,
    { params?: PutSystemRoleAuthUserSelectAllParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPutSystemRoleAuthUserSelectAllMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询未分配用户角色列表
 */
export const getSystemRoleAuthUserUnallocatedList = (
  params: MaybeRef<GetSystemRoleAuthUserUnallocatedListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象SysUser>(
    {
      url: `/system/role/authUser/unallocatedList`,
      method: "get",
      params,
      signal,
    },
    options,
  );
};

export const getGetSystemRoleAuthUserUnallocatedListQueryKey = (
  params: MaybeRef<GetSystemRoleAuthUserUnallocatedListParams>,
) =>
  [
    "system",
    "role",
    "authUser",
    "unallocatedList",
    ...(params ? [params] : []),
  ] as const;

export const getGetSystemRoleAuthUserUnallocatedListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemRoleAuthUserUnallocatedList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemRoleAuthUserUnallocatedListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemRoleAuthUserUnallocatedList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemRoleAuthUserUnallocatedList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemRoleAuthUserUnallocatedListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemRoleAuthUserUnallocatedList>>
  > = ({ signal }) =>
    getSystemRoleAuthUserUnallocatedList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemRoleAuthUserUnallocatedListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemRoleAuthUserUnallocatedList>>
>;
export type GetSystemRoleAuthUserUnallocatedListQueryError = void;

/**
 * @summary 查询未分配用户角色列表
 */
export const useGetSystemRoleAuthUserUnallocatedList = <
  TData = Awaited<ReturnType<typeof getSystemRoleAuthUserUnallocatedList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemRoleAuthUserUnallocatedListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemRoleAuthUserUnallocatedList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemRoleAuthUserUnallocatedListQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 状态修改
 */
export const putSystemRoleChangeStatus = (
  sysRoleReq: MaybeRef<SysRoleReq>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/role/changeStatus`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: sysRoleReq,
    },
    options,
  );
};

export const getPutSystemRoleChangeStatusMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRoleChangeStatus>>,
    TError,
    { data: SysRoleReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemRoleChangeStatus>>,
  TError,
  { data: SysRoleReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemRoleChangeStatus>>,
    { data: SysRoleReq }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemRoleChangeStatus(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemRoleChangeStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemRoleChangeStatus>>
>;
export type PutSystemRoleChangeStatusMutationBody = SysRoleReq;
export type PutSystemRoleChangeStatusMutationError = unknown;

/**
 * @summary 状态修改
 */
export const usePutSystemRoleChangeStatus = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRoleChangeStatus>>,
    TError,
    { data: SysRoleReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemRoleChangeStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 修改保存数据权限
 */
export const putSystemRoleDataScope = (
  sysRoleReq: MaybeRef<SysRoleReq>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/role/dataScope`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: sysRoleReq,
    },
    options,
  );
};

export const getPutSystemRoleDataScopeMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRoleDataScope>>,
    TError,
    { data: SysRoleReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemRoleDataScope>>,
  TError,
  { data: SysRoleReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemRoleDataScope>>,
    { data: SysRoleReq }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemRoleDataScope(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemRoleDataScopeMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemRoleDataScope>>
>;
export type PutSystemRoleDataScopeMutationBody = SysRoleReq;
export type PutSystemRoleDataScopeMutationError = unknown;

/**
 * @summary 修改保存数据权限
 */
export const usePutSystemRoleDataScope = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemRoleDataScope>>,
    TError,
    { data: SysRoleReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemRoleDataScopeMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导出角色信息列表
 */
export const postSystemRoleExport = (
  params: MaybeRef<PostSystemRoleExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/system/role/export`, method: "post", params },
    options,
  );
};

export const getPostSystemRoleExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemRoleExport>>,
    TError,
    { params: PostSystemRoleExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemRoleExport>>,
  TError,
  { params: PostSystemRoleExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemRoleExport>>,
    { params: PostSystemRoleExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postSystemRoleExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemRoleExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemRoleExport>>
>;

export type PostSystemRoleExportMutationError = unknown;

/**
 * @summary 导出角色信息列表
 */
export const usePostSystemRoleExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemRoleExport>>,
    TError,
    { params: PostSystemRoleExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemRoleExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 查询角色信息列表
 */
export const getSystemRoleList = (
  params: MaybeRef<GetSystemRoleListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象SysRole>(
    { url: `/system/role/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemRoleListQueryKey = (
  params: MaybeRef<GetSystemRoleListParams>,
) => ["system", "role", "list", ...(params ? [params] : [])] as const;

export const getGetSystemRoleListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemRoleList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemRoleListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemRoleList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemRoleList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemRoleListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemRoleList>>
  > = ({ signal }) => getSystemRoleList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemRoleListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemRoleList>>
>;
export type GetSystemRoleListQueryError = void;

/**
 * @summary 查询角色信息列表
 */
export const useGetSystemRoleList = <
  TData = Awaited<ReturnType<typeof getSystemRoleList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemRoleListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemRoleList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemRoleListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 获取角色选择框列表
 */
export const getSystemRoleOptionselect = (
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象ListSysRole>(
    { url: `/system/role/optionselect`, method: "get", signal },
    options,
  );
};

export const getGetSystemRoleOptionselectQueryKey = () =>
  ["system", "role", "optionselect"] as const;

export const getGetSystemRoleOptionselectQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemRoleOptionselect>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSystemRoleOptionselect>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemRoleOptionselect>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemRoleOptionselectQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemRoleOptionselect>>
  > = ({ signal }) => getSystemRoleOptionselect(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemRoleOptionselectQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemRoleOptionselect>>
>;
export type GetSystemRoleOptionselectQueryError = void;

/**
 * @summary 获取角色选择框列表
 */
export const useGetSystemRoleOptionselect = <
  TData = Awaited<ReturnType<typeof getSystemRoleOptionselect>>,
  TError = void,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getSystemRoleOptionselect>>,
    TError,
    TData
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemRoleOptionselectQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 删除角色
 */
export const deleteSystemRoleByRoleIds = (
  roleIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/role/${unref(roleIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemRoleByRoleIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemRoleByRoleIds>>,
    TError,
    { roleIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemRoleByRoleIds>>,
  TError,
  { roleIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemRoleByRoleIds>>,
    { roleIds: string }
  > = (props) => {
    const { roleIds } = props ?? {};

    return deleteSystemRoleByRoleIds(roleIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemRoleByRoleIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemRoleByRoleIds>>
>;

export type DeleteSystemRoleByRoleIdsMutationError = unknown;

/**
 * @summary 删除角色
 */
export const useDeleteSystemRoleByRoleIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemRoleByRoleIds>>,
    TError,
    { roleIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteSystemRoleByRoleIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 根据角色编号获取详细信息
 */
export const getSystemRoleByRoleId = (
  roleId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象SysRole>(
    { url: `/system/role/${unref(roleId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemRoleByRoleIdQueryKey = (roleId: MaybeRef<number>) =>
  ["system", "role", roleId] as const;

export const getGetSystemRoleByRoleIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemRoleByRoleId>>,
  TError = void,
>(
  roleId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemRoleByRoleId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemRoleByRoleId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemRoleByRoleIdQueryKey(roleId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemRoleByRoleId>>
  > = ({ signal }) => getSystemRoleByRoleId(roleId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!roleId, ...queryOptions };
};

export type GetSystemRoleByRoleIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemRoleByRoleId>>
>;
export type GetSystemRoleByRoleIdQueryError = void;

/**
 * @summary 根据角色编号获取详细信息
 */
export const useGetSystemRoleByRoleId = <
  TData = Awaited<ReturnType<typeof getSystemRoleByRoleId>>,
  TError = void,
>(
  roleId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemRoleByRoleId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemRoleByRoleIdQueryOptions(roleId, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 修改用户
 */
export const putSystemUser = (
  sysUserReq: MaybeRef<SysUserReq>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/user`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: sysUserReq,
    },
    options,
  );
};

export const getPutSystemUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUser>>,
    TError,
    { data: SysUserReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemUser>>,
  TError,
  { data: SysUserReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemUser>>,
    { data: SysUserReq }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemUser>>
>;
export type PutSystemUserMutationBody = SysUserReq;
export type PutSystemUserMutationError = unknown;

/**
 * @summary 修改用户
 */
export const usePutSystemUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUser>>,
    TError,
    { data: SysUserReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 新增用户
 */
export const postSystemUser = (
  sysUserReq: MaybeRef<SysUserReq>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/user`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: sysUserReq,
    },
    options,
  );
};

export const getPostSystemUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemUser>>,
    TError,
    { data: SysUserReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemUser>>,
  TError,
  { data: SysUserReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemUser>>,
    { data: SysUserReq }
  > = (props) => {
    const { data } = props ?? {};

    return postSystemUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemUser>>
>;
export type PostSystemUserMutationBody = SysUserReq;
export type PostSystemUserMutationError = unknown;

/**
 * @summary 新增用户
 */
export const usePostSystemUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemUser>>,
    TError,
    { data: SysUserReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemUserMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 用户授权角色
 */
export const putSystemUserAuthRole = (
  params?: MaybeRef<PutSystemUserAuthRoleParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/user/authRole`, method: "put", params },
    options,
  );
};

export const getPutSystemUserAuthRoleMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUserAuthRole>>,
    TError,
    { params?: PutSystemUserAuthRoleParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemUserAuthRole>>,
  TError,
  { params?: PutSystemUserAuthRoleParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemUserAuthRole>>,
    { params?: PutSystemUserAuthRoleParams }
  > = (props) => {
    const { params } = props ?? {};

    return putSystemUserAuthRole(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemUserAuthRoleMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemUserAuthRole>>
>;

export type PutSystemUserAuthRoleMutationError = unknown;

/**
 * @summary 用户授权角色
 */
export const usePutSystemUserAuthRole = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUserAuthRole>>,
    TError,
    { params?: PutSystemUserAuthRoleParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemUserAuthRoleMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 根据用户编号获取授权角色
 */
export const getSystemUserAuthRoleByUserId = (
  userId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象MapStringObject>(
    { url: `/system/user/authRole/${unref(userId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemUserAuthRoleByUserIdQueryKey = (
  userId: MaybeRef<number>,
) => ["system", "user", "authRole", userId] as const;

export const getGetSystemUserAuthRoleByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemUserAuthRoleByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemUserAuthRoleByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemUserAuthRoleByUserId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemUserAuthRoleByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemUserAuthRoleByUserId>>
  > = ({ signal }) =>
    getSystemUserAuthRoleByUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetSystemUserAuthRoleByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemUserAuthRoleByUserId>>
>;
export type GetSystemUserAuthRoleByUserIdQueryError = void;

/**
 * @summary 根据用户编号获取授权角色
 */
export const useGetSystemUserAuthRoleByUserId = <
  TData = Awaited<ReturnType<typeof getSystemUserAuthRoleByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemUserAuthRoleByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemUserAuthRoleByUserIdQueryOptions(
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 状态修改
 */
export const putSystemUserChangeStatus = (
  sysUserReq: MaybeRef<SysUserReq>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/user/changeStatus`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: sysUserReq,
    },
    options,
  );
};

export const getPutSystemUserChangeStatusMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUserChangeStatus>>,
    TError,
    { data: SysUserReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemUserChangeStatus>>,
  TError,
  { data: SysUserReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemUserChangeStatus>>,
    { data: SysUserReq }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemUserChangeStatus(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemUserChangeStatusMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemUserChangeStatus>>
>;
export type PutSystemUserChangeStatusMutationBody = SysUserReq;
export type PutSystemUserChangeStatusMutationError = unknown;

/**
 * @summary 状态修改
 */
export const usePutSystemUserChangeStatus = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUserChangeStatus>>,
    TError,
    { data: SysUserReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemUserChangeStatusMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 校验身份证号是否唯一
 */
export const getSystemUserCheckIdCardNoUnique = (
  params: MaybeRef<GetSystemUserCheckIdCardNoUniqueParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象Void>(
    { url: `/system/user/checkIdCardNoUnique`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemUserCheckIdCardNoUniqueQueryKey = (
  params: MaybeRef<GetSystemUserCheckIdCardNoUniqueParams>,
) =>
  [
    "system",
    "user",
    "checkIdCardNoUnique",
    ...(params ? [params] : []),
  ] as const;

export const getGetSystemUserCheckIdCardNoUniqueQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemUserCheckIdCardNoUnique>>,
  TError = void,
>(
  params: MaybeRef<GetSystemUserCheckIdCardNoUniqueParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemUserCheckIdCardNoUnique>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemUserCheckIdCardNoUnique>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemUserCheckIdCardNoUniqueQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemUserCheckIdCardNoUnique>>
  > = ({ signal }) =>
    getSystemUserCheckIdCardNoUnique(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemUserCheckIdCardNoUniqueQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemUserCheckIdCardNoUnique>>
>;
export type GetSystemUserCheckIdCardNoUniqueQueryError = void;

/**
 * @summary 校验身份证号是否唯一
 */
export const useGetSystemUserCheckIdCardNoUnique = <
  TData = Awaited<ReturnType<typeof getSystemUserCheckIdCardNoUnique>>,
  TError = void,
>(
  params: MaybeRef<GetSystemUserCheckIdCardNoUniqueParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemUserCheckIdCardNoUnique>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemUserCheckIdCardNoUniqueQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 校验手机号码是否唯一
 */
export const getSystemUserCheckPhoneUnique = (
  params: MaybeRef<GetSystemUserCheckPhoneUniqueParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象Void>(
    { url: `/system/user/checkPhoneUnique`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemUserCheckPhoneUniqueQueryKey = (
  params: MaybeRef<GetSystemUserCheckPhoneUniqueParams>,
) =>
  ["system", "user", "checkPhoneUnique", ...(params ? [params] : [])] as const;

export const getGetSystemUserCheckPhoneUniqueQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemUserCheckPhoneUnique>>,
  TError = void,
>(
  params: MaybeRef<GetSystemUserCheckPhoneUniqueParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemUserCheckPhoneUnique>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemUserCheckPhoneUnique>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemUserCheckPhoneUniqueQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemUserCheckPhoneUnique>>
  > = ({ signal }) =>
    getSystemUserCheckPhoneUnique(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemUserCheckPhoneUniqueQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemUserCheckPhoneUnique>>
>;
export type GetSystemUserCheckPhoneUniqueQueryError = void;

/**
 * @summary 校验手机号码是否唯一
 */
export const useGetSystemUserCheckPhoneUnique = <
  TData = Awaited<ReturnType<typeof getSystemUserCheckPhoneUnique>>,
  TError = void,
>(
  params: MaybeRef<GetSystemUserCheckPhoneUniqueParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemUserCheckPhoneUnique>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemUserCheckPhoneUniqueQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 导出用户列表
 */
export const postSystemUserExport = (
  params: MaybeRef<PostSystemUserExportParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/system/user/export`, method: "post", params },
    options,
  );
};

export const getPostSystemUserExportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemUserExport>>,
    TError,
    { params: PostSystemUserExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemUserExport>>,
  TError,
  { params: PostSystemUserExportParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemUserExport>>,
    { params: PostSystemUserExportParams }
  > = (props) => {
    const { params } = props ?? {};

    return postSystemUserExport(params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemUserExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemUserExport>>
>;

export type PostSystemUserExportMutationError = unknown;

/**
 * @summary 导出用户列表
 */
export const usePostSystemUserExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemUserExport>>,
    TError,
    { params: PostSystemUserExportParams },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemUserExportMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 导入用户列表
 */
export const postSystemUserImportData = (
  postSystemUserImportDataBody: MaybeRef<PostSystemUserImportDataBody>,
  params?: MaybeRef<PostSystemUserImportDataParams>,
  options?: SecondParameter<typeof customInstance>,
) => {
  const formData = new FormData();
  formData.append("file", postSystemUserImportDataBody.file);

  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/user/importData`,
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
      params,
    },
    options,
  );
};

export const getPostSystemUserImportDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemUserImportData>>,
    TError,
    {
      data: PostSystemUserImportDataBody;
      params?: PostSystemUserImportDataParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemUserImportData>>,
  TError,
  {
    data: PostSystemUserImportDataBody;
    params?: PostSystemUserImportDataParams;
  },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemUserImportData>>,
    {
      data: PostSystemUserImportDataBody;
      params?: PostSystemUserImportDataParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return postSystemUserImportData(data, params, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemUserImportDataMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemUserImportData>>
>;
export type PostSystemUserImportDataMutationBody = PostSystemUserImportDataBody;
export type PostSystemUserImportDataMutationError = unknown;

/**
 * @summary 导入用户列表
 */
export const usePostSystemUserImportData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemUserImportData>>,
    TError,
    {
      data: PostSystemUserImportDataBody;
      params?: PostSystemUserImportDataParams;
    },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPostSystemUserImportDataMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 下载导入模板
 */
export const postSystemUserImportTemplate = (
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<void>(
    { url: `/system/user/importTemplate`, method: "post" },
    options,
  );
};

export const getPostSystemUserImportTemplateMutationOptions = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemUserImportTemplate>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postSystemUserImportTemplate>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postSystemUserImportTemplate>>,
    TVariables
  > = () => {
    return postSystemUserImportTemplate(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostSystemUserImportTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof postSystemUserImportTemplate>>
>;

export type PostSystemUserImportTemplateMutationError = unknown;

/**
 * @summary 下载导入模板
 */
export const usePostSystemUserImportTemplate = <
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postSystemUserImportTemplate>>,
    TError,
    TVariables,
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getPostSystemUserImportTemplateMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 获取用户列表
 */
export const getSystemUserList = (
  params: MaybeRef<GetSystemUserListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象SysUser>(
    { url: `/system/user/list`, method: "get", params, signal },
    options,
  );
};

export const getGetSystemUserListQueryKey = (
  params: MaybeRef<GetSystemUserListParams>,
) => ["system", "user", "list", ...(params ? [params] : [])] as const;

export const getGetSystemUserListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemUserList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemUserListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemUserList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemUserList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemUserListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemUserList>>
  > = ({ signal }) => getSystemUserList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetSystemUserListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemUserList>>
>;
export type GetSystemUserListQueryError = void;

/**
 * @summary 获取用户列表
 */
export const useGetSystemUserList = <
  TData = Awaited<ReturnType<typeof getSystemUserList>>,
  TError = void,
>(
  params: MaybeRef<GetSystemUserListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemUserList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemUserListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 重置密码
 */
export const putSystemUserResetPwd = (
  sysUserReq: MaybeRef<SysUserReq>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    {
      url: `/system/user/resetPwd`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: sysUserReq,
    },
    options,
  );
};

export const getPutSystemUserResetPwdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUserResetPwd>>,
    TError,
    { data: SysUserReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putSystemUserResetPwd>>,
  TError,
  { data: SysUserReq },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putSystemUserResetPwd>>,
    { data: SysUserReq }
  > = (props) => {
    const { data } = props ?? {};

    return putSystemUserResetPwd(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutSystemUserResetPwdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putSystemUserResetPwd>>
>;
export type PutSystemUserResetPwdMutationBody = SysUserReq;
export type PutSystemUserResetPwdMutationError = unknown;

/**
 * @summary 重置密码
 */
export const usePutSystemUserResetPwd = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putSystemUserResetPwd>>,
    TError,
    { data: SysUserReq },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getPutSystemUserResetPwdMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 删除用户
 */
export const deleteSystemUserByUserIds = (
  userIds: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/system/user/${unref(userIds)}`, method: "delete" },
    options,
  );
};

export const getDeleteSystemUserByUserIdsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemUserByUserIds>>,
    TError,
    { userIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteSystemUserByUserIds>>,
  TError,
  { userIds: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteSystemUserByUserIds>>,
    { userIds: string }
  > = (props) => {
    const { userIds } = props ?? {};

    return deleteSystemUserByUserIds(userIds, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteSystemUserByUserIdsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteSystemUserByUserIds>>
>;

export type DeleteSystemUserByUserIdsMutationError = unknown;

/**
 * @summary 删除用户
 */
export const useDeleteSystemUserByUserIds = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSystemUserByUserIds>>,
    TError,
    { userIds: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions = getDeleteSystemUserByUserIdsMutationOptions(options);

  return useMutation(mutationOptions);
};

/**
 * @summary 根据用户编号获取详细信息
 */
export const getSystemUserByUserId = (
  userId: MaybeRef<number>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<请求响应对象MapStringObject>(
    { url: `/system/user/${unref(userId)}`, method: "get", signal },
    options,
  );
};

export const getGetSystemUserByUserIdQueryKey = (userId: MaybeRef<number>) =>
  ["system", "user", userId] as const;

export const getGetSystemUserByUserIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getSystemUserByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemUserByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getSystemUserByUserId>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetSystemUserByUserIdQueryKey(userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getSystemUserByUserId>>
  > = ({ signal }) => getSystemUserByUserId(userId, requestOptions, signal);

  return { queryKey, queryFn, enabled: !!userId, ...queryOptions };
};

export type GetSystemUserByUserIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSystemUserByUserId>>
>;
export type GetSystemUserByUserIdQueryError = void;

/**
 * @summary 根据用户编号获取详细信息
 */
export const useGetSystemUserByUserId = <
  TData = Awaited<ReturnType<typeof getSystemUserByUserId>>,
  TError = void,
>(
  userId: MaybeRef<number>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getSystemUserByUserId>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSystemUserByUserIdQueryOptions(userId, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 在线用户列表
 */
export const getMonitorOnlineList = (
  params?: MaybeRef<GetMonitorOnlineListParams>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  return customInstance<分页响应对象当前在线会话业务对象>(
    { url: `/monitor/online/list`, method: "get", params, signal },
    options,
  );
};

export const getGetMonitorOnlineListQueryKey = (
  params?: MaybeRef<GetMonitorOnlineListParams>,
) => ["monitor", "online", "list", ...(params ? [params] : [])] as const;

export const getGetMonitorOnlineListQueryOptions = <
  TData = Awaited<ReturnType<typeof getMonitorOnlineList>>,
  TError = void,
>(
  params?: MaybeRef<GetMonitorOnlineListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMonitorOnlineList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryOptions<
  Awaited<ReturnType<typeof getMonitorOnlineList>>,
  TError,
  TData
> => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = getGetMonitorOnlineListQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getMonitorOnlineList>>
  > = ({ signal }) => getMonitorOnlineList(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions };
};

export type GetMonitorOnlineListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getMonitorOnlineList>>
>;
export type GetMonitorOnlineListQueryError = void;

/**
 * @summary 在线用户列表
 */
export const useGetMonitorOnlineList = <
  TData = Awaited<ReturnType<typeof getMonitorOnlineList>>,
  TError = void,
>(
  params?: MaybeRef<GetMonitorOnlineListParams>,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getMonitorOnlineList>>,
      TError,
      TData
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetMonitorOnlineListQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
};

/**
 * @summary 强退用户
 */
export const deleteMonitorOnlineByTokenId = (
  tokenId: MaybeRef<string>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<请求响应对象Void | void>(
    { url: `/monitor/online/${unref(tokenId)}`, method: "delete" },
    options,
  );
};

export const getDeleteMonitorOnlineByTokenIdMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorOnlineByTokenId>>,
    TError,
    { tokenId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteMonitorOnlineByTokenId>>,
  TError,
  { tokenId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteMonitorOnlineByTokenId>>,
    { tokenId: string }
  > = (props) => {
    const { tokenId } = props ?? {};

    return deleteMonitorOnlineByTokenId(tokenId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteMonitorOnlineByTokenIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteMonitorOnlineByTokenId>>
>;

export type DeleteMonitorOnlineByTokenIdMutationError = unknown;

/**
 * @summary 强退用户
 */
export const useDeleteMonitorOnlineByTokenId = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteMonitorOnlineByTokenId>>,
    TError,
    { tokenId: string },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const mutationOptions =
    getDeleteMonitorOnlineByTokenIdMutationOptions(options);

  return useMutation(mutationOptions);
};
