import { createRouter, createWebHistory } from "vue-router";
import { Routes, DashboardRoutes } from "./routes";
import { useGlobalStore } from "@/store/globalStore";
import { useUserStore } from "@/store/userStore";
import { config } from "@/config";

const DashboardRouteNames = DashboardRoutes.map((route) => route.name);

const router = createRouter({
  history: createWebHistory(config.BASE),
  routes: Routes,
});

router.afterEach((to) => {
  if (to.path.match(/^\/dashboard/) && !to.meta.isPin) {
    const globalStore = useGlobalStore();
    globalStore.ADD_TAB(to);
  }
});

router.beforeEach((to) => {
  const userStore = useUserStore();
  const token = userStore.token;
  if (typeof to.name === "string" && DashboardRouteNames.includes(to.name)) {
    if (!token) {
      return {
        name: "Home",
        replace: true,
      };
    }
  }
});

export { router };
